<template>
  <div :class="subDetailType">
    <div class="handlers" v-for="(item, num) in assigneesDatas" :key="num">
      <!-- <div class="handler-more" v-if="item.logType === 'CC' || (item.accounts && item.accounts.length !== 1)"> -->
      <div
        class="handler-more"
        v-if="item.logType === 'CC' || (item.assigneeType && item.assigneeType.includes('MULTIPLE'))"
      >
        <div class="handler-main-more">
          <div>
            <img :src="multi" class="handler-img" :class="{ handlering: num === assigneesDatas.length - 1 }" />
            <div class="line" v-if="num !== assigneesDatas.length - 1 || assigneesDatas.length > 1"></div>
          </div>
          <div class="morehandler-info">
            <div class="handler-teacher-more">
              <div class="teacher-list-more" v-if="item.logType === 'NODE'">
                <p>多人处理</p>
                <span class="transfer-more">({{ item.nodeLabel }})</span>
              </div>
              <div class="teacher-list-more" v-if="item.logType !== 'NODE'">
                <p>
                  {{
                    roleArray.some(
                      (k) => item.taskFromAccount.roles && item.taskFromAccount.roles.join(' ').includes(k),
                    )
                      ? '审核人'
                      : item.taskFromAccount.fullName
                  }}
                  {{ item.taskFromAccount.departmentsName ? `(${item.taskFromAccount.departmentsName})` : '' }}
                </p>
                <span class="transfer-more">添加了抄送</span>
                <p class="ccNumber">抄送{{ item.accounts.length }}人</p>
              </div>
              <p v-if="item.time">{{ moment(item.time).utcOffset(8).format('YY/MM/DD HH:mm') }}</p>
            </div>
            <div style="width: 100%; margin: 0 0 10px 0" v-if="item.logType === 'NODE' && item.data && !item.label">
              <span v-if="item.data.businessTaskStatus === 'PENDING'" class="handler-status pending-status"
                >待处理</span
              >
              <span v-if="item.data.businessTaskStatus === 'PASSED'" class="handler-status success-status">已完成</span>
              <span v-if="item.data.businessTaskStatus === 'REJECTED'" class="handler-status refuse-status"
                >已拒绝</span
              >
              <span v-if="item.data.businessTaskStatus === 'CANCEL'" class="handler-status cancel-status">已撤销</span>

              <!-- <span v-if="!item.data.finished" class="handler-status pending-status">待处理</span>
              <span v-if="item.data.finished && !item.data.deleted" class="handler-status success-status">已完成</span>
              <span v-if="item.data.deleted" class="handler-status refuse-status">已拒绝</span> -->
            </div>
            <div style="margin: 0 0 10px 0" v-if="item.label">
              <span class="handler-status">{{ item.label }}</span>
            </div>
            <div class="morehandler-name-more">
              <div class="teacher-info-more" v-for="(i, index) in item.accounts" :key="index">
                <template v-if="index < 3 || isMore === num">
                  <img :src="defaultImg" alt="" class="teacher-img-more" />
                  <span class="teacher-name-more"
                    >{{ roleArray.some((k) => i.roles && i.roles.join(' ').includes(k)) ? '审核人' : i.fullName }}

                    {{ i.rolesC }} {{ i.departmentsName ? `(${i.departmentsName})` : '' }}</span
                  >
                  <span></span>
                </template>
              </div>
              <div class="more" @click="moreHandler(num)" v-if="item.accounts.length > 3">
                <span v-if="!(isMore === num)">+ {{ item.accounts.length - 3 }}</span>
                <a-icon :type="isMore === num ? 'caret-down' : 'caret-up'" />
              </div>
            </div>
            <div v-if="item.logType === 'CC' && item.data && item.data.comments && item.data.comments.length !== 0">
              <!-- <div v-for="comment in item.data.comments" :key="comment.time">
                <div class="handler-reply" v-if="comment.message">
                  <p>{{ comment.message }}</p>
                </div>
              </div> -->
              <div v-for="comment in item.data.comments" :key="comment.time">
                <div class="handler-reply" v-if="comment.message">
                  <p>{{ comment.message }}</p>
                </div>
                <img
                  class="commentImg"
                  v-for="img in comment.images"
                  :key="img.uuid"
                  :src="img.url"
                  preview="1"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="handler-one" v-if="item.logType !== 'CC' && item.accounts && item.accounts.length === 1"> -->
      <div class="handler-one" v-if="item.logType !== 'CC' && item.assigneeType === 'SINGLE'">
        <div class="handler-main">
          <div>
            <img
              :src="defaultImg"
              alt=""
              class="handler-img"
              :class="{ handlering: num === assigneesDatas.length - 1 }"
            />
            <div
              class="line"
              v-if="num !== assigneesDatas.length - 1 || (item.data && item.data.finished) || isRefuse || isSuccess"
            ></div>
          </div>
          <div class="handler-info">
            <div class="handler-teacher">
              <div class="teacher-list">
                <p class="teacher-name" v-if="item.logType == 'TURN'">
                  <span>
                    {{
                      roleArray.some(
                        (k) => item.taskFromAccount.roles && item.taskFromAccount.roles.join(' ').includes(k),
                      )
                        ? '审核人'
                        : item.taskFromAccount.fullName
                    }}
                  </span>
                  {{ item.taskFromAccount.rolesC }}
                  {{ item.taskFromAccount.departmentsName ? `(${item.taskFromAccount.departmentsName})` : '' }}
                </p>
                <p class="teacher-name" v-else>
                  <span>
                    {{
                      roleArray.some((k) => item.accounts[0].roles && item.accounts[0].roles.join(' ').includes(k))
                        ? '审核人'
                        : item.accounts[0].fullName
                    }}</span
                  >
                  {{ item.accounts[0].rolesC }}
                  {{ item.accounts[0].departmentsName ? `(${item.accounts[0].departmentsName})` : '' }}
                </p>
                <span v-if="item.logType === 'COMMENT'" style="color: #999; width: 100px">添加了评论</span>
                <span v-if="item.logType === 'TURN'" class="turnCls" style="color: #999; width: 100px">转交给</span>
                <p class="teacher-name turn-name" v-if="item.logType === 'TURN'">
                  <span>{{
                    roleArray.some((k) => item.accounts[0].roles && item.accounts[0].roles.join(' ').includes(k))
                      ? '审核人'
                      : item.accounts[0].fullName
                  }}</span
                  >{{ item.accounts[0].rolesC
                  }}{{ item.accounts[0].departmentsName ? `(${item.accounts[0].departmentsName})` : '' }}
                </p>
              </div>
              <p v-if="item.time">
                {{ moment(item.time).utcOffset(8).format('YY/MM/DD HH:mm') }}
              </p>
            </div>
            <div style="margin: 0 0 10px 0" v-if="item.logType === 'NODE' && item.data && !item.label">
              <span v-if="item.data.businessTaskStatus === 'PENDING'" class="handler-status pending-status"
                >待处理</span
              >
              <span v-if="item.data.businessTaskStatus === 'PASSED'" class="handler-status success-status">已完成</span>
              <span v-if="item.data.businessTaskStatus === 'REJECTED'" class="handler-status refuse-status"
                >已拒绝</span
              >
              <span v-if="item.data.businessTaskStatus === 'CANCEL'" class="handler-status cancel-status">已撤销</span>
              <!-- <span v-if="!item.data.finished" class="handler-status pending-status">待处理</span>
              <span v-if="item.data.finished && !item.data.deleted" class="handler-status success-status">已完成</span>
              <span v-if="item.data.deleted" class="handler-status refuse-status">已拒绝</span> -->
            </div>
            <div style="margin: 0 0 10px 0" v-if="item.label">
              <span class="handler-status">{{ item.label }}</span>
            </div>
            <div v-if="item.data && item.data.comments && item.data.comments.length !== 0">
              <div v-for="(comment, index) in item.data.comments" :key="index">
                <div class="handler-reply">
                  <p>{{ comment.message }}</p>
                </div>
                <img
                  class="commentImg"
                  v-for="img in comment.images"
                  :key="img.uuid"
                  :src="img.url"
                  alt=""
                  preview="1"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="handler-more refuse" v-if="!item.accounts && item.nodeCompleted">
        <div class="handler-main">
          <div class="refuseStatus"><span></span></div>
          <div class="morehandler-info">
            <div class="handler-teacher">
              <div class="teacher-list">
                <p>流程已结束</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from 'vue';
import moment from 'moment';
import preview from 'vue-photo-preview';
import defaultImg from '../assets/service_pic_head.png';
import finished from '../assets/service_pic_finished.svg';
import multi from '../assets/home_icon_multi.svg';
import 'vue-photo-preview/dist/skin.css';

const options = {
  maxSpreadZoom: 1, // 执行展开（缩放）手势时的最大缩放级别。`2`意味着图像可以从原始尺寸放大 2 倍
  loop: true, // 默认循环轮播图片
  pinchToClose: true, // 当图片缩小时，退出相册集
  closeOnScroll: true, // 当滚动鼠标的时候，退出相册，这边默认true，建议false
  fullscreenEl: false, // 控制是否显示右上角全屏按钮
  closeEl: true, // 控制是否显示右上角关闭按钮
  tapToClose: false, // 点击滑动区域应关闭图库
  shareEl: false, // 控制是否显示分享按钮
  zoomEl: false, // 控制是否显示放大缩小按钮
  counterEl: true, // 控制是否显示左上角图片数量按钮
  indexIndicatorSep: ' / ', // 图片数量的分隔符
  bgOpacity: 0.5,
};
Vue.use(preview, options);
export default {
  props: {
    assigneesDatas: {
      type: Array,
    },
    subDetailType: {
      type: String,
      default: 'teacher',
    },
  },
  data() {
    return {
      defaultImg,
      finished,
      multi,
      isMore: '',
      isRefuse: false,
      isSuccess: false,
      roleArray: ['TEACHER_ADMIN', 'QA_MEMBER', 'QA_LEADER', 'TEACHER_ADMIN_AST'],
    };
  },
  mounted() {
    this.$previewRefresh();
  },
  methods: {
    moment,
    moreHandler(data) {
      this.isMore = this.isMore === data ? '' : data;
    },
  },
};
</script>
<style scoped>
.handler-more,
.handler-one {
  margin-bottom: 10px;
}
p {
  margin: 0;
}
.handler-main-more,
.handler-main {
  display: flex;
  justify-content: flex-start;
}
.handler-img-more,
.handler-img {
  width: 32px;
  height: 32px;
  border-radius: 50%;
}
.line {
  width: 0;
  height: 100%;
  border-left: 1px solid #eee;
  margin: auto;
}
.handlering {
  border: 1px solid #009cff;
}
.classAdmin .handlering {
  border: 1px solid #43d186;
}
.morehandler-info {
  width: 93%;
  margin-left: 10px;
}
.handler-teacher-more {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  font-size: 16px;
  color: #999;
  margin-bottom: 10px;
}
.teacher-list-more {
  width: 80%;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.teacher-list-more .transfer {
  margin: 0 10px;
  color: #999 !important;
}
.teacher-list-more p {
  display: inline-block;
  max-width: 43%;
  line-height: 22px;
  color: #333;
}
.teacher-list-more span {
  margin-left: 10px;
  color: #999;
}
.teacher-list-more .ccNumber {
  font-size: 14px;
  width: 100%;
  margin-left: 10px;
  color: #999;
}
.morehandler-name-more {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-top: 10px;
  color: #333;
  font-size: 14px;
}
.morehandler-name-more .teacher-info {
  width: 30%;
  margin-bottom: 10px;
}
.teacher-info-more {
  max-width: 80%;
  min-width: 33%;
}
.teacher-img-more {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  margin: 0 10px;
}
.teacher-name {
  color: #333;
}
.teacher-name span {
  color: #009cff;
  margin: 0 5px;
}
.classAdmin .teacher-name span {
  color: #43d186;
}
.classAdmin .handler-one .handler-info .handler-teacher .teacher-list p span {
  color: #43d186;
}
.turn-name {
  max-width: 66%;
}
.more {
  width: 100%;
}
.handler-info {
  width: 93%;
  margin-left: 10px;
}
.handler-teacher {
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  color: #999;
  margin-bottom: 10px;
}
.teacher-list {
  width: 80%;
  display: flex;
  justify-content: flex-start;
}
.turnCls {
  display: inline-block;
  width: 60px;
  text-align: center;
  color: #999;
}
.transfer {
  margin: 0 10px;
  color: #999 !important;
}
.transfer p {
  display: inline-block;
  max-width: 43%;
  line-height: 22px;
  color: #333;
}
.transfer p span {
  color: #009cff;
}
.teacher-name {
  max-width: 74%;
}
.handler-status {
  font-size: 14px;
  padding: 2px 10px;
  border-radius: 6px;
}
.pending-status {
  color: #ffbf00 !important;
  background-color: rgba(255, 191, 0, 0.09);
}
.success-status {
  color: #00cf64 !important;
  background-color: rgba(0, 207, 100, 0.09);
}
.refuse-status {
  color: #ff5353 !important;
  background-color: rgba(255, 83, 83, 0.09);
}
.cancel-status {
  color: #454444;
  background-color: rgba(118, 114, 114, 0.09);
}
.handler-reply {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  margin: 10px 0;
  padding: 10px;
  border-radius: 10px;
  background-color: #f7f7f7;
}
.handler-reply p {
  font-size: 16px;
  color: #333333;
  line-height: 22px;
}
.commentImg {
  width: 120px;
  height: 120px;
}
.reply-btn {
  color: #333;
  margin-top: 20px;
}
.reply-btn span {
  color: #009cff;
}
.reply-btn:hover {
  cursor: pointer;
}
.handler-status {
  font-size: 14px;
  padding: 2px 10px;
  border-radius: 6px;
  /* color: #ffbf00; */
}
/* .refuse { */
.refuseStatus {
  border: 1px solid #eee;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}
.refuseStatus span {
  display: inline-block;
  width: 12px;
  height: 12px;
  border-radius: 6px;
  background-color: #ddd;
}
/* } */
</style>
