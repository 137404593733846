var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.dataInfos)?_vm._l((_vm.dataInfos),function(item){return _c('div',{key:item.uuid},[(
          _vm.originType === 'teacher' &&
          ((item.exerciseType !== 'SUBJECTIVE_EXERCISE' && item.exerciseType !== 'COMPLEX_SUBJECTIVE') ||
            (item.questionTopicType !== 'SUBJECTIVE_EXERCISE' && item.questionTopicType !== 'COMPLEX_SUBJECTIVE'))
        )?_c('div',{staticClass:"read-report"},[_c('div',{staticClass:"report-title"},[_c('InfoTitle',{attrs:{"title":item.linkName}}),_c('p',{domProps:{"textContent":_vm._s(item.linkAnswerType === 'QUESTS' ? '闯关模式' : '测试模式')}})],1),(item.exerciseType !== 'SUBJECTIVE_EXERCISE' || item.questionTopicType !== 'SUBJECTIVE_EXERCISE')?_c('p',{staticStyle:{"margin-top":"4px"},on:{"click":function($event){return _vm.onReportHistory(item.linkId)}}},[_vm._v(" "+_vm._s(_vm.subject === 'MATH' ? 'View historical reports >' : '查看历史报告 >')+" ")]):_vm._e()]):_vm._e(),(
          item.reportData &&
          ((item.exerciseType !== 'SUBJECTIVE_EXERCISE' && item.exerciseType !== 'COMPLEX_SUBJECTIVE') ||
            (item.questionTopicType !== 'SUBJECTIVE_EXERCISE' && item.questionTopicType !== 'COMPLEX_SUBJECTIVE'))
        )?_c('div',[(_vm.subject === 'MATH')?_c('MathReport',{attrs:{"dataInfo":item || {},"explainData":_vm.explainData,"checkData":_vm.checkData,"parentaudio":_vm.parentaudio},on:{"audioPlay":_vm.audioPlay,"clearAudio":_vm.clearAudio,"explainResolution":_vm.explainResolution}}):(item.exerciseType === 'READER_EXERCISE')?_c('PictureReaderReport',{attrs:{"dataInfo":item || {},"explainData":_vm.explainData,"checkData":_vm.checkData,"parentaudio":_vm.parentaudio},on:{"audioPlay":_vm.audioPlay,"clearAudio":_vm.clearAudio,"explainResolution":_vm.explainResolution}}):_c('ReadReport',{attrs:{"dataInfo":item || {},"explainData":_vm.explainData,"checkData":_vm.checkData,"parentaudio":_vm.parentaudio},on:{"audioPlay":_vm.audioPlay,"clearAudio":_vm.clearAudio,"explainResolution":_vm.explainResolution}})],1):_c('div',[_c('InfoTitle',{attrs:{"title":item.linkName}}),(_vm.originType === 'teacher')?[_vm._t("default",null,{"datas":item})]:[_c('ClassDoReport',{attrs:{"dataInfo":item || {},"teacherId":_vm.teacherUuid}})]],2)])}):_vm._e(),(_vm.currentView === 'ReportHistoryOperation')?_c('ReportHistoryOperation',{attrs:{"originType":_vm.originType,"reportHistory":_vm.reportHistory,"historyType":_vm.historyType,"studentReview":_vm.studentReview,"studentId":_vm.studentId,"explainData":_vm.explainData,"checkData":_vm.checkData,"subject":_vm.subject},on:{"explainResolution":_vm.explainResolution}},[[_vm._t("default")]],2):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }