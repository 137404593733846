<template>
  <div class="applicant-main" :class="subDetailType" v-if="applicant.reles">
    <p class="title">申请人</p>
    <div class="student-item">
      <img :src="defaultImg" alt="" class="handler-img" />
      <div>
        <p class="student-name">{{ applicant.name }}</p>
        <p>{{ applicant.reles || '无' }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import defaultImg from '../assets/service_pic_head.png';

export default {
  props: {
    applicant: {
      type: Object,
    },
    subDetailType: {
      // 区分是老师还是班主任，班主任主题色是绿色
      type: String,
      default: 'teacher',
    },
  },
  data() {
    return {
      defaultImg,
    };
  },
  methods: {},
};
</script>

<style scoped>
.title {
  font-size: 20px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #333333;
  line-height: 28px;
  margin: 20px 0;
}
.title::before {
  content: '';
  display: block;
  float: left;
  margin: 4px 12px 0 0;
  width: 4px;
  height: 20px;
  background-color: #009cff;
  border-radius: 0px 6px 6px 0px;
}
.student-item {
  margin-bottom: 20px;
  display: flex;
  justify-content: flex-start;
}
.student-item p {
  font-size: 14px;
  font-weight: 400;
  color: #999999;
  line-height: 20px;
}
.student-item img {
  width: 42px;
  height: 42px;
  border-radius: 50%;
  margin-right: 7px;
}
.student-item .student-name {
  font-size: 16px;
  font-weight: 600;
  color: #333333;
  line-height: 22px;
}
.classAdmin .title::before {
  background-color: #43d186;
}
</style>
