<template>
  <div>
    <div v-if="taskStatus === 'complate'" class="finishTaskForm">
      <p class="finishTask-title">确认完成“{{ taskTypeName }}”任务吗？</p>
      <p style="margin-bottom: 8px" v-if="taskType === 'TASK_RESIGN_STUDENT_NEW_CLASSADMIN'">
        注意：完成该任务后，<span style="color: #43d186">自动成为该学生的新班主任</span>
      </p>

      <a-form :form="finishTaskForm">
        <template v-if="taskType === 'LESSON_PROGRESS_RELATED'">
          <a-form-item label="是否调整">
            <a-radio-group
              :default-value="isAdjusted"
              v-decorator="['isAdjusted', { rules: [{ required: true, message: '请选择是否调整课节' }] }]"
              @change="handleChangeAdjust"
            >
              <a-radio :value="true"> 是 </a-radio>
              <a-radio :value="false">否</a-radio>
            </a-radio-group>
          </a-form-item>
          <a-form-item label="调整后首课" v-if="isAdjusted">
            <a-select
              v-decorator="['adjustedFirstLessonId', { rules: [{ required: true, message: '首课课节不能为空' }] }]"
              placeholder="请选择"
            >
              <a-select-option :value="item.uuid" :key="index" v-for="(item, index) in courseSubScheduleArr">
                {{ item.courseSectionRespDTO.structure }} {{ item.startChinaDateTime }} {{ item.teacher.fullName }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </template>

        <a-form-item
          v-bind="formItemLayout1"
          label="课节调整状态"
          v-if="taskType === 'APPLICATION_FOR_TEACHER_LEAVE' && adjustScheduleProcess !== 'ALL'"
        >
          <a-radio-group
            v-decorator="['adjustScheduleProcess', { rules: [{ required: true, message: '请选择课节调整状态' }] }]"
          >
            <a-radio value="NONE"> 未调整 </a-radio>
            <a-radio value="PART">部分调整 </a-radio>
            <a-radio value="ALL">全部调整 </a-radio>
          </a-radio-group>
        </a-form-item>
        <!-- <a-form-item
          v-bind="formItemLayout"
          label="是否更换了授课老师"
          v-if="(taskType === 'TAKE_OVER' || taskType === 'CHINESE_RESIGN_TAKE_OVER') && classType === '私教课'"
        >
          <a-radio-group @change="changRadio" v-decorator="['flag', { rules: [{ required: true }] }]">
            <a-radio :value="true"> 是 </a-radio>
            <a-radio :value="false"> 否 </a-radio>
          </a-radio-group>
        </a-form-item> -->
        <a-form-item label="请确认新授课老师的首课课节：" v-if="taskOverIsValidate">
          <a-select
            v-decorator="[
              'firstCourseObjId',
              { rules: [{ required: classType === '班课', message: '首课课节不能为空' }] },
            ]"
            placeholder="请选择"
          >
            <a-select-option :value="item.uuid" :key="index" v-for="(item, index) in courseScheduleArr">
              {{ item.courseSectionRespDTO.structure }} {{ item.startChinaDateTime }} {{ item.teacher.fullName }}
            </a-select-option>
          </a-select>
          <p style="font-size: 12px; color: #d38d01">（ 提交后将生成首课回访任务，请仔细确认 ）</p>
        </a-form-item>

        <a-form-item
          v-if="['APPLICATION_FOR_TEACHER_EN', 'TAKE_OVER_EN', 'RESIGN_TAKE_OVER_EN'].includes(taskType)"
          label="完成描述(填写后传递至【教学侧】)"
        >
          <a-textarea
            v-decorator="['message', { rules: [{ required: true, message: '请填写完成描述' }] }]"
            placeholder="填写后该信息将传递至【教学侧】"
            :auto-size="{ minRows: 6 }"
          />
        </a-form-item>
        <a-form-item v-else>
          <a-textarea v-decorator="['message', {}]" placeholder="补充说明（选填）" :auto-size="{ minRows: 6 }" />
        </a-form-item>
      </a-form>
    </div>

    <div v-if="taskStatus === 'refuse'" class="finishTaskForm">
      <p class="finishTask-title">确认拒绝“{{ taskTypeName }}”任务吗？</p>
      <a-form :form="refuseTaskForm" :label-col="{ span: 5 }" :wrapper-col="{ span: 24 }">
        <a-form-item>
          <a-textarea
            v-decorator="['reason', { rules: [{ required: true, message: '请说明拒绝理由' }] }]"
            placeholder="请说明拒绝理由（必填）"
            :auto-size="{ minRows: 6 }"
          />
        </a-form-item>
      </a-form>
    </div>
    <div v-if="taskStatus === 'comment'" class="finishTaskForm">
      <p class="finishTask-title">评论“{{ taskTypeName }}”任务</p>
      <a-form :form="commentTaskForm" :label-col="{ span: 5 }" :wrapper-col="{ span: 24 }">
        <a-form-item>
          <a-textarea
            v-decorator="['message', { rules: [{ required: true, message: '请输入评论' }] }]"
            placeholder="请输入评论"
            :auto-size="{ minRows: 6 }"
          />
        </a-form-item>
        <a-form-item v-if="source === 'teacher'">
          <a-upload
            action="https://tch-studio-dev.wukongacademy.com/api/admin/file/sts/public"
            name="file"
            :file-list="fileList"
            list-type="picture-card"
            @change="handleChange"
            v-decorator="['imgList', {}]"
          >
            <div v-if="fileList.length < 6">
              <!-- <a-icon type="plus" /> -->
              <a-icon :type="uploadLoading ? 'loading' : 'plus'" />
              <div class="ant-upload-text">上传图片</div>
            </div>
          </a-upload>
        </a-form-item>
      </a-form>
    </div>
    <div v-if="taskStatus === 'CC'" class="finishTaskForm">
      <p class="finishTask-title">抄送“{{ taskTypeName }}”任务</p>
      <a-form :form="ccTaskForm" :label-col="{ span: 2 }" :wrapper-col="{ span: 24 }">
        <a-form-item label="抄送人">
          <a-select
            show-search
            size="large"
            mode="multiple"
            v-decorator="['userIds', { rules: [{ required: true, message: '搜索或选择抄送人!' }] }]"
            placeholder="搜索或选择抄送人"
            :filterOption="filterOption"
            @change="changeCarbonCopy"
            @search="searchCarbonCopy"
          >
            <a-select-option v-for="(item, index) in carbonCopyArray" :key="index" :value="item.uuid">
              {{ item.fullName }} {{ item.departmentsName }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item>
          <a-textarea v-decorator="['message', {}]" placeholder="抄送原因（选填）" :auto-size="{ minRows: 6 }" />
        </a-form-item>
      </a-form>
    </div>
    <div v-if="taskStatus === 'turn'" class="finishTaskForm">
      <p class="finishTask-title">转交“{{ taskTypeName }}”任务</p>
      <a-form :form="turnTaskForm" :label-col="{ span: 2 }" :wrapper-col="{ span: 24 }">
        <a-form-item label="转交人">
          <a-select
            show-search
            size="large"
            v-decorator="['turnAssigneeId', { rules: [{ required: true, message: '搜索或选择转交人!' }] }]"
            placeholder="搜索或选择转交人"
            option-filter-prop="children"
            :filterOption="filterOption"
            @blur="handleBlur"
            @change="changeCarbonCopy"
            @search="searchCarbonCopy"
          >
            <a-select-option v-for="(item, index) in carbonCopyArray" :key="index" :value="item.uuid">
              {{ item.fullName }} {{ item.departmentsName }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item>
          <a-textarea
            v-decorator="['message', { rules: [{ required: true, message: '请输入转交原因' }] }]"
            placeholder="转交原因（必填）"
            :auto-size="{ minRows: 6 }"
          />
        </a-form-item>
      </a-form>
    </div>
    <div v-if="taskStatus === 'REPORT'" class="finishTaskForm">
      <p class="finishTask-title">确认提交“学生阶段学习报告”吗，报告提交后不可再次修改任务</p>
    </div>
    <div class="taskFormBtn">
      <!-- <a-button :style="{ marginRight: '8px' }" @click="onClose"> 取消 </a-button> -->
      <a-button type="primary" @click="onSubmit" v-if="taskStatus === 'complate'"> 确认完成 </a-button>
      <a-button type="primary" @click="onSubmit" v-if="taskStatus === 'comment'"> 添加评论 </a-button>
      <a-button type="danger" @click="onSubmit" v-if="taskStatus === 'refuse'"> 确认拒绝 </a-button>
      <a-button type="primary" @click="onSubmit" v-if="taskStatus === 'CC'"> 确认抄送 </a-button>
      <a-button type="primary" @click="onSubmit" v-if="taskStatus === 'turn'"> 确认转交 </a-button>
      <a-button
        type="primary"
        @click="onSubmit"
        v-if="taskStatus === 'REPORT'"
        :disabled="submitStudy"
        :loading="submitStudy"
      >
        报告提交
      </a-button>
    </div>
  </div>
</template>
<script>
import Vue from 'vue';
import axios from 'axios';
import storage from 'store';
import sessionStorage from 'store/storages/sessionStorage';
import { Drawer, Button, Form, Input } from 'ant-design-vue';
import { courseScheduleInfo } from '@/api/headTeacher';

Vue.use(Button);
Vue.use(Drawer);
Vue.use(Form);
Vue.use(Input);
const session = storage.createStore(sessionStorage);
// const token = storage.get('rememberMe') ? storage.get('Access-Token') : session.get('Access-Token');
const token = storage.get('Access-Token');

export default {
  props: {
    formInfoData: {
      type: Object,
      default: () => {},
    },
    adjustScheduleProcess: {
      type: String,
    },
    taskDefinitionKey: {
      type: String,
    },
    classType: {
      type: String,
    },
    courseType: {
      type: String,
    },
    taskType: {
      type: String,
      default: '',
    },
    taskTypeName: {
      type: String,
      default: '',
    },
    baseUrl: {
      type: String,
      default: '',
    },
    taskStatus: {
      type: String,
      default: '',
    },
    source: {
      type: String,
      default: '',
    },
    studeyData: {
      type: Object,
      default: () => {},
    },
    courseScheduleArr: {
      type: Array,
      default: () => [],
    },
  },
  emits: ['onSubmit'],
  data() {
    return {
      formItemLayout1: {
        labelCol: {
          sm: { span: 4 },
        },
        wrapperCol: {
          sm: { span: 16 },
        },
      },

      formItemLayout: {
        labelCol: {
          xs: { span: 24 },
          sm: { span: 6 },
        },
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 16 },
        },
      },
      title: `任务`,
      token,
      loadingBol: false,
      visible: true,
      finishTaskForm: this.$form.createForm(this),
      refuseTaskForm: this.$form.createForm(this),
      commentTaskForm: this.$form.createForm(this),
      ccTaskForm: this.$form.createForm(this),
      turnTaskForm: this.$form.createForm(this),
      reportTaskForm: this.$form.createForm(this),
      carbonCopyArray: [],
      childrenOptions: [],
      submitStudy: false,
      fileList: [],
      uploadLoading: false,
      // 是否调整课节
      isAdjusted: true,
      courseSubScheduleArr: [],
    };
  },
  computed: {
    taskOverIsValidate() {
      return (
        (this.classType === '私教课' &&
          ((this.taskType === 'TAKE_OVER' && this.taskDefinitionKey === 'sid-321221BE-14B7-4D92-838B-9256759AC9C1') ||
            (this.taskType === 'CHINESE_RESIGN_TAKE_OVER' &&
              this.taskDefinitionKey === 'sid-F8B5A25A-037A-4475-8B53-C38882258958'))) ||
        (this.classType === '班课' &&
          ((this.taskType === 'TAKE_OVER' && this.taskDefinitionKey === 'sid-35A0A91F-6A52-4BDA-B958-450CAFDDEE69') ||
            (this.taskType === 'CHINESE_RESIGN_TAKE_OVER' &&
              this.taskDefinitionKey === 'sid-512A7E07-7F26-4E6F-9F71-FCF1CCF61082'))) ||
        (['标准课', 'STANDAD'].includes(this.courseType) &&
          ['RESIGN_TAKE_OVER_EN', 'TAKE_OVER_EN'].includes(this.taskType))
      );
    },
  },
  mounted() {
    if (
      this.taskStatus === 'complate' &&
      (this.taskType === 'TAKE_OVER' || this.taskType === 'CHINESE_RESIGN_TAKE_OVER') &&
      this.classType === '私教课'
    ) {
      this.finishTaskForm.setFieldsValue({ flag: true });
    }
    if (this.taskStatus === 'turn' || this.taskStatus === 'CC') {
      this.searchCarbonCopy();
    }

    console.log('this.taskType', this.formInfoData, this.taskType);
    if (this.taskType === 'LESSON_PROGRESS_RELATED') {
      this.finishTaskForm.setFieldsValue({
        isAdjusted: this.isAdjusted,
      });
      courseScheduleInfo({
        formClassId: this.formInfoData.courseInfo.formClassId,
        scheduleStatus: 'NORMAL',
        processInstanceId: this.formInfoData.processInstanceId,
      }).then((res) => {
        this.courseSubScheduleArr = res.data.content;
      });
    }
  },
  methods: {
    handleChangeAdjust(e) {
      this.isAdjusted = e.target.value;
    },
    handleChange(info) {
      if (info.file.status === 'removed') {
        this.fileList = info.fileList;
        if (this.fileList.length === 0) {
          this.images = [];
          this.commentTaskForm.setFieldsValue({ imgList: undefined });
        }
      } else {
        this.uploadLoading = true;
        axios
          .get(`${this.baseUrl}/api/admin/file/sts/public`, { headers: { Authorization: `Bearer ${this.token}` } })
          .then((res) => {
            this.uploadToOss(info, res.data.content);
          });
      }
    },
    uploadToOss(info, fileData) {
      const formData = new FormData();
      const formArray = Object.entries(fileData.formFields);
      formArray.forEach((item) => {
        formData.append(item[0], item[1]);
      });
      formData.append('file', info.file.originFileObj);
      axios
        .post(`https://${fileData.endpoint}`, formData)
        .then((res) => {
          this.uploadLoading = false;
          this.fileList.push({
            uid: res.data.url,
            name: 'image.png',
            status: 'done',
            url: res.data.url,
          });
        })
        .finally(() => {
          // this.loading = false;
        });
    },
    // changRadio(event) {
    //   this.showClassFlag = event.target.value;
    // },
    handleBlur() {
      this.searchCarbonCopy();
    },
    changeCarbonCopy(value) {
      if (value.length === 0) {
        this.searchCarbonCopy();
      }
    },
    searchCarbonCopy(value) {
      axios
        .post(
          `${this.baseUrl}/api/teacher/work_flow/task/carbon_copy/user/search/1`,
          { name: value },
          { headers: { Authorization: `Bearer ${this.token}` } },
        )
        .then((res) => {
          this.carbonCopyArray = res.data.content.content;
        });
    },
    filterOption(input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    },
    // complate refuse comment
    onSubmit(e) {
      e.preventDefault();
      let formType = null;
      switch (this.taskStatus) {
        case 'complate':
          formType = this.finishTaskForm;
          break;
        case 'refuse':
          formType = this.refuseTaskForm;
          break;
        case 'comment':
          formType = this.commentTaskForm;
          break;
        case 'CC':
          formType = this.ccTaskForm;
          break;
        case 'turn':
          formType = this.turnTaskForm;
          break;
        default:
          break;
      }
      if (this.taskStatus === 'REPORT') {
        let studyData = null;
        studyData = { ...this.studeyData };
        const FeedbackData = {
          taskType: this.taskStatus,
          formData: studyData,
        };
        this.submitStudy = true;
        this.$emit('onSubmit', FeedbackData);
        return;
      }
      formType.validateFields((err, values) => {
        if (!err) {
          const formData = values;
          if (this.taskStatus === 'complate') {
            formData.userId = this.accountId;
          } else if (this.taskStatus === 'comment' || this.taskStatus === 'CC') {
            formData.createdBy = this.accountId;
            if (this.fileList.length !== 0) {
              delete formData.imgList;
              formData.images = this.fileList;
            }
          }
          const schedule = this.courseScheduleArr?.find?.((item) => item.uuid === formData.firstCourseObjId);

          formData.newSchedule = schedule
            ? `${schedule.courseSectionRespDTO.sectionName} ${schedule.startChinaDateTime}`
            : undefined;
          formData.taskOverIsValidate = this.taskOverIsValidate;

          if (this.taskType === 'LESSON_PROGRESS_RELATED') {
            formData.adjustedFirstLesson = this?.courseSubScheduleArr?.find(
              (item) => item.uuid === formData.adjustedFirstLessonId,
            );
          }
          const FeedbackData = {
            taskType: this.taskStatus,
            formData,
          };
          this.$emit('onSubmit', FeedbackData);
        }
      });
    },
    onClose() {
      // this.visible = false;
      // console.log(this.$parent);
      // this.$parent.clearnCurrentView();
      // this.$emit('cancal', null);
      this.$emit('clearnCurrentView');
    },
  },
};
</script>
<style scoped>
.finishTask-title {
  font-size: 16px;
  font-weight: 600;
  color: #333333;
  line-height: 22px;
  margin-bottom: 20px;
}
.taskFormBtn {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 100%;
  border-top: 1px solid #e9e9e9;
  padding: 10px 16px;
  background: '#fff';
  text-align: right;
  z-index: 1;
}
.taskFormBtn.ant-btn {
  border-radius: 6px;
}
.taskFormBtn .ant-btn-primary {
  background-color: #009cff;
}
.taskFormBtn .ant-btn-danger {
  color: #fff;
  background-color: #ff5353;
  border-color: #ff5353;
}
.finishTaskForm {
  height: calc(100vh - 90px);
}
/deep/ .ant-form-item-label {
  text-align: left;
}
</style>
