import { curry } from 'ramda';
import { fetch, post } from './index';

const BASE_URL = process.env.VUE_APP_API_IM_URL;

const get = (baseURL) => curry(fetch)(baseURL);

export const getStudentTags = ({ uuid, subject }) => fetch(BASE_URL, `/api/student/${uuid}/${subject}/tags`);

// 获取学生详情顶部标签列表
export const getLayerTagsList = () => {
    return fetch(BASE_URL, `/api/student/rank/list`)
}

// 获取任务学员列表
export const getTaskStudentList = (params) => {
    return post(BASE_URL, `/api/account/serve/student/statistics/search`, params)

}

// 获取学生的流失记录
export const getStudentNotRenewList = (params) => {
    return post(BASE_URL, `/api/admin/scheduling/loss_refund/search/1`, params)
}