<template>
  <div class="reportMath-main">
    <template v-if="JSON.stringify(dataInfo) !== '{}'">
      <div class="report-header">
        <div class="subtract-title">
          <div class="subtract">
            <img src="../assets/Subtract.png" alt="" />
          </div>
          <div>
            <p class="report-name">
              {{ moment(new Date(dataInfo.whenCreated)).format('YYYY年MM月DD日') }}{{ dataInfo.linkName }}报告
            </p>
            <div class="answer-time">
              <div>
                <a-icon type="clock-circle" /> Total answer time:<span>{{
                  classOutInTime(dataInfo.totalAnswerTime) || 'No data'
                }}</span>
              </div>
              <div>
                <a-icon type="up-circle" />Submission time:
                <span>{{ dataInfo.whenCreated || 'No data' }}</span>
              </div>
            </div>
          </div>
        </div>

        <div class="fraction-main">
          <p v-if="isErrorNumber && dataInfo.linkAnswerType !== 'QUIZ_MODE' && dataInfo.linkAnswerType !== 'QUESTS'">
            Number of errors in interactive questions ：
            <span class="fraction">{{ dataInfo.totalErrorNumber || 0 }}</span>
          </p>
          <p v-if="dataInfo.whetherScore">
            Total score： <span class="fraction">{{ dataInfo.reportData.score || 0 }}</span>
          </p>
        </div>
        <div class="answers-overview">
          <p class="all-answers">Answers overview :</p>
          <div class="answers">
            <span class="correct">{{ getAllAnswer.correctNumber }}</span>
            Correct
          </div>
          <div class="answers">
            <span class="error">{{ getAllAnswer.errorNumber }}</span>
            Wrong
          </div>
          <div class="answers">
            <span class="skip">{{ getAllAnswer.skipNumber }}</span>
            Not done
          </div>
          <div class="answers">
            <span>{{ data.length }}</span>
            TOTAL
          </div>
        </div>
      </div>
      <div class="report-expand">
        <p class="expand-btn" @click="onExpand">
          {{ is_expand ? 'Expand report' : 'Collapse report' }}
          <a-icon :type="is_expand ? 'down' : 'up'" />
        </p>
      </div>
      <div v-if="!is_expand" class="report-list">
        <div class="topics-list" :key="idx" v-for="(l, idx) in Math.ceil(data.length / 10)">
          <div class="topics-item topics-title">
            <div>题号</div>
            <div><p>题型</p></div>
            <div>答题情况</div>
          </div>

          <div class="topics-item" v-for="(item, index) in data.slice(idx * 10, (idx + 1) * 10)" :key="index">
            <div>{{ idx * 10 + index + 1 }}</div>
            <div class="topics-main">{{ questionType(item.questionType) }}</div>
            <div
              v-if="item.answerStatus && dataInfo.linkAnswerType === 'QUIZ_MODE'"
              :style="{
                background:
                  item.answerStatus === 'ERROR' ? '#fdeeee' : item.answerStatus === 'SKIP' ? '#F2F2F2' : '#f1faf5',
              }"
            >
              <img v-if="item.answerStatus === 'CORRECT'" src="../assets/Frame 36.png" alt="" />
              <img v-else-if="item.answerStatus === 'ERROR'" src="../assets/Frame 45.png" alt="" />
              <img v-else-if="item.answerStatus === 'SKIP'" src="../assets/FrameNo.png" alt="" />
            </div>
            <div
              v-else
              :style="{
                background: countanswerStatus(item.questionType, item.answerStatus, item.errorNumber)
                  ? '#f1faf5'
                  : '#fdeeee',
              }"
            >
              <img
                v-if="countanswerStatus(item.questionType, item.answerStatus, item.errorNumber)"
                src="../assets/Frame 36.png"
                alt=""
              />
              <img v-else src="../assets/Frame 45.png" alt="" />
            </div>
          </div>
        </div>
        <div v-for="(item, index) in data" :key="item.id">
          <div class="report-item">
            <div class="item-title">
              <div class="face-icon">
                <template v-if="item.answerStatus && dataInfo.linkAnswerType === 'QUIZ_MODE'">
                  <img v-if="item.answerStatus === 'CORRECT'" src="../assets/Frame 36.png" alt="" />
                  <img v-else-if="item.answerStatus === 'ERROR'" src="../assets/Frame 45.png" alt="" />
                  <img v-else-if="item.answerStatus === 'SKIP'" src="../assets/FrameNo.png" alt="" />
                </template>
                <template v-else>
                  <img
                    v-if="countanswerStatus(item.questionType, item.answerStatus, item.errorNumber)"
                    src="../assets/Frame 36.png"
                    alt=""
                  />
                  <img v-else src="../assets/Frame 45.png" alt="" />
                </template>
                <!-- <img v-if="!item.errorNumber" src="../assets/Frame 36.png" alt="" />
                <img v-else src="../assets/Frame 45.png" alt="" /> -->
              </div>

              <p
                v-if="
                  item.questionType !== 'MATH_FILLING' &&
                  item.questionType !== 'EXPLAN_CHOICE' &&
                  item.questionType !== 'SINGLE_CHOICE' &&
                  item.questionType !== 'MATH_CHOICE' &&
                  item.questionType !== 'MATH_COMPLETE'
                "
              >
                {{ index + 1 }} 、{{ item.title }}
              </p>
              <div v-else style="display: flex; justify-content: flex-start; flex: 1">
                <p>{{ index + 1 }}、</p>
                <div>
                  <QuillRender :delta="transformStringToDelta(item.title)" />
                </div>
              </div>
              <div class="questionDifficulty">Question difficulty: {{ item.questionDifficulty }}</div>
            </div>

            <div class="answer">
              <p
                style="font-size: 16px; font-weight: bold"
                v-if="item.errorOptions"
                :class="item.correctOptions !== item.errorOptions ? 'answer-error' : 'answer-correct'"
              >
                Student Answer:
                <span v-if="item.questionType === 'MATH_COMPLETE'">
                  <span v-for="(item1, key) in JSON.parse(item.errorOptions.replace(/\r\n/g, ''))" :key="key">
                    <span v-html="item1['0'] && stringToHtml(item1['0'].replaceAll(`\\placeholder{}`, ''))"></span>
                  </span>
                </span>
                <span v-else-if="item.questionType === 'MATH_CHOICE'">
                  {{ String.fromCharCode(65 + Number(item.errorOptions)) }}
                </span>
                <span v-else>
                  {{ item.errorOptions }}
                </span>
              </p>
              <p
                class="answer-correct"
                style="font-size: 16px; font-weight: bold"
                v-if="item.correctOptions !== item.errorOptions"
              >
                Correct Answer:
                <span v-if="item.questionType === 'MATH_COMPLETE'">
                  <span v-for="(item1, key) in JSON.parse(item.correctOptions.replace(/\r\n/g, ''))" :key="key">
                    <span v-html="item1['0'] && stringToHtml(item1['0'][0])"></span>
                  </span>
                </span>
                <span v-else-if="item.questionType === 'MATH_CHOICE'">
                  {{ String.fromCharCode(65 + Number(item.correctOptions)) }}
                </span>
                <span v-else>
                  {{ item.correctOptions }}
                </span>
              </p>
              <!--闯关模式才展示-->
              <p style="font-size: 16px; font-weight: bold; color: #009cff" v-if="dataInfo.linkAnswerType === 'QUESTS'">
                Answer Time: {{ item.answerTime }} s
              </p>
            </div>
            <div class="item-ai" @click="explainResolution(item.questionId)">
              <span style="margin-right: 12px">Problem analysis</span>
              <a-icon
                style="font-size: 16px; font-weight: bold"
                :type="JSON.stringify(explainData) !== '{}' && questionId === item.questionId ? 'up' : 'down'"
              />
            </div>
            <div class="explain-main" v-if="explainData && questionId === item.questionId">
              <!--explainData.explan返回的是对象，新数学填空题-->
              <div v-if="item.questionType === 'MATH_COMPLETE'">
                <QuillRender
                  v-if="explainData.explan && explainData.explan.content"
                  :delta="explainData.explan.content"
                />
                <video
                  v-if="explainData.explan && explainData.explan.video"
                  class="explain-video"
                  :src="explainData.explan.video"
                  controls="controls"
                ></video>
                <div class="item-audio" v-if="explainData.explan && explainData.explan.audio">
                  <audio :src="explainData.explan.audio" controls="controls" preload></audio>
                </div>
              </div>
              <div v-else-if="item.questionType === 'MATH_CHOICE'">
                <QuillRender v-if="explainData.explan" :delta="explainData.explan" />
                <video
                  v-if="explainData.explanVideo"
                  class="explain-video"
                  :src="explainData.explanVideo"
                  controls="controls"
                ></video>
                <div class="item-audio" v-if="explainData.audio">
                  <audio :src="explainData.audio" controls="controls" preload></audio>
                </div>
              </div>
              <div v-else>
                <div v-for="(item, index) in explainData.explan" :key="index">
                  <p v-if="typeof item.insert === 'string' && item.insert.replace(/\r\n/g, '')">
                    {{ item.insert.replace(/\r\n/g, '') }}
                  </p>
                  <img v-if="item.insert.image" :src="item.insert.image" style="width: 100%" alt="" />
                  <p v-if="item.insert.formula">{{ item.insert.formula }}</p>
                </div>
                <video
                  v-if="explainData.explanVideo"
                  class="explain-video"
                  :src="explainData.explanVideo"
                  controls="controls"
                ></video>
                <div class="item-audio" v-if="explainData.explanAudio">
                  <audio :src="explainData.explanAudio" controls="controls" preload></audio>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
    <div v-else class="empty">
      <p>The report has not yet been submitted</p>
      <img src="../assets/pic_emptypage.svg" alt="" />
    </div>
  </div>
</template>
<script>
import { Exercise, withIframe } from '@wk/iexercise';
import moment from 'moment';
import QuillRender, { stringToHtml } from '@wk/iexercise/lib/components/QuillRender';
import { transformStringToDelta } from '@wk/iexercise/lib/utils/quill';

export default {
  components: { QuillRender, Exercise: withIframe(Exercise) },
  props: {
    dataInfo: {
      type: Object,
      default: () => {},
    },
    parentaudio: {
      type: String,
      default: '',
    },
    checkData: {
      type: Array,
      default: () => [],
    },
    explainData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      moment,
      stringToHtml,
      questionId: '',
      // audio: null,
      isAudio: false,
      data: this.dataInfo?.reportData?.answerRecord || [],
      linkId: this.dataInfo?.linkId,
      is_expand: true,
      showInfo: '',
      correctInfoData: null,
      errorInfoData: null,
      explanShow: false,
      showError: true,
    };
  },
  computed: {
    isAverageScore() {
      return this.data.some((item) => item.questionType === 'PICTURE_BOOK');
    },
    isErrorNumber() {
      return this.data.some((item) => item.questionType !== 'PICTURE_BOOK');
    },
    getAllAnswer() {
      let errorNumber = 0;
      let correctNumber = 0;
      let skipNumber = 0;
      this.data.forEach((item) => {
        if (item.answerStatus && this.dataInfo.linkAnswerType === 'QUIZ_MODE') {
          if (item.answerStatus === 'CORRECT') {
            correctNumber += 1;
          } else if (item.answerStatus === 'ERROR') {
            errorNumber += 1;
          } else if (item.answerStatus === 'SKIP') {
            skipNumber += 1;
          }
        } else if (this.countanswerStatus(item.questionType, item.answerStatus, item.errorNumber)) {
          correctNumber += 1;
        } else {
          errorNumber += 1;
        }
      });
      return { correctNumber, errorNumber, skipNumber };
    },
  },
  methods: {
    transformStringToDelta,
    countanswerStatus(questionType, answerStatus, errorNumber) {
      const oldQuestionType = [
        'CHOICE',
        'CONNECTION',
        'PICTURE_BOOK',
        'BLANK_FILLING',
        'EXPLAN_CHOICE',
        'ARTICLE_FILLING',
        'CLICK_SELECT',
        'READING_QUESTION',
        'SORTING_QUESTION',
        'WRITE_QUESTION',
        'MATH_FILLING',
      ];
      const newQuestionType = [
        'AUTO_WRITE',
        'AUTO_SORTING',
        'AUTO_CHOICE',
        'AUTO_FILLING',
        'AUTO_MATCHING',
        'AUTO_INPUT',
        'AUTO_INPUT_FILLING',
        'AUTO_READING',
        'AUTO_ADV_CHOICE',
        'MATH_CHOICE',
        'MATH_COMPLETE',
      ];
      let bol = true;

      if (newQuestionType.includes(questionType)) {
        bol = answerStatus === 'CORRECT';
      }
      if (oldQuestionType.includes(questionType)) {
        bol = errorNumber === 0;
      }
      return bol;
    },
    explainResolution(id) {
      this.questionId = this.questionId === id ? '' : id;
      this.$emit('explainResolution', id);
    },
    getContainer() {
      return document.body;
    },
    initData(uuid, k) {
      let linkData = [];
      this.checkData.forEach((i) => {
        if (i.uuid === this.linkId) {
          linkData = i.exercises;
        }
      });
      linkData.forEach((i) => {
        if (i.uuid === uuid) {
          this.initCompont(i, k);
        }
      });
    },
    initCompont(data, k) {
      const o = {
        title: '做一做',
        type: '复习巩固',
        id: 'Segment1',
        status: false,
        cover: '/demo/segment-cover-1.png',
      };
      let typeObj = {};
      switch (data.type) {
        case 'PICTURE_BOOK':
          typeObj = this.pictureBookData(data);
          break;
        case 'CHOICE':
          typeObj = this.choiceData(data);
          break;
        case 'BLANK_FILLING':
          typeObj = this.blankFillingData(data);
          break;
        case 'CONNECTION':
          typeObj = this.connectionData(data);
          break;
        case 'SUBJECTIVE':
          typeObj = this.subjective(data);
          break;
        case 'EXPLAN_CHOICE':
          typeObj = this.explan(data);
          break;
        case 'GENERAL_SUBJECTIVE':
          typeObj = this.generalSubjective(data);
          break;
        case 'CLICK_SELECT':
          typeObj = this.pointSelect(data);
          break;
        case 'WRITE_QUESTION':
          typeObj = this.writeChar(data);
          break;
        case 'ARTICLE_FILLING':
          typeObj = this.articleFilling(data);
          break;
        case 'SORTING_QUESTION':
          typeObj = this.sort(data);
          break;
        case 'MATH_FILLING':
          typeObj = this.blankFillingMathData(data);
          break;
        case 'MATH_CHOICE':
          typeObj = this.blankChoiceMathData(data);
          break;
        case 'MATH_COMPLETE':
          typeObj = this.blankCompleteMathData(data);
          break;
        default:
          break;
      }
      let errorOptions = null;
      let correctOptions = null;
      let errorNodes = [];
      let correctNodes = [];
      if (data.type === 'CHOICE') {
        errorOptions = Number(k.errorOptions);
        correctOptions = Number(k.correctOptions);
      } else if (data.type === 'EXPLAN_CHOICE') {
        const error = k.userAnswer || k.errorOptions;
        errorOptions = error ? error?.charCodeAt() - 65 : '';
        correctOptions = k.correctOptions ? k.correctOptions?.charCodeAt() - 65 : '';
        if (Object.is(errorOptions, NaN)) {
          this.showError = false;
        }
      } else if (data.type === 'PICTURE_BOOK') {
        const obj = {
          audio: k.readRecording,
          score: k.readScore,
        };
        errorOptions = [obj];
      } else if (data.type === 'WRITE_QUESTION') {
        const obj = {
          position: 'right',
          finished: true,
          url: k.answerPictures[0],
        };
        errorOptions = [obj];
        correctOptions = [obj];
        // } else if (data.type === 'CONNECTION') {
        // } else if (data.type === 'CONNECTION' && this.dataInfo.linkAnswerType === 'QUESTS') {
        // errorOptions = JSON.parse(k.correctOptions);
      } else {
        errorOptions = JSON.parse(k.errorOptions || k.correctOptions);
        correctOptions = JSON.parse(k.correctOptions);
      }
      errorNodes = [{ ...typeObj, review: true, showAnswer: true, value: errorOptions }];
      correctNodes = [{ ...typeObj, review: true, value: correctOptions }];
      this.errorInfoData = [{ ...o, nodes: errorNodes }];
      this.correctInfoData = [{ ...o, nodes: correctNodes }];
      this.showInfo = this.showInfo === data.uuid ? '' : data.uuid;
    },
    // 绘本
    pictureBookData(data) {
      return {
        type: 'pictureBook',
        id: data.uuid,
        title: {
          content: data.title,
          audio: '',
        },
        tag: data.tag,
        payload: [
          {
            id: data.uuid,
            picture: data.contentOption.picture,
            audio: data.contentOption.bookAudio,
            text: data.contentOption.text,
            pinyins: data.contentOption.pinyins,
          },
        ],
      };
    },
    // 选择题
    choiceData(data) {
      return {
        id: data.uuid,
        title: {
          content: data.title,
          audio: '',
        },
        type: 'choice',
        payload: {
          audio: data.contentOption.audio,
          picture: data.contentOption.picture,
          options: data.contentOption.options,
          answer: data.contentOption.answer,
        },
      };
    },
    // 选词填空
    blankFillingData(data) {
      return {
        id: data.uuid,
        title: {
          content: data.title,
          audio: '',
        },
        tag: data.tag,
        type: 'blankFilling',
        payload: {
          begin: data.contentOption.begin,
          end: data.contentOption.end,
          answer: data.contentOption.answer,
        },
      };
    },
    blankFillingMathData(data) {
      // 查看数学填空题
      return {
        id: data.uuid,
        title: {
          content: transformStringToDelta(data.title),
          audio: '',
        },
        type: 'mathFill',
        payload: {
          keyboardId: 'normal',
          answers: data.contentOption.answers,
          difficulty: data.contentOption.difficulty,
          explanAudio: data.contentOption.explanAudio,
          explanVideo: data.contentOption.explanVideo,
          picture: data.contentOption.picture,
          pictures: [data.contentOption.picture, ...data.contentOption.pictures].filter((i) => i),
          explan: data.contentOption.explan,
        },
      };
    },
    blankChoiceMathData(data) {
      // 查看数学选择题
      return {
        id: data.uuid,
        title: {
          content: transformStringToDelta(data.title),
          audio: '',
        },

        type: 'mathChoice',
        payload: {
          question: {
            content: data.contentOption?.title,
            audio: data.contentOption?.audio,
            difficulty: data.contentOption?.difficulty,
            pictures: data.contentOption?.pictures,
          },
          explan: {
            content: data.contentOption?.explan,
            audio: data.contentOption?.explanAudio,
            video: data.contentOption?.explanVideo,
          },
          operation: {
            options: data.contentOption?.options?.map((item) => ({
              ...item,
              content: transformStringToDelta(item.content),
            })),
            answer: data.contentOption?.answer,
          },
        },
      };
    },
    // 连线
    connectionData(data) {
      return {
        id: data.uuid,
        title: {
          content: data.title,
          audio: '',
        },
        type: 'connection',
        payload: {
          begin: data.contentOption.begin,
          end: data.contentOption.end,
          answer: data.contentOption.answer,
        },
      };
    },
    //
    subjective(data) {
      return {
        id: data.uuid,
        title: {
          content: data.title,
          audio: '',
        },
        tag: data.tag,
        type: 'subjective',
        payload: {
          picture: data.contentOption.picture,
          status: 'finished',
          preview: true,
          task: data.contentOption.task,
          steps: data.contentOption.steps,
          homework: {},
        },
      };
    },
    // 带解析的选择题
    explan(data) {
      return {
        type: 'explanChoice',
        id: data.uuid,
        title: {
          content: transformStringToDelta(data.title),
          audio: data.audio,
        },
        tag: data.tag,
        payload: {
          answer: data.contentOption.answer,
          difficulty: data.contentOption.difficulty,
          explanAudio: data.contentOption.explanAudio,
          explanVideo: data.contentOption.explanVideo,
          picture: data.contentOption.picture,
          pictures: [data.contentOption.picture],
          explan: data.contentOption.explan,
          options: data.contentOption.options.map((item) => {
            if (item.type === 'text') {
              return {
                ...item,
                content: transformStringToDelta(item.content),
              };
            }
            return item;
          }),
        },
      };
    },
    // 单选题
    explanSingle(data) {
      return {
        type: 'explanChoice',
        id: data.uuid,
        title: {
          content: transformStringToDelta(data.title),
          audio: '',
        },
        tag: data.tag,
        // payload: data.contentOption,
        payload: {
          answer: data.contentOption.answer,
          difficulty: data.contentOption.difficulty,
          explanAudio: data.contentOption.explanAudio,
          explanVideo: data.contentOption.explanVideo,
          picture: data.contentOption.picture,
          explan: data.contentOption.explan,
          options: data.contentOption.options.map((item) => {
            if (item.type === 'text') {
              return {
                ...item,
                content: transformStringToDelta(item.content),
              };
            }
            return item;
          }),
        },
      };
    },
    // 通用主观题
    generalSubjective(data) {
      return {
        type: 'generalSubjective',
        id: data.uuid,
        title: {
          content: data.title,
          audio: data.audio,
        },
        tag: data.tag,
        payload: {
          status: 'finished',
          question: Object.assign(data.contentOption, { title: data.title }),
          // answer: {},
        },
      };
    },
    // 点选题
    pointSelect(data) {
      return {
        type: 'pointSelect',
        id: data.uuid,
        title: {
          content: data.title,
          audio: '',
        },
        tag: data.tag,
        payload: data.contentOption,
      };
    },
    // 写字练习
    writeChar(data) {
      return {
        type: 'writeChar',
        id: data.uuid,
        title: {
          content: data.title,
          audio: '',
        },
        tag: data.tag,
        payload: Object.assign(data.contentOption, { clearDefaultShow: false }),
      };
    },
    // 文章补全题
    articleFilling(data) {
      return {
        type: 'articleCompletion',
        id: data.uuid,
        title: {
          content: data.title,
          audio: '',
        },
        tag: data.tag,
        payload: data.contentOption,
      };
    },
    // 排序
    sort(data) {
      return {
        type: 'sort',
        id: data.uuid,
        title: {
          content: data.title,
          audio: '',
        },
        payload: data.contentOption,
      };
    },
    onClose() {
      this.showInfo = '';
      this.errorInfoData = null;
      this.correctInfoData = null;
    },
    onShowInfo(uuid, k) {
      this.initData(uuid, k);
    },
    classOutInTime(value) {
      let theTime = Number(value);
      let middle = 0;
      let hour = 0;
      if (theTime > 60) {
        middle = parseInt(theTime / 60, 10);
        theTime = parseInt(theTime % 60, 10);
        if (middle > 60) {
          hour = parseInt(middle / 60, 10);
          middle = parseInt(middle % 60, 10);
        }
      }
      let result = '';
      if (parseInt(theTime, 10) > 0) {
        result = `${parseInt(theTime, 10)}s`;
      }
      if (middle > 0) {
        result = `${parseInt(middle, 10)}m${result}`;
      }
      if (hour > 0) {
        result = `${parseInt(hour, 10)}h${result}`;
      }
      return result;
    },
    blankCompleteMathData(data) {
      // 多步骤数学填空题
      return {
        id: data.uuid,
        title: {
          content: transformStringToDelta(data.title),
          audio: '',
        },

        type: 'mathComplete',
        payload: {
          ...data?.contentOption,
        },
      };
    },
    aiAnalysisHandle(data) {
      if (!data) {
        return '无';
      }
      const datas = JSON.parse(JSON.stringify(data));
      let strArr = [];
      datas.forEach((item) => {
        strArr.push(
          `${item.initialsErrorMessage || item.vowelErrorMessage ? `${item.text}：` : ''}${
            item.initialsErrorMessage ? `声母：${item.initialsErrorMessage},` : ''
          }${item.vowelErrorMessage ? `韵母：${item.vowelErrorMessage};` : ''}`,
        );
      });
      strArr = Array.from(new Set(strArr));
      return strArr.join(' ');
    },
    questionType(type) {
      const TYPE = [
        { type: 'CHOICE', name: '选择题' },
        { type: 'AUTO_ADV_CHOICE', name: '高级选择题' },
        { type: 'CONNECTION', name: '连线题' },
        { type: 'EXPLAN_CHOICE', name: '带解析的选择题' },
        { type: 'BLANK_FILLING', name: '选词填空题' },
        { type: 'ARTICLE_FILLING', name: '文章填空题' },
        { type: 'PICTURE_BOOK', name: '绘本朗读' },
        { type: 'SORTING_QUESTION', name: '排序题' },
        { type: 'WRITE_QUESTION', name: '书写题' },
        { type: 'CLICK_SELECT', name: '点选题' },
        { type: 'SUBJECTIVE', name: '主观题' },
        { type: 'GENERAL_SUBJECTIVE', name: '通用主观题' },
        { type: 'SINGLE_CHOICE', name: '单选题' },
        { type: 'MATH_FILLING', name: '数学填空题' },
        { type: 'MATH_CHOICE', name: '数学选择题' },
        { type: 'MATH_COMPLETE', name: '数学填空题' },
      ];
      return TYPE.filter((item) => type === item.type)[0]?.name;
    },
    onExpand() {
      this.is_expand = !this.is_expand;
      this.questionId = '';
    },
    audioPlay(value) {
      if (this.parentaudio === value) {
        // this.$parent.clearAudio();
        this.$emit('clearAudio');
        // this.isAudio = false;
        return;
      }
      // this.isAudio = true;
      this.$emit('audioPlay', { audio: value, isAudio: this.isAudio });
    },
  },
};
</script>

<style lang="less" scoped>
.reportMath-main {
  padding: 10px 0 0;
  border-radius: 10px;
  border: 1px solid #eee;
  margin-bottom: 20px;
  .showInfo {
    width: 100vw;
    height: 100vh;
    position: fixed;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-content: center;
    left: 0;
    top: 0;
    z-index: 1000;
  }
  .showInfo-main {
    width: 800px;
    margin: auto;
    border-radius: 10px;
    background-color: #fff;
    padding: 0 20px;
    .title {
      height: 60px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 20px;
      p {
        font-size: 16px;
        color: #333;
      }
    }
    .backfill {
      display: flex;
      justify-content: space-around;
      align-items: center;
      border: 2px solid #f2f2f2;
      border-radius: 10px;
      margin-bottom: 20px;
      padding: 20px;
      .backfill-main {
        width: 580px;
      }
    }
    .correct p {
      color: #27ae60;
    }
  }
  .report-header {
    padding: 0 20px;
    .subtract-title {
      display: flex;
      // justify-content: flex-start;
    }
    .answer-time {
      display: flex;
      justify-content: space-between;
      i {
        margin: 0 4px;
      }
      & > div:nth-child(2) {
        margin-left: 20px;
      }
    }
    .subtract {
      width: 50px;
      height: 50px;
      background: #ecf8ff;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 20px;
    }
  }
  .readRecording {
    &:hover {
      cursor: pointer;
    }
    img {
      width: 14px;
      height: 14px;
    }
  }
  p {
    font-size: 18px;

    margin-bottom: 10px;
    span {
      color: #333;
      margin-right: 40px;
    }
  }
  .explain-title {
    padding: 7px 12px;
    font-size: 14px;
    color: #333;
    background-color: #f1faf5;
    display: flex;
    justify-content: space-between;
    align-content: center;
    border-radius: 12px;
    margin-bottom: 20px;
    p {
      font-size: 14px;
      color: #333;
      margin-bottom: 0;
    }
  }
  .explain-main {
    background: #f7f7f7;
    border-radius: 8px;
    padding: 12px;
    color: #828282;
    margin-bottom: 12px;
    p {
      font-size: 14px;
      color: #333;
    }
  }
  .explain-video {
    width: 160px;
    height: 90px;
    margin: 0 10px;
  }
  .report-name {
    color: #333;
    font-size: 18px;
    font-weight: 600;
  }
  .fraction-main {
    display: flex;
    justify-content: space-between;
    margin: 20px 0;
  }
  .answers-overview {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 20px 0;
    .all-answers {
      font-weight: 600;
      font-size: 16px;
      line-height: 16px;
      color: #333;
    }
    .answers {
      font-size: 12px;
      line-height: 36px;
      color: #666666;

      span {
        display: inline-block;
        width: 36px;
        height: 36px;
        border-radius: 50%;
        text-align: center;
        line-height: 36px;
        font-size: 20px;
        font-weight: 600;
        color: #009cff;
        background-color: #ecf8ff;
        margin-right: 10px;
      }
      .error {
        color: #eb5757;
        background-color: rgba(235, 87, 87, 0.1);
      }
      .correct {
        color: #27ae60;
        background-color: rgba(111, 207, 151, 0.1);
      }
      .skip {
        color: rgba(51, 51, 51, 0.6);
        background-color: #f2f2f2;
      }
    }
  }
  .topics-list {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 20px;
    .topics-item {
      min-width: 65px;
      font-size: 14px;
      color: #000;
      // display: flex;
      // flex-direction: column;
      // justify-content: center;
      // align-self: center;
      & > div {
        text-align: center;
        outline: 1px solid #f2f2f2;
        padding: 5px 16px;
      }
      & > .topics-main {
        padding: 5px 8px;
      }
      & > div:nth-child(2) {
        height: 50px;
        p {
          font-size: 14px;
          color: #000;
          text-align: center;
          line-height: 40px;
          margin-bottom: 0;
        }
      }
      & > div:nth-child(3) {
        height: 50px;
      }
    }
    .topics-title {
      width: 65px;
      & > div:nth-child(2) {
        height: 50px;
        p {
          font-size: 14px;
          color: #000;
          text-align: center;
          line-height: 40px;
          margin-bottom: 0;
        }
      }
    }
  }
  .fraction {
    font-size: 32px;
    font-weight: 600;
    color: #333333;
    line-height: 32px;
  }
  .report-expand {
    .expand-btn {
      width: 100%;
      height: 42px;
      padding-right: 10px;
      line-height: 42px;
      text-align: right;
      // border-radius: 10px;
      font-size: 16px;
      color: #009cff;
      background-color: #ecf8ff;
      margin-bottom: 0;
      &:hover {
        cursor: pointer;
      }
    }
  }
  .report-list {
    padding: 10px 20px 0;
    & > div:last-child .report-item {
      border: none;
    }
    .report-item {
      border-bottom: 1px solid #eee;
      margin-bottom: 20px;
    }
    .report-unit {
      display: flex;
      justify-content: space-between;
      align-items: center;

      & > img {
        width: 30px;
        height: 30px;
        margin: 20px 20px 0 0;
        display: inline-block;
      }

      .item-time {
        font-weight: 600;
        color: #009cff;
      }
      .item-error {
        font-weight: 600;
        color: #eb5757;
      }
      .title-error {
        background: #fdeeee;
      }
      .show-info {
        font-size: 14px;
        line-height: 20px;
        padding: 6px 12px;
        color: #009dff;
        border: 1px solid #009dff;
        border-radius: 8px;
        margin-bottom: 20px;
      }
    }

    .item-audio {
      font-size: 16px;
      font-weight: 400;
      color: #333333;
      line-height: 22px;
      height: 28px;
      display: flex;
      align-items: center;
      margin-bottom: 10px;
      audio {
        height: 22px;
        margin: 0 10px;
      }
    }
  }
  .empty {
    font-size: 16px;
    color: #999;
    padding-top: 1%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
}
.answer-error {
  color: #ff5353;
}
.answer-correct {
  color: #00cf64;
}
.item-title {
  font-size: 16px;
  font-weight: 600;
  color: #333333;
  margin-bottom: 10px;
  display: flex;
  justify-content: flex-start;
  width: 100%;

  p {
    font-size: 16px;
    line-height: 22px;
    color: #333;
    max-width: 80%;
    margin-bottom: 0;
  }
  span {
    font-size: 14px;
    font-weight: normal;
    padding: 2px 6px;
    border-radius: 10px;
    border: 1px solid #eee;
    margin-left: 5px;
  }
}
.item-ai {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 16px;
  color: #666;
  margin-bottom: 10px;
  font-weight: bold;
}
.questionDifficulty {
  width: 170px;
  height: 28px;
  text-align: center;
  color: #ffbf00;
  border: 1px solid #ffbf00;
  border-radius: 6px;
  font-size: 14px;
  line-height: 26px;
  margin-left: 10px;
}
.face-icon {
  margin-right: 15px;
}
</style>
