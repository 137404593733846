<template>
  <div>
    <div v-for="(item, index) in reportAllData" :key="index">
      <ClassDoReport :reportItem="item" :linkInformation="dataInfo" :teacherId="teacherId"> </ClassDoReport>
    </div>
  </div>
</template>
<script>
import ClassDoReport from './ClassDoReport';

export default {
  components: { ClassDoReport },
  props: {
    dataInfo: {
      type: Object,
      default: () => {},
    },
    teacherId: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      reportAllData: this.dataInfo?.reportData?.answerRecord || [],
    };
  },
  methods: {},
};
</script>
<style lang="less"></style>
