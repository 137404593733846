<template>
  <div class="poster-studyreport">
    <div class="poster-bgwrap">
      <div class="title">阶段学习报告</div>
      <div class="poster-unit">{{ studyData.progress }}</div>
    </div>
    <div class="eval-wrap" style="margin-top: 0">
      <div class="monkey"></div>
      <div class="student-name">{{ studyData.name }}</div>
      <div class="encourage-wrap" v-if="studyData.score > 3">
        <img src="../assets/icon_goodjob.png" class="face-icon" />
        你表现得很不错哦，继续保持吧！
      </div>
      <div class="encourage-wrap" v-else>
        <img src="../assets/icon_goodjob.png" class="face-icon" />
        接下来要继续努力哦，加油！
      </div>
      <div class="evaluate-wrap">
        <div class="evaluate-txt">
          <div class="evaluate-lineleft"></div>
          <div style="color: #999999; font-size: 14px">学习情况评价</div>
          <div class="evaluate-lineright"></div>
        </div>
        <div class="star-wrap">
          <div class="poster-star" v-for="i in studyData.score" :key="i + 'star'"></div>
          <div class="poster-noStar" v-for="i in 5 - studyData.score" :key="i + 'noStar'"></div>
        </div>
      </div>
    </div>
    <div class="eval-wrap">
      <div class="eval-title">
        <img src="../assets/report-h1.png" class="report-h1" />
      </div>
      <div class="pic-wrap">
        <img src="../assets/pic_head_teacher.png" class="pic_head_teacher" />
        <span class="teacher">{{ studyData.teacherName }}</span>
      </div>
      <div class="teacher-evaluate">
        <div class="teacher-txt remake" style="letter-spacing: 0.1px" v-html="studyData.remake">
          <!-- {{ studyData.remake }} -->
        </div>
      </div>
    </div>
    <div class="eval-wrap" v-if="studyData.classSection">
      <div class="eval-title">
        <img src="../assets/report-h2.png" class="report-h2" />
      </div>
      <div class="stage-wrap" v-html="studyData.classSection">
        <!-- <h1>学习目标</h1>
        <h2>笔画</h2>
        <p>
          1、掌握“日、月、水、火”等43个汉字；<br />
          2、掌握“日出、月亮、雨水、石头”等92个词语；<br />
          3、掌握“水滴石穿、目瞪口呆、手舞足蹈、手足情深”4个成语；
        </p> -->
      </div>
    </div>
    <div class="logo-wrap">
      <img src="../assets/startpage_logo.png" class="logo-pic" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'StudyReport',
  props: {
    studyData: {
      type: Object,
      require: true,
    },
  },
  data() {
    return {};
  },
  mounted() {
    console.log(this.studyData);
  },
};
</script>

<style lang="less">
.remake {
  h1 {
    font-size: 2em;
    margin-bottom: 10px;
  }
  h2 {
    font-size: 1.5em;
    margin-bottom: 10px;
  }
}
p,
h1,
h2 {
  margin: 0;
}
.poster-studyreport {
  position: relative;
  width: 375px;
  margin: 0 auto;
  border-radius: 8px;
  padding-top: 192px;
  // font-family: 'PingFang SC';
  font-family: Helvetica, Arial, sans-serif;

  background: #f5f5f5;
  overflow: hidden;
  .title {
    color: #ffffff;
    font-size: 28px;
    font-weight: bold;
    position: absolute;
    top: 30px;
    left: 26px;
  }
  .poster-bgwrap {
    position: absolute;
    width: 375px;
    height: 400px;
    background: url('../assets/pic_top_bg.png') no-repeat;
    background-size: 100%;
    position: absolute;
    right: 0px;
    top: 0px;
  }
  .poster-unit {
    color: #fff;
    font-size: 16px;
    position: absolute;
    left: 26px;
    top: 85px;
    padding: 5px 12px;
    border-radius: 22px;
    border: 1px solid #fff;
  }
  .eval-wrap {
    margin: 12px 15px 0;
    background: #ffffff;
    border-radius: 30px;
    position: relative;
    padding-bottom: 30px;
  }
  .monkey {
    width: 80px;
    height: 80px;
    background: url('../assets/pic_head_student.png') no-repeat;
    background-size: 100%;
    position: absolute;
    left: 50%;
    top: -40px;
    transform: translate(-50%, 0);
  }
  .face-icon {
    width: 24px;
    height: 24px;
    margin-right: 5px;
  }
  .student-name {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    color: #333333;
    text-align: center;
    padding-top: 48px;
  }
  .encourage-wrap {
    color: #999;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
  }
  .evaluate-wrap {
    background: #f9f9f9;
    border-radius: 20px;
    margin: 22px 16px 0px;
    padding: 16px 25px 20px;
  }

  .star-wrap {
    display: flex;
    margin-top: 18px;
    justify-content: space-between;
  }
  .poster-star {
    width: 35px;
    height: 36px;
    background: url('../assets/pop_icon_star.png') no-repeat;
    background-size: 100%;
  }
  .poster-noStar {
    width: 35px;
    height: 36px;
    background: url('../assets/homework_star.png') no-repeat;
    background-size: 100%;
  }
  .eval-title {
    padding: 22px 0 0;
    text-align: center;
  }
  .report-h1 {
    width: 120px;
    height: 30px;
  }
  .report-h2 {
    width: 160px;
    height: 30px;
  }
  .pic_head_teacher {
    width: 36px;
    height: 36px;
    margin-right: 10px;
  }
  .teacher {
    color: #333333;
    font-size: 16px;
  }
  .teacher-evaluate {
    background: #f9f9f9;
    border-radius: 6px 20px 20px 20px;
    margin: 12px 16px 0px;
    padding: 12px 12px;
  }
  .teacher-txt {
    font-size: 15px;
    line-height: 25px;
    text-align: justify;
    color: #666666;
    margin-bottom: 0;
  }
  .evaluate-lineleft {
    width: 30px;
    height: 2px;
    background: linear-gradient(90deg, rgba(229, 229, 229, 0.0001) 0.69%, #e5e5e5 100%);
    border-radius: 1px;
    margin-right: 8px;
  }
  .evaluate-lineright {
    width: 30px;
    height: 2px;
    border-radius: 1px;
    margin-left: 8px;
    background: linear-gradient(90deg, #e5e5e5 0.69%, rgba(229, 229, 229, 0.0001) 100%);
    border-radius: 1px;
  }
  .pic-wrap {
    padding: 16px 16px 0;
  }
  .evaluate-txt {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .stage-wrap {
    font-family: Helvetica, Arial, sans-serif;
    margin: 0 16px 0;
    h1 {
      font-weight: 500;
      font-size: 18px;
      color: #333333;
      margin: 20px 0 6px 0;
    }
    h1::before {
      content: '';
      display: block;
      float: left;
      margin: 4px 10px 0 0;
      width: 20px;
      height: 20px;
      background: url('../assets/triangle-icon.png') no-repeat;
      background-size: 100%;
    }
    h2 {
      font-size: 16px;
      color: #ff5353;
      padding: 6px 12px;
      background: rgba(255, 83, 83, 0.1);
      border-radius: 30px;
      margin: 12px 0 10px 0;
      display: inline-block;
      font-weight: normal;
    }
    p {
      font-size: 15px;
      line-height: 25px;
      text-align: justify;
      color: #666666;
    }
  }
  .logo-wrap {
    text-align: center;
    padding: 15px 0;
  }
  .logo-pic {
    width: 100px;
    height: 35px;
  }
}
</style>
