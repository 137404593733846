var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.dataInfos)?_vm._l((_vm.dataInfos),function(item){return _c('div',{key:item.uuid},[(item.noSubmit)?_c('div',{staticStyle:{"margin-bottom":"20px"}},[_c('div',{staticClass:"report-title"},[_c('InfoTitle',{attrs:{"title":item.name}})],1),_c('div',[_vm._v(" 学员暂"),_c('span',{staticStyle:{"color":"red"}},[_vm._v("未提交")]),_vm._v("该环节作业"),_c('span',{staticStyle:{"color":"#009cff","padding-left":"15px","cursor":"pointer"},on:{"click":function($event){return _vm.lookNoSubmit(item)}}},[_vm._v("查看作业详情")])])]):_c('div',[(
            (item.exerciseType !== 'SUBJECTIVE_EXERCISE' || item.questionTopicType !== 'SUBJECTIVE_EXERCISE') &&
            (item.exerciseType !== 'COMPLEX_SUBJECTIVE' || item.questionTopicType !== 'COMPLEX_SUBJECTIVE')
          )?_c('div',{staticClass:"read-report"},[_c('div',{staticClass:"report-title"},[_c('InfoTitle',{attrs:{"title":item.linkName}}),_c('p',{domProps:{"textContent":_vm._s(item.linkAnswerType === 'QUESTS' ? '闯关模式' : '测试模式')}})],1),(
              (item.exerciseType !== 'SUBJECTIVE_EXERCISE' || item.questionTopicType !== 'SUBJECTIVE_EXERCISE') &&
              (item.exerciseType !== 'COMPLEX_SUBJECTIVE' || item.questionTopicType !== 'COMPLEX_SUBJECTIVE')
            )?_c('p',{on:{"click":function($event){return _vm.onReportHistory(item.linkId)}}},[_vm._v(" "+_vm._s(_vm.subject === 'MATH' ? 'View historical reports >' : '查看历史报告 >')+" ")]):_vm._e()]):_vm._e(),(
            item.reportData &&
            (item.exerciseType !== 'SUBJECTIVE_EXERCISE' || item.questionTopicType !== 'SUBJECTIVE_EXERCISE') &&
            (item.exerciseType !== 'COMPLEX_SUBJECTIVE' || item.questionTopicType !== 'COMPLEX_SUBJECTIVE')
          )?_c('div',[(_vm.subject === 'MATH')?_c('MathReport',{attrs:{"dataInfo":item || {},"explainData":_vm.explainData,"checkData":_vm.checkData,"parentaudio":_vm.parentaudio},on:{"audioPlay":_vm.audioPlay,"clearAudio":_vm.clearAudio,"explainResolution":_vm.explainResolution}}):(item.exerciseType === 'READER_EXERCISE')?_c('PictureReaderReport',{attrs:{"dataInfo":item || {},"explainData":_vm.explainData,"checkData":_vm.checkData,"parentaudio":_vm.parentaudio},on:{"audioPlay":_vm.audioPlay,"clearAudio":_vm.clearAudio,"explainResolution":_vm.explainResolution}}):_c('ReadReport',{attrs:{"dataInfo":item || {},"explainData":_vm.explainData,"checkData":_vm.checkData,"parentaudio":_vm.parentaudio},on:{"audioPlay":_vm.audioPlay,"clearAudio":_vm.clearAudio,"explainResolution":_vm.explainResolution}})],1):_c('div',[_c('DoItReportClassAdmin',{attrs:{"dataInfo":item || {},"teacherId":_vm.teacherUuid}})],1)])])}):_vm._e(),(_vm.currentView === 'ReportHistoryOperation')?_c('ReportHistoryOperation',{attrs:{"originType":_vm.originType,"reportHistory":_vm.reportHistory,"historyType":_vm.historyType,"studentReview":_vm.studentReview,"studentId":_vm.studentId,"explainData":_vm.explainData,"checkData":_vm.checkData,"subject":_vm.subject},on:{"explainResolution":_vm.explainResolution}},[[_vm._t("default")]],2):_vm._e(),_c('a-drawer',{attrs:{"title":"查看详情","placement":"right","width":"700","closable":true,"visible":_vm.checkVisible},on:{"close":_vm.cancelDetail}},[_c('CheckHomeWork',{attrs:{"subject":_vm.subject,"checkData":_vm.selCheckData,"originType":"submit"}})],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }