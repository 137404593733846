<template>
  <div>
    <a-modal title="邮件详情" :visible="visible" width="730px" :footer=null dialogClass="schedule-related" @cancel="onCancel(0)">
    
      <div class="email-item">
      <div class="email-label">发件人：</div>
      <div><span class="name-label">{{emailDetail.emailFrom}}</span></div>
    </div>
      <div class="email-item">
        <div class="email-label">收件人：</div>
        <div>
          <span class="name-label">{{emailDetail.emailTo}}</span>
        </div>
      </div>
     <div class="email-item">
        <div class="email-label">邮件主题：</div>
        <div>
          <span>{{emailDetail.subject}}</span>
        </div>
      </div>
      <div class="email-item no-border">
        <div class="email-label">邮件详情：</div>
        <div class="email-content" v-html="emailDetail.contentHtml">
        
        </div>
      </div>
    
    </a-modal>
  
  </div>
</template>
<script>
import storage from 'store';
import { queryEmailDetail } from '@/api/headTeacher';
export default {
  name: 'emailEdit',
  components: {
   
  },
  props: {
    recordData: {
      type: Object,
      default: () => {},
    },
     roleType:{
      type:String,
      default:''
    }
  },
  data() {
    return {
      visible:true,
    
      userInfo: {},
      emailDetail:{}
    
    };
  },

 created(){
 //入学档案的邮件
  if(['STUDENT_ADMISSION_PROFILE','STUDENT_SUBSCRIBE_PROFILE'].includes(this.recordData?.recordData?.taskType)){
    this.emailDetail=this.recordData.recordData
  }else{
   this.queryEmailDetail()
    this.userInfo = storage.get('userInfo');
  }
 },
  mounted() {
    // this.queryEmailDetail()
    // this.userInfo = storage.get('userInfo');

  },

  methods: {
    queryEmailDetail(){
      let params={
        logId:this.recordData.recordData.logId,
        type:this.roleType 
      }
      queryEmailDetail(params).then(res=>{
        this.emailDetail=res.data.content
      })
    },
   
  
    onCancel() {
      this.$emit('onCancel');
    },
  },
  beforeDestroy() {},
};
</script>
<style lang="less" scoped>
/deep/ .el-input__inner {
  border: none !important;
  height: 20px;
}
/deep/ .el-input__suffix {
  top: -7px;
}
.schedule-related {
}
.email-item {
  display: flex;
  padding: 10px 0;
  border-bottom: 1px solid #ccc;
}
.no-border {
  border: none;
}
.name-label {
  padding: 2px 10px;
  background-color: rgba(240, 240, 240, 1);
  border-radius: 10px;
}
.email-label {
  width:80px;
}
.email-content {
  flex: 1;
  max-height: 500px;
  overflow-y: auto;
}
.footer-email {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.send-emailName {
  text-align: left;
  flex: 1;
}
.footer-emailBtn {
  width: 340px;
}
.insertTemp {
  padding: 5px 10px;
  border: 1px solid #eee;
  border-radius: 10px;
  color: #43d186;
  font-size: 13px;
  cursor: pointer;
}
.insert-btn {
  position: absolute;
  right: 45px;
  top: 171px;
  z-index: 100;
}
</style>
