<template>
  <div :class="subDetailType">
    <div class="task-form">
      <p class="form-name">
        <a-tooltip :overlayStyle="{ maxWidth: '500px' }">
          <template slot="title">
            <div class="task-explainTitleTC">
              <span class="task-titleTxt">任务说明</span
              ><span class="task-explain" @click="lookWord">查看文档说明</span>
            </div>
            <div>
              【完成方式】电话/文字沟通<br />
              【完成时效】3天<br />
              【操作步骤】<br />
              1、沟通前： <br />
              a.初步审核阶段学习报告是否合格，若不合格则提交【学员一切反馈】审批进行反馈<br />
              b.查看近期课程回放，对当前阶段的学习做简单的总结，可从课频、孩子学习习惯、课堂表现等方面<br />
              c.查看学员互动作业，从作业提交频率，作业完成情况、优秀点和待提升点进行简单的总结<br />
              2、沟通中： <br />
              a.学情沟通：与家长沟通本阶段学生课堂表现（参与度、出勤、作业完成情况）、待提升点及建议<br />
              b.学习规划：依据课程大纲内容，铺垫并介绍下阶段学习内容与重难点，做好后续的学习规划调整，需要家长配合方向等<br />
              c.对家长存在疑问做对应异议处理和解答<br />
              3、沟通后： <br />
              a.提醒家长查看阶段学习报告（家长通过APP或者IM查看或班主任下发）<br />
              b. 优先做风险管理，有问题及时跟进反馈<br />
              c.及时填写跟进记录，记录沟通的重要信息<br />

              【注意事项】<br />
              ①跟进记录中的每一项均需沟通完毕才可完成任务，不得在未沟通的情况下点击完成任务<br />
            </div>
          </template>
          <i class="explainTC"></i>
        </a-tooltip>
        {{ formInfoData.question }}
        <span class="pending-status" v-if="!formInfoData.finished">待处理</span>
        <span class="success-status" v-if="formInfoData.finished && !formInfoData.deleted">已完成</span>
        <span class="refuse-status" v-if="formInfoData.finished && formInfoData.deleted">已拒绝</span>
      </p>
      <div class="form-teacher">
        <img :src="defaultImg" alt="" />
        <span>{{ formInfoData.startUserName }}于</span>
        <span>{{ $moment(formInfoData.startTime).utcOffset(8).format('YYYY年MM月DD日 HH:mm') }}</span>
        | 最迟解决时间
        <span :class="{ dueDate: new Date().getTime() > new Date(formInfoData.dueDate).getTime() }">
          {{ $moment(formInfoData.dueDate).format('YYYY年MM月DD日 HH:mm') }}
          {{ new Date().getTime() > new Date(formInfoData.dueDate).getTime() ? '(逾期)' : '' }}
        </span>
      </div>
      <div class="study-desc">请在学生本阶段最后一节课完课后，及时发送该报告给学生，并做进一步沟通</div>
      <div class="study-wrap">
        <div class="study-top">
          <div>{{ formInfoData.studentArray[0].name }}的阶段学习报告</div>
          <div class="study-btnWrap">
            <span class="download-poster" @click="downLoadImg">生成报告</span>
            <span class="send-group" @click="sendGroup">发送至群</span>
          </div>
        </div>
        <div><a-rate v-model="formInfoData.score" class="start" disabled /></div>
        <div class="study-txt">
          <span>{{ formInfoData.progress.substring(0, formInfoData.progress.indexOf('-')) }} | </span>
          <span>{{ formInfoData.relatedClass[0].className }}</span>
        </div>
        <div class="study-txt">
          <span>{{ formInfoData.teacherName }} 授课老师 （教学部）</span>
          <span style="color: #666">填写于 {{ formInfoData.startTime }}</span>
        </div>
      </div>
    </div>
    <div class="courseInfo" v-if="formInfoData.courseInfo">
      <div class="title">
        <span>关联课节</span>
      </div>
      <div class="course-info">
        <router-link
          v-if="formInfoData.showcourseSection"
          class="course-name"
          :to="{ path: '/courseDetails', query: { uuid: formInfoData.courseInfo.uuid } }"
        >
          {{ formInfoData.courseInfo.courseName }}
          {{ formInfoData.courseInfo.scheduleName }}
        </router-link>
        <span v-if="!formInfoData.showcourseSection" class="course-name"
          >{{ formInfoData.courseInfo.courseName }} {{ formInfoData.courseInfo.scheduleName }}</span
        >
        <div class="course-type">
          <span>开课时间：{{ formInfoData.courseInfo.startTime }}</span>
        </div>
        <div class="course-type">
          <span>{{ formInfoData.courseInfo.courseSectionName }}</span> |
          <span>{{ formInfoData.courseInfo.classType === 'PRIVATE' ? '私教课' : '班课' }}</span> |
          <span>学生：{{ formInfoData.courseInfo.studentName || '无' }}</span> |
          <span>班级：{{ formInfoData.courseInfo.formClassName }}</span>
        </div>
      </div>
    </div>
    <RelatedStudents :formInfoData="formInfoData" @onGetStudentId="onGetStudentId" />
  </div>
</template>

<script>
import defaultImg from '../assets/service_pic_head.png';
import RelatedStudents from './RelatedStudents.vue';

export default {
  props: {
    formInfoData: {
      type: Object,
    },
    isCheak: {
      type: Boolean,
    },
    subDetailType: {
      // 区分是老师还是班主任，班主任主题色是绿色
      type: String,
      default: 'teacher',
    },
  },
  components: { RelatedStudents },
  data() {
    return {
      cursor: 'cursor',
      defaultImg,
      studyTaskForm: this.$form.createForm(this),
      isRequired: false,
    };
  },
  methods: {
    lookWord() {
      window.open('https://wukongedu.feishu.cn/wiki/H9w5wOhY6iQq9CkF7yyc2w29nwb', '_blank');
    },
    sendGroup() {
      const reportData = {
        score: this.formInfoData.score,
        name: this.formInfoData.studentArray ? this.formInfoData.studentArray[0].name : '',
        progress: `${this.formInfoData.progress.split('-')[0]}-${this.formInfoData.progress.split('-')[1]}`,
        teacherName: this.formInfoData.teacherName,
        createTime: this.formInfoData.createTime.substring(0, 10),
        listeningLevel: this.formInfoData.listeningLevel,
        readingLevel: this.formInfoData.readingLevel,
        speakingLevel: this.formInfoData.speakingLevel,
        writingLevel: this.formInfoData.writingLevel,
        remake: this.formInfoData.remake,
        classSection: this.formInfoData.classSection,
      };
      this.$emit('sendStudyReport', reportData);
    },
    downloadByBlob(url, name) {
      const image = new Image();
      image.setAttribute('crossOrigin', 'anonymous');
      image.src = url;
      image.onload = () => {
        const canvas = document.createElement('canvas');
        canvas.width = image.width;
        canvas.height = image.height;
        const ctx = canvas.getContext('2d');
        ctx.drawImage(image, 0, 0, image.width, image.height);
        canvas.toBlob(async (blob) => {
          const data = [
            new ClipboardItem({
              [blob.type]: blob,
            }),
          ];
          await navigator.clipboard.write(data);
          this.$message.success('复制成功');
        });
        // canvas.toBlob((blob) => {
        //   const imgUrl = URL.createObjectURL(blob);
        //   this.downloadImage(imgUrl, name);
        //   URL.revokeObjectURL(imgUrl);
        //   setTimeout(() => {
        //     this.$message.success('下载成功');
        //   }, 1000);
        // });
      };
    },
    downLoadImg() {
      const reportData = {
        score: this.formInfoData.score,
        name: this.formInfoData.studentArray ? this.formInfoData.studentArray[0].name : '',
        progress: `${this.formInfoData.progress.split('-')[0]}-${this.formInfoData.progress.split('-')[1]}`,
        teacherName: this.formInfoData.teacherName,
        createTime: this.formInfoData.createTime.substring(0, 10),
        listeningLevel: this.formInfoData.listeningLevel,
        readingLevel: this.formInfoData.readingLevel,
        speakingLevel: this.formInfoData.speakingLevel,
        writingLevel: this.formInfoData.writingLevel,
        remake: this.formInfoData.remake,
        classSection: this.formInfoData.classSection,
      };
      this.$emit('onClassReport', reportData);
      // const fileName = `${this.formInfoData.studentArray[0].name}的阶段学习报告${this.formInfoData.progress.substring(
      //   0,
      //   this.formInfoData.progress.indexOf('-'),
      // )}-${this.$moment(new Date()).format('YYYYMMDDHHmmss')}`;
      // this.downloadByBlob(this.formInfoData.imgData, fileName);
    },
    downloadImage(url, name) {
      const link = document.createElement('a');
      link.href = url;
      link.download = name;
      link.click();
    },
    onStudentId(data) {
      this.$emit('onGetStudentId', { name: data.name, id: data.id });
    },
    onGetStudentId(data) {
      this.$emit('onGetStudentId', data);
    },
    // 检查数据
    oncheckRequired() {
      this.$nextTick(() => {
        // const getFieldsValue = this.studyTaskForm.getFieldsValue();
        // this.isRequired = Object.entries(getFieldsValue).every((item) => item[1] !== undefined && item[1]);
        // if (this.isRequired) {
        this.$emit('onStudyReport', this.studyTaskForm);
        // }
      });
    },
  },
  mounted() {},
};
</script>

<style lang="less" scoped>
.cursor {
  cursor: pointer;
  color: #009cff !important;
}
.classAdmin .cursor {
  cursor: pointer;
  color: #43d186 !important;
}
.title {
  font-size: 20px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #333333;
  line-height: 28px;
  margin-bottom: 20px;
}
.title::before {
  content: '';
  display: block;
  float: left;
  margin: 4px 12px 0 0;
  width: 4px;
  height: 20px;
  background-color: #009cff;
  border-radius: 0px 6px 6px 0px;
}
.task-form {
  border-bottom: 1px solid #eee;
}

.form-name span {
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  padding: 2px 10px;
  border-radius: 6px;
  margin: 0 10px;
}
.pending-status {
  color: #ffbf00;
  background-color: rgba(255, 191, 0, 0.09);
}
.success-status {
  color: #00cf64;
  background-color: rgba(0, 207, 100, 0.09);
}
.refuse-status {
  color: #ff5353;
  background-color: rgba(255, 83, 83, 0.09);
}
.form-teacher {
  font-size: 16px;
  font-weight: 400;
  color: #999;
  line-height: 22px;
  margin-bottom: 15px;
}
.form-teacher .dueDate {
  color: #ff5454;
}
.form-teacher span {
  color: #333;
}
.form-teacher img {
  width: 18px;
  height: 18px;
  border-radius: 50%;
  margin-right: 10px;
}
.form-des {
  font-size: 16px;
  font-weight: 400;
  color: #333333;
  line-height: 22px;
  margin-bottom: 15px;
}
.form-imgs {
  margin-bottom: 20px;
}
.form-imgs img {
  width: 80px;
  height: 80px;
  border-radius: 4px;
  margin-right: 10px;
}
.courseInfo {
  padding: 20px 0;
}
.course-info {
  padding: 20px 16px;
  background: linear-gradient(180deg, #ecf7ff 0%, #f7fcff 100%);
  border-radius: 20px;
  margin-bottom: 20px;
}
.course-name {
  font-size: 16px;
  font-weight: 600;
  color: #009cff;
  line-height: 22px;
  margin-bottom: 10px;
}
.course-info a:hover {
  text-decoration: underline;
}
.course-type {
  font-size: 14px;
  font-weight: 400;
  color: #999999;
  line-height: 18px;
  margin-top: 10px;
}
.carousel-main {
  width: 550px;
  height: 340px;
  background: #fff;
  border-radius: 20px;
}
.carousel-main .imgcls {
  width: 550px;
  height: 340px;
}
.carousel-main .imgcls img {
  width: 100%;
  height: 100%;
  border-radius: 20px;
}
.carousel-main .ant-carousel .slick-slide {
  text-align: center;
  height: 340px;
}
.carousel-main .ant-carousel .custom-slick-arrow {
  width: 25px;
  height: 25px;
  font-size: 25px;
  color: #000;
  background-color: rgba(31, 45, 61, 0.11);
  opacity: 0.3;
}
.carousel-main .ant-carousel .custom-slick-arrow:before {
  display: none;
}
.carousel-main .ant-carousel .custom-slick-arrow:hover {
  opacity: 0.5;
}
.student-item {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.student-item p {
  font-size: 14px;
  font-weight: 400;
  color: #999999;
  line-height: 20px;
}
.student-item img {
  width: 42px;
  height: 42px;
  border-radius: 50%;
  margin-right: 7px;
}
.student-item .student-name {
  font-size: 16px;
  font-weight: 600;
  color: #333333;
  line-height: 22px;
  width: 300px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.student-wrap {
  display: flex;
  margin-bottom: 22px;
}
.form-desc1 {
  margin-bottom: 10px;
  color: #333;
  font-size: 16px;
  p {
    margin-left: 8px;
  }
}
.reportPhase {
  display: flex;
}
.red-icon {
  color: #ff5353;
}
.form-label {
  width: 130px;
  padding-right: 5px;

  text-align: right;
}
.form-reason {
  flex: 1;
}
.title {
  font-size: 20px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #333333;
  line-height: 28px;
  margin-bottom: 20px;
}
.title::before {
  content: '';
  display: block;
  float: left;
  margin: 4px 12px 0 0;
  width: 4px;
  height: 20px;
  background-color: #009cff;
  border-radius: 0px 6px 6px 0px;
}
.concat-wrap {
  padding-top: 20px;
  margin-bottom: 20px;
}
.concat-con {
  border: 1px solid #eeeeee;
  width: 100%;
  max-height: 200px;
  overflow-y: auto;
  padding: 10px;
  font-size: 16px;
  color: #333;
  margin-bottom: 20px;
}
.concat-item {
  margin-bottom: 5px;
}
.concat-label {
  color: #666;
  font-size: 13px;
  border-left: 1px solid #dddddd;
  padding: 0 10px;
  display: inline-block;
  &:first-child {
    padding: 0 10px 0 0;
    border-left: 0;
  }
}
.bot-border {
  border-bottom: 1px solid #eeeeee;
  padding-bottom: 10px;
}

.icon-starlight {
  width: 22px;
  height: 22px;
  background: url('../assets/icon_star_ed.png') no-repeat 0 0;
  background-size: 100%;
  display: inline-block;
  margin-right: 5px;
}
.icon-star {
  width: 22px;
  height: 22px;
  background: url('../assets/icon_star.png') no-repeat 0 0;
  background-size: 100%;
  display: inline-block;
  margin-right: 5px;
}
/deep/ .ant-form label {
  font-size: 16px !important;
}
.study-wrap {
  border: 1px solid #eeeeee;
  width: 100%;
  max-height: 200px;
  overflow-y: auto;
  padding: 10px;
  font-size: 16px;
  color: #333;
  margin: 15px 0;
  border-radius: 5px;
}
.study-txt {
  font-size: 14px;
  margin-top: 10px;
}
.study-top {
  display: flex;
  justify-content: space-between;
}
.study-btnWrap {
  font-size: 14px;
  color: #009cff;
}
.download-poster {
  margin-right: 15px;
  cursor: pointer;
}
.send-group {
  cursor: pointer;
}
.classAdmin {
  .title::before {
    background-color: #43d186;
  }
  .course-name {
    color: #43d186;
  }
}
.explainTC {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: url('../assets/explain.png') no-repeat center;
  background-size: 100%;
  cursor: pointer;
  margin-right: 5px;
}
.task-explainTitleTC {
  padding-bottom: 5px;
  display: inline-flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}
.task-titleTxt {
  font-size: 18px;
  font-weight: 600;
}
.task-explain {
  color: #2eaaf9;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
}
.form-name {
  font-size: 20px;
  font-weight: 600;
  color: #333333;
  line-height: 28px;
  margin-bottom: 15px;
  display: flex;
  align-items: center;
}
</style>
