<template>
  <div :class="subDetailType">
    <div class="task-form">
      <p class="form-name">
        {{ formInfoData.question }}
        <span class="pending-status" v-if="!formInfoData.finished">待处理</span>
        <span class="success-status" v-if="formInfoData.finished && !formInfoData.deleted">已完成</span>
        <span class="refuse-status" v-if="formInfoData.finished && formInfoData.deleted">已拒绝</span>
      </p>
      <div class="form-teacher">
        <img :src="defaultImg" alt="" />
        <span
          >{{ formInfoData.teacherName }}（ <span>{{ formInfoData.userRole || '老师' }}</span> ）</span
        >
        提交于
        <span>{{ $moment(formInfoData.startTime).utcOffset(8).format('YYYY年MM月DD日 HH:mm') }}</span>
        | 最迟解决时间
        <span
          v-if="formInfoData.endTime"
          :class="{ dueDate: new Date(formInfoData.endTime).getTime() > new Date(formInfoData.dueDate).getTime() }"
          >{{ $moment(formInfoData.dueDate).format('YYYY年MM月DD日 HH:mm')
          }}{{
            new Date(formInfoData.endTime).getTime() > new Date(formInfoData.dueDate).getTime() ? '(逾期)' : ''
          }}</span
        >
        <span v-else :class="{ dueDate: new Date().getTime() > new Date(formInfoData.dueDate).getTime() }"
          >{{ $moment(formInfoData.dueDate).format('YYYY年MM月DD日 HH:mm')
          }}{{ new Date().getTime() > new Date(formInfoData.dueDate).getTime() ? '(逾期)' : '' }}</span
        >
      </div>
      <div>
        <div class="form-desc form-desc1">
          <span class="red-icon">*</span>
          <span>请假原因：</span>
          <p>{{ formInfoData.description }}</p>
        </div>
        <div class="form-desc form-desc1">
          <span class="red-icon">*</span>
          <span>请假类型：</span>
          <p>{{ formInfoData.reasonType }}</p>
        </div>
        <div class="form-desc form-desc1">
          <span class="red-icon">*</span>
          <span>请假具体原因：</span>
          <p>{{ formInfoData.reasonText }}</p>
        </div>
        <div class="form-desc form-desc1">
          <span class="red-icon">*</span>
          <span>违规类型：</span>
          <p>{{ illegalTypeOption[formInfoData.illegalType] }}</p>
        </div>
        <div class="form-desc form-desc1">
          <span class="red-icon">*</span>
          <span>请假时间（起）：</span>
          <p>{{ formInfoData.leaveStartTime }}</p>
        </div>
        <div class="form-desc form-desc1">
          <span class="red-icon">*</span>
          <span>请假时间（止）：</span>
          <p>{{ formInfoData.leaveEndTime }}</p>
        </div>
        <div class="form-desc form-desc1">
          <span class="red-icon">*</span>
          <span>最近开课时间：</span>
          <p>{{ $moment(formInfoData.chinaBeginDateTime).format('YYYY-MM-DD HH:mm:ss') }}</p>
        </div>
        <div class="form-desc form-desc1">
          <span class="red-icon">*</span>
          <span>课节总数：</span>
          <p>
            {{ formInfoData.scheduleArray.length }}
            <a-popover title="" trigger="click" overlayClassName="scheduleMain">
              <template slot="content">
                <div>
                  <div class="scheduleTitle scheduleHeader">
                    <div>班级名称</div>
                    <div>课节名称</div>
                    <div>开课时间</div>
                  </div>
                  <div class="scheduleData" v-if="formInfoData.scheduleArray && formInfoData.scheduleArray.length">
                    <div class="scheduleTitle" v-for="i in formInfoData.scheduleArray" :key="i.uuid">
                      <div>{{ i.formClassName }}</div>
                      <div>{{ i.scheduleName }}</div>
                      <div>
                        {{ i.startChinaDateTime && $moment(i.startChinaDateTime).format('YYYY-MM-DD HH:mm:ss') }}
                      </div>
                    </div>
                  </div>
                  <div v-else class="scheduleDataNo">暂无数据</div>
                </div>
              </template>
              <a-icon class="scheduleIcon" type="file-text" />
            </a-popover>
          </p>
        </div>
        <div class="form-desc form-desc1">
          <span class="red-icon">*</span>
          <span>课节总时长：</span>
          <p>{{ formInfoData.duration }}</p>
        </div>

        <div class="form-desc form-desc1">
          <span class="red-icon">*</span>
          <span>申诉老师：</span>
          <p>{{ formInfoData.teacherName }}</p>
        </div>
        <div class="form-desc form-desc1">
          <span class="red-icon">*</span>
          <span>申诉理由：</span>
          <p v-html="formInfoData.reason"></p>
        </div>
      </div>
    </div>
    <div class="concat-wrap" v-if="formInfoData.relatedTask">
      <div class="title">关联任务</div>
      <div class="concat-con">
        <p>任务名称：{{ formInfoData.relatedTask.name }}</p>
      </div>
    </div>
    <div class="concat-wrap" v-if="formInfoData.relatedClass">
      <div class="title">关联班级</div>
      <RelatedClass
        class="concat-con"
        v-for="(classItem, index) in formInfoData.relatedClass"
        :classItem="classItem"
        :key="index"
        @onGetStudentId="onGetStudentId"
      >
      </RelatedClass>
    </div>
  </div>
</template>

<script>
import defaultImg from '../assets/service_pic_head.png';
import RelatedClass from './RelatedClass.vue';

export default {
  components: { RelatedClass },
  props: {
    formInfoData: {
      type: Object,
    },
    subDetailType: {
      // 区分是老师还是班主任，班主任主题色是绿色
      type: String,
      default: 'teacher',
    },
    detailType: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      cursor: 'cursor',
      defaultImg,
      isCarousel: false,
      pictures: [],
      visibleClass: true,
      toggleTxt: '收起',
      form: this.$form.createForm(this),
      isRequired: false,
      selectArr: [
        { name: '课堂标准化', value: 'CLASS_ROOM' },
        { name: '课后标准化', value: 'AFTER_CLASS' },
        { name: '制度规范化', value: 'INSTITUTION' },
        { name: '上错课', value: 'ERROR_CLASS' },
      ],
      illegalTypeOption: {
        LEAVE_ILLEGAL_HOUR: '小于48小时',
        LEAVE_ILLEGAL_DAY: '小于4天',
        LEAVE_ILLEGAL_QUOTA_EXCESS: `超出额度`,
        LEAVE_ILLEGAL_HOUR_QUOTA_EXCESS: '小于48小时且已超额',
        LEAVE_ILLEGAL_DAY_QUOTA_EXCESS: '小于4天且已超额',
        LEAVE_CROSS_QUARTER: '额度尚未生成',
      },
      options: null,
    };
  },
  methods: {
    lookWord() {
      window.open('https://wukongedu.feishu.cn/wiki/wikcno1QonFESjRp08ddR3x3trh?sheet=h2ZIz4', '_blank');
    },
    onGetStudentId(data) {
      this.$emit('onGetStudentId', data);
    },
    // 检查交接合规
    onHandoverClass(value) {
      const times =
        this.$moment(value).format('X') - this.$moment(this.formInfoData.relatedClass[0].beginDateTime).format('X');
      if (times < 90 * 24 * 60 * 60) {
        this.form.getFieldDecorator('reasonable', { initialValue: '否' });
      } else {
        this.form.getFieldDecorator('reasonable', { initialValue: '是' });
      }
      this.oncheckRequired();
    },
    // 检查数据
    oncheckRequired() {
      this.$nextTick(() => {
        const getFieldsValue = this.form.getFieldsValue();
        this.isRequired = Object.entries(getFieldsValue).every((item) => item[1] !== undefined && item[1]);
        // if (this.isRequired) {
        this.$emit('onClassResignation', this.form);
        // }
      });
    },
    // 切换收起详情
    toggleClass() {
      this.visibleClass = !this.visibleClass;
      this.toggleTxt = this.visibleClass ? '收起' : '详情';
    },
    initData(data) {
      if (!this.formInfoData.resign || this.formInfoData.assigneesList[1].data.finished) return;
      // const time = this.$moment(data.takeOverDate);
      // this.form.getFieldDecorator('takeOverDate', { initialValue: time });
      // this.form.getFieldDecorator('outProcedure', { initialValue: data.outProcedure });
      this.form.setFieldsValue({ takeOverDate: this.$moment(data.takeOverDate) });
      this.form.setFieldsValue({ outProcedure: data.outProcedure });
      this.form.setFieldsValue({ description: data.description });
      this.onHandoverClass(data.takeOverDate);
    },
    disabledDate(current) {
      return current && current < this.$moment().subtract(1, 'days');
    },
  },
  mounted() {
    this.initData(this.formInfoData);
    this.$fetch(`/api/teacher/qa/options`).then((res) => {
      this.options = res.data.content;
    });
  },
};
</script>
<style lang="less">
.scheduleMain {
  width: 600px;
}
.scheduleTitle {
  display: flex;
  justify-content: space-around;
  border-bottom: 1px solid #e8e8e8;
  & > div {
    width: 33.33%;
    padding: 10px;
  }
}
.scheduleData {
  max-height: 450px;
  overflow-y: auto;
}
.scheduleDataNo {
  min-height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.scheduleHeader {
  background-color: #fafafa;
}
.scheduleIcon {
  cursor: pointer;
  color: #009cff;
}
.remarkBox {
  img {
    max-width: 500px;
  }
}
</style>
<style lang="less" scoped>
.cursor {
  cursor: pointer;
  color: #009cff !important;
}
.classAdmin .cursor {
  cursor: pointer;
  color: #43d186 !important;
}
.title {
  font-size: 20px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #333333;
  line-height: 28px;
  margin-bottom: 20px;
}
.title::before {
  content: '';
  display: block;
  float: left;
  margin: 4px 12px 0 0;
  width: 4px;
  height: 20px;
  background-color: #009cff;
  border-radius: 0px 6px 6px 0px;
}
.task-form {
  border-bottom: 1px solid #eee;
}
.form-name {
  font-size: 20px;
  font-weight: 600;
  color: #333333;
  line-height: 28px;
  margin-bottom: 15px;
  display: flex;
  align-items: center;
}
.form-name span {
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  padding: 2px 10px;
  border-radius: 6px;
  margin: 0 10px;
}
.pending-status {
  color: #ffbf00;
  background-color: rgba(255, 191, 0, 0.09);
}
.success-status {
  color: #00cf64;
  background-color: rgba(0, 207, 100, 0.09);
}
.refuse-status {
  color: #ff5353;
  background-color: rgba(255, 83, 83, 0.09);
}
.form-teacher {
  font-size: 16px;
  font-weight: 400;
  color: #999;
  line-height: 22px;
  margin-bottom: 15px;
}
.form-teacher .dueDate {
  color: #ff5454;
}
.form-teacher span {
  color: #333;
}
.form-teacher img {
  width: 18px;
  height: 18px;
  border-radius: 50%;
  margin-right: 10px;
}
.form-des {
  font-size: 16px;
  font-weight: 400;
  color: #333333;
  line-height: 22px;
  margin-bottom: 15px;
}
.form-imgs {
  margin-bottom: 20px;
}
.form-imgs img {
  width: 80px;
  height: 80px;
  border-radius: 4px;
  margin-right: 10px;
}
.courseInfo {
  padding: 20px 0;
}
.course-info {
  padding: 20px 16px;
  background: linear-gradient(180deg, #ecf7ff 0%, #f7fcff 100%);
  border-radius: 20px;
  margin-bottom: 20px;
}
.course-name {
  font-size: 16px;
  font-weight: 600;
  color: #009cff;
  line-height: 22px;
  margin-bottom: 10px;
}
.course-info a:hover {
  text-decoration: underline;
}
.course-type {
  font-size: 14px;
  font-weight: 400;
  color: #999999;
  line-height: 18px;
  margin-top: 10px;
}
.student-item {
  display: flex;
  justify-content: flex-start;
}
.student-item p {
  font-size: 14px;
  font-weight: 400;
  color: #999999;
  line-height: 20px;
}
.student-item img {
  width: 42px;
  height: 42px;
  border-radius: 50%;
  margin-right: 7px;
}
.student-item .student-name {
  font-size: 16px;
  font-weight: 600;
  color: #333333;
  line-height: 22px;
}
.form-desc {
  color: #333;
  font-size: 16px;
}
.form-desc1 {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 6px;
}
.form-desc1 p {
  max-width: 80%;
}
.form-desc2 {
  margin-bottom: 20px;
  padding-left: 8px;
}
.red-icon {
  color: #ff5353;
}
.form-label {
  width: 85px;
}
.form-reason {
  flex: 1;
}
.title {
  font-size: 20px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #333333;
  line-height: 28px;
  margin-bottom: 20px;
}
.title::before {
  content: '';
  display: block;
  float: left;
  margin: 4px 12px 0 0;
  width: 4px;
  height: 20px;
  background-color: #009cff;
  border-radius: 0px 6px 6px 0px;
}
.concat-wrap {
  padding-top: 20px;
  margin-bottom: 20px;
}
.concat-con {
  border: 1px solid #eeeeee;
  width: 100%;
  max-height: 200px;
  overflow-y: auto;
  padding: 10px;
  font-size: 16px;
  color: #333;
  margin-bottom: 20px;
}
.concat-item {
  margin-bottom: 5px;
}
.concat-label {
  color: #666;
  font-size: 13px;
  border-left: 1px solid #dddddd;
  padding: 0 10px;
  display: inline-block;
  &:first-child {
    padding: 0 10px 0 0;
    border-left: 0;
  }
}
.bot-border {
  border-bottom: 1px solid #eeeeee;
  padding-bottom: 10px;
}

.class-item {
  font-size: 14px;
  margin-bottom: 4px;
}
.student-item {
  display: flex;
  justify-content: flex-start;
  margin-right: 20px;
}
.student-item p {
  font-size: 14px;
  font-weight: 400;
  color: #999999;
  line-height: 20px;
}
.student-item img {
  width: 42px;
  height: 42px;
  border-radius: 50%;
  margin-right: 7px;
}
.student-item .student-name {
  font-size: 16px;
  font-weight: 600;
  color: #333333;
  line-height: 22px;
}
.student-wrap {
  display: flex;
  margin-bottom: 22px;
}
.classAdmin {
  .title::before {
    background-color: #43d186;
  }
  .course-name {
    color: #43d186;
  }
}
.explainTC {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: url('../assets/explain.png') no-repeat center;
  background-size: 100%;
  cursor: pointer;
  margin-right: 5px;
}
.task-explainTitleTC {
  padding-bottom: 5px;
  display: inline-flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}
.task-titleTxt {
  font-size: 18px;
  font-weight: 600;
}
.task-explain {
  color: #2eaaf9;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
}
</style>
