<template>
  <div :class="subDetailType">
    <div class="task-form">
      <p class="form-name">
        <a-tooltip
          :overlayStyle="{ maxWidth: '500px' }"
          v-if="
            detailType === 'TASK_FIRST_PASS' ||
            detailType === 'TASK_FIRST_FOLLOW_UP' ||
            detailType === 'TASK_FIRST_VISIT' ||
            detailType === 'TASK_W3_VISIT' ||
            detailType === 'TASK_MOTH_VISIT' ||
            detailType === 'TASK_CLASS_ADMIN_FIRST_PASS' ||
            detailType === 'TASK_SCHEDULE' ||
            detailType === 'TASK_CLASS_ADMIN_JOIN' ||
            detailType === 'TASK_PROMOTE' ||
            detailType === 'TASK_TERM_VISIT' ||
            detailType === 'TASK_WEEK_VISIT' ||
            detailType === 'REVIEW_QUESTION_REMINDER' ||
            detailType === 'TASK_MOTH_STUDY_VISIT' ||
            detailType === 'TASK_STUDENT_ACTIVE'
          "
        >
          <template slot="title">
            <div class="task-explainTitleTC">
              <span class="task-titleTxt" v-if="!['TRANSFER_STUDENT_FOLLOW_UP', 'TASK_SCHEDULE'].includes(detailType)"
                >任务说明</span
              ><span
                class="task-explain"
                @click="lookWord"
                v-if="
                  detailType === 'TASK_FIRST_PASS' ||
                  detailType === 'TASK_FIRST_FOLLOW_UP' ||
                  detailType === 'TASK_FIRST_VISIT' ||
                  detailType === 'TASK_W3_VISIT' ||
                  detailType === 'TASK_CLASS_ADMIN_FIRST_PASS' ||
                  detailType === 'TASK_SCHEDULE' ||
                  detailType === 'TASK_CLASS_ADMIN_JOIN' ||
                  detailType === 'TASK_PROMOTE' ||
                  detailType === 'TASK_MOTH_STUDY_VISIT' ||
                  detailType === 'TASK_STUDENT_ACTIVE'
                "
                >查看文档说明</span
              >
            </div>
            <div v-if="detailType === 'TASK_PROMOTE'">
              【完成方式】电话/文字沟通<br />
              【完成时效】40天<br />
              【操作步骤】<br />
              1、沟通前： <br />
              a.看课了解学员阶段学情；查看之前是否存在未解决的异议；<br />
              2、沟通中： <br />
              a. 学情总结归纳：近期学习的进步点和待提升点；<br />
              b. 学习规划：学习规划表、课频建议，xx级别学习目标，级别进度；<br />
              c. 补升意向：了解家长的补升意向，针对性异议处理并关单；若家长拒绝，需挖掘具体原因<br />
              3、沟通后： <br />
              a. 若家长考虑，做好课程包推荐以及持续跟进做异议处理；若关单失败选择正确的未补升原因<br />
              b.及时填写跟进记录，记录沟通的重要信息；<br />
              【注意事项】<br />
              ①先做异议处理，再跟进整理发送学习方案<br />
              ②企业微信发送相关素材<br />
              ③跟进记录中的每一项均需沟通完毕才可完成任务，不得在未沟通的情况下点击完成任务<br />
            </div>
            <div v-if="['TASK_FIRST_PASS', 'TASK_FIRST_FOLLOW_UP'].includes(detailType)">
              【完成方式】电话<br />
              【完成时效】24小时<br />
              【操作步骤】<br />
              1、沟通前： <br />
              a.及时联系顾问老师拉群，微信群里先跟家长打招呼做自我介绍，预约来电时间(非必须)，建立初步联系;<br />
              b.核对花果学员【入学学情】信息<br />
              c. 查看试听课回放形成初步客户画像，并查看试听课老师是否有正式课标签<br />
              d. 了解报名课包信息<br />
              2、沟通后： <br />
              a. 每次联系沟通情况，无论是否有效沟通，均须及时填写跟进记录，记录沟通的重要信息<br />
              b. 发送新生注意事项长图、APP、分享赠课上传方式、请假规则说明和操作步骤、客服电话等重要信息<br />
              c. 完成排课等待处理事项<br />
              【注意事项】<br />
              ①建议在家长当地时间8:00-20:00期间联系，避免对家长造成困扰。 <br />
              ②当天未联系上需要持续跟进的，请在24小时内至少3次尝试沟通，间隔大于半小时。3次后依然未联系上，请及时与家长在聊天内留言说明情况，预约方便沟通的时间再做沟通。
            </div>
            <div v-if="detailType === 'TASK_FIRST_VISIT'">
              【完成方式】电话<br />
              【完成时效】2天<br />
              【操作步骤】<br />
              1、沟通前： <br />
              a.
              观看课节回放：查看老师是否完成课前破冰及课后与家长沟通；确认级别是否需要调整；查看学员是否提交课后互动作业<br />
              2、 沟通中： <br />
              a. 了解家长对首课内容的反馈和孩子的感受、级别进度是否合适、授课老师是否喜欢；进行看课学情反馈<br />
              b. 介绍如何复习、互动作业查看及提交方式；朋友圈分享规则、操作方法和上传路径<br />
              c. 疑问解答：询问在课程/操作等方面是否有疑问<br />
              3、沟通后：<br />
              a. 若级别不变，微信上跟家长确认邮寄信息并确认系统信息填写，安排寄送新生礼包和教辅资料；<br />
              b.发送《如何完成互动作业》操作视频；完成跟进处理沟通中的待解决事项<br />
              c.及时填写跟进记录，记录沟通的重要信息<br />
              【注意事项】<br />
              ①若首课后定级不改变，则核对订单寄送确认信息，完成寄送任务 <br />
              ②若首课后需调整级别，则等待下次课程结束确认定级后再邮寄教辅资料<br />
              ③跟进记录中的每一项均需和家长沟通确认才可完成任务，不得在未确认沟通的情况下点击完成任务<br />
            </div>
            <div v-if="detailType === 'TASK_W3_VISIT'">
              【完成方式】电话/文字沟通<br />
              【完成时效】9天<br />
              【操作步骤】<br />
              1、沟通前： <br />
              a.查看学员信息；b.查看课程回放，总结学员课堂表现情况等；c.了解报名课时信息<br />
              2、沟通中： <br />
              a. 学情沟通：对比首课，多维度反馈学情：学习进步点、课堂表现、作业完成情况及待提升点等；<br />
              b.学情规划制定：学习规划表、课频建议，xx级别学习目标，级别进度和重难点；<br />
              c. 对家长存在疑问做对应异议处理和解答<br />
              d. 补升方案介绍：结合孩子学习情况铺垫方案性价比，优惠的唯一性<br />
              3、沟通后： <br />
              a. 整理发送补升方案，课程包推荐以及持续跟进做异议处理<br />
              b.及时填写跟进记录，记录沟通的重要信息<br />
              【注意事项】<br />
              ①先做异议处理，再跟进整理发送学习方案<br />
              ②跟进记录中的每一项均需沟通完毕才可完成任务，不得在未沟通的情况下点击完成任务
            </div>
            <div v-if="detailType === 'TASK_STUDENT_ACTIVE'">
              【完成方式】电话/文字沟通<br />
              【完成时效】7天<br />
              【操作步骤】<br />
              1、沟通前：<br />
              a.筋斗云系统内查看学员停课时长、课程进度、之前停课原因等<br />
              b.准备课程推荐或学习资料<br />
              2、沟通中：<br />
              a.做好回暖沟通：可询问近期近况，可包含学校近况、天气等寒暄；<br />
              b.询问学员复课计划<br />
              c.根据学员的课程进度做好课程政策、资料推荐(字帖、中文动画、绘本书籍等)<br />
              d.最新上线活动同步、分享赠课提醒<br />
              e.异议处理：根据家长提出的问题或者异议点做分析和处理<br />
              3、沟通后：<br />
              a.优先做风险管理，有问题及时跟进反馈<br />
              b.如确定复课，按照沟通的上课时间及时排好课程并微信发送家长进行确认<br />
              b.及时填写跟进记录，记录沟通的重要信息<br />

              【注意事项】<br />
              ①跟进记录中的每一项均需沟通完毕才可完成任务，不得在未沟通的情况下点击完成任务<br />
            </div>
            <div v-if="detailType === 'TASK_MOTH_VISIT'">
              【完成方式】电话/文字沟通<br />
              【完成时效】30天<br />
              【操作步骤】<br />
              1、沟通前：筋斗云系统内查看学员停课时长、课程进度等 <br />
              2、沟通中： <br />
              a. 做好学生近期中文学习情况沟通、询问近期近况，可包含疫情（复课）、学校近况、天气等寒暄；<br />
              b.根据学员的课程进度做好课程政策、资料推荐(字帖、中文动画、绘本书籍等)<br />
              c.最新上线活动同步、分享赠课提醒<br />
              3、沟通后： <br />
              a. 填写花果跟进记录<br />
              b.优先做风险管理，有问题及时跟进反馈<br />
              【注意事项】<br />
              及时完成“每月回访(冻结学员)”的跟进记录以完成本任务
            </div>
            <div v-if="detailType === 'TASK_CLASS_ADMIN_FIRST_PASS'">
              【完成方式】电话<br />
              【完成时效】3天<br />
              【操作步骤】<br />
              1、沟通前： <br />
              a.查看学员花果档案、学情及跟进记录（上课时间及课频、级别进度、遗留问题等）<br />
              b.排课核实：检查学员当前排课情况，若待开课不足10节需及时加课<br />
              c.看课：了解学员上课情况、课堂表现等<br />
              2、沟通中： <br />
              a.身份介绍、核实学情：姓名、上课时间及频次、班型和级别进度、授课老师、课时扣减规则等<br />
              b.确认是否有遗留问题未解决<br />
              c.学情反馈、了解家长期望并做学习规划（不强制要求）<br />
              3、沟通后： <br />
              a.及时核对当前排课情况，有误的及时更改调整<br />
              b.若有遗留问题/前班主任失误点，需及时向领导汇报<br />
              c.及时填写跟进记录，记录沟通的重要信息<br />

              【注意事项】 ③跟进记录中的每一项均需和家长沟通确认才可完成任务，不得在未确认沟通的情况下点击完成任务
            </div>
            <div v-if="detailType === 'TASK_TERM_VISIT'">
              【完成方式】电话/文字沟通<br />
              【完成时效】15天<br />
              【操作步骤】<br />
              1、沟通前： <br />
              a. 查看近期课程回放，对当前阶段的学习做简单的总结，可从课频、孩子学习习惯、课堂表现等方面<br />
              b. 查看学员单元测评及作业情况，从作业完成情况、优秀点和待提升点进行简单的总结<br />
              2、沟通中： <br />
              a. 学情沟通：与家长沟通本阶段学生课堂表现（参与度、出勤、作业完成情况）、待提升点及建议<br />
              b.
              学习规划：依据课程大纲内容，铺垫并介绍下阶段学习内容与重难点，做好后续的学习规划调整，需要家长配合方向等<br />
              c. 对家长存在疑问做对应异议处理和解答<br />
              3、沟通后： <br />
              a. 优先做风险管理，有问题及时跟进反馈<br />
              b. 及时填写跟进记录，记录沟通的重要信息<br />

              【注意事项】<br />
              ①跟进记录中的每一项均需沟通完毕才可完成任务，不得在未沟通的情况下点击完成任务<br />
            </div>
            <div v-if="['TASK_WEEK_VISIT', 'REVIEW_QUESTION_REMINDER'].includes(detailType)">
              完成方式】电话/文字沟通<br />
              【完成时效】2天<br />
              【操作步骤】<br />
              1、沟通前：<br />
              a. 看课了解学员近2节课的课堂表现，总结学情沟通内容<br />
              2、沟通中： <br />
              a. 学情沟通：与家长沟通学员近期课堂表现、出勤及作业完成情况、待提升点及建议等<br />
              b. 对家长存在疑问做对应异议处理和解答<br />
              3、沟通后： <br />
              a. 优先做风险管理，有问题及时跟进反馈<br />
              b.及时填写跟进记录，记录沟通的重要信息<br />
              【注意事项】<br />
              ①跟进记录中的每一项均需沟通完毕才可完成任务，不得在未沟通的情况下点击完成任务<br />
            </div>
            <div v-if="detailType === 'TASK_MOTH_STUDY_VISIT'">
              【完成方式】电话/文字沟通<br />
              【完成时效】25天<br />
              【操作步骤】<br />
              1、沟通前：<br />
              a. 看课了解学员近期课堂表现，查看互动作业完成情况；总结学情沟通内容<br />
              2、沟通中： <br />
              a. 学情沟通：与家长沟通学员近期课堂表现、出勤及作业完成情况、待提升点及建议等<br />
              b. 对家长存在疑问做对应异议处理和解答<br />
              3、沟通后： <br />
              a. 优先做风险管理，有问题及时跟进反馈<br />
              b.及时填写跟进记录，记录沟通的重要信息<br />
              【注意事项】<br />
              ①跟进记录中的每一项均需沟通完毕才可完成任务，不得在未沟通的情况下点击完成任务<br />
            </div>
          </template>
          <i
            class="explainTC"
            v-if="
              detailType === 'TASK_FIRST_PASS' ||
              detailType === 'TASK_FIRST_FOLLOW_UP' ||
              detailType === 'TASK_FIRST_VISIT' ||
              detailType === 'TASK_W3_VISIT' ||
              detailType === 'TASK_MOTH_VISIT' ||
              detailType === 'TASK_CLASS_ADMIN_FIRST_PASS' ||
              detailType === 'TASK_SCHEDULE' ||
              detailType === 'TASK_CLASS_ADMIN_JOIN' ||
              detailType === 'TASK_PROMOTE' ||
              detailType === 'TASK_TERM_VISIT' ||
              detailType === 'TASK_WEEK_VISIT' ||
              detailType === 'REVIEW_QUESTION_REMINDER' ||
              detailType === 'TASK_MOTH_STUDY_VISIT' ||
              detailType === 'TASK_STUDENT_ACTIVE'
            "
          ></i>
        </a-tooltip>
        {{ formInfoData.question }}
        <span class="pending-status" v-if="!formInfoData.finished">待处理</span>
        <span class="success-status" v-if="formInfoData.finished && !formInfoData.deleted">已完成</span>
        <span class="refuse-status" v-if="formInfoData.finished && formInfoData.deleted">已拒绝</span>
      </p>
      <div class="form-teacher">
        <img :src="defaultImg" alt="" />
        <span
          >{{ formInfoData.startUserName ? formInfoData.startUserName : '系统'
          }}<span v-if="formInfoData.userRole">（ {{ formInfoData.userRole }} ）</span></span
        >
        提交于
        <span>{{ $moment(formInfoData.startTime).utcOffset(8).format('YYYY年MM月DD日 HH:mm') }}</span>
        | 最迟解决时间

        <span :class="formInfoData.expireFlag ? 'dueDate' : ''">{{ formInfoData.dueDate }}</span>
      </div>
      <div class="form-des" v-if="formInfoData.description">
        <p>{{ formInfoData.description }}</p>
      </div>
      <div
        class="form-des"
        v-if="['TASK_TERM_VISIT', 'TASK_WEEK_VISIT', 'REVIEW_QUESTION_REMINDER'].includes(detailType)"
      >
        <p>请及时跟进学员本阶段学情，及时与学员沟通</p>
      </div>
      <div class="form-des" v-if="detailType === 'TASK_STUDENT_ACTIVE'">
        <p>学员{{ formInfoData.subjectName }}状态：{{ formInfoData.studentStatus }}</p>
      </div>
      <div class="form-des" v-if="detailType === 'TASK_STUDENT_ACTIVE'">
        <p>
          冻结/延期时间({{ formInfoData.subjectName }})：{{ formInfoData.freezeFromDate }} -
          {{ formInfoData.freezeToDate }}
        </p>
      </div>
      <div class="form-des" v-if="detailType === 'TASK_STUDENT_ACTIVE'">
        <p>请及时跟进学员，确定开课情况</p>
      </div>
      <div class="form-des" v-if="detailType === 'TASK_PROMOTE'">
        请及时跟进学员，完成新生补升；
        <template v-if="formInfoData.showStudyPlan">
          点击可
          <span style="color: #04cb94; text-decoration: underline; cursor: pointer" @click="hrefStudyPlan">
            生成学习规划
          </span>
        </template>
      </div>
      <div class="form-imgs" v-if="formInfoData.imgs">
        <img v-for="item in formInfoData.imgs.split(',')" :src="item" preview="1" :key="item" alt="" />
      </div>
    </div>
    <RelatedStudents :formInfoData="formInfoData" @onGetStudentId="onGetStudentId" />
    <div style="padding-top: 15px; border-top: 1px solid #e9e9e9" v-if="formInfoData.preClassAdmin">
      <div class="title">
        <span>前班主任</span>
      </div>
      <div class="student-wrap">
        <div class="student-item" style="padding-bottom: 20px">
          <img :src="defaultImg" alt="" class="handler-img" />
          <div>
            <p class="student-name">
              {{ formInfoData.preClassAdmin.preClassAdminName }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <div style="padding-top: 15px; border-top: 1px solid #e9e9e9" v-if="formInfoData.courseConsultantName">
      <div class="title">
        <span>课程顾问</span>
      </div>
      <div class="student-wrap">
        <div class="student-item">
          <img :src="defaultImg" alt="" class="handler-img" />
          <div>
            <p class="student-name">
              {{ formInfoData.courseConsultantName }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="courseInfo" v-if="formInfoData.courseInfo" style="border-top: 1px solid #e9e9e9">
      <div class="title">
        <span>首课课节</span>
      </div>
      <div class="course-info">
        <div
          class="playCourse"
          @click="playCourse(formInfoData.courseInfo.platform)"
          v-if="formInfoData.courseInfo.scheduleStatus === 'COMPLETED'"
        >
          回放
        </div>
        <div
          class="playCourse"
          @click="playClass(formInfoData.courseInfo.platform)"
          v-if="formInfoData.courseInfo.scheduleStatus === 'NORMAL'"
        >
          监课
        </div>
        <router-link
          v-if="formInfoData.showcourseSection"
          class="course-name"
          :to="{ path: '/courseDetails', query: { uuid: formInfoData.courseInfo.uuid } }"
        >
          {{ formInfoData.courseInfo.courseName }}
          {{ formInfoData.courseInfo.scheduleName }}
        </router-link>
        <div v-if="!formInfoData.showcourseSection" class="course-name">
          {{ formInfoData.courseInfo.courseName }} {{ formInfoData.courseInfo.scheduleName }}
        </div>
        <div class="course-type">
          <span>开课时间：{{ formInfoData.courseInfo.startTime }}</span>
        </div>
        <div class="course-type">
          <span>授课老师：{{ formInfoData.courseInfo.teacherName }}</span>
        </div>
        <div class="course-type">
          <span>{{ formInfoData.courseInfo.courseSectionName }}</span> |
          <span>{{ formInfoData.courseInfo.classType === 'PRIVATE' ? '私教课' : '班课' }}</span> |
          <span>学生：{{ formInfoData.courseInfo.studentName || '无' }}</span> |
          <span>班级：{{ formInfoData.courseInfo.formClassName }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import defaultImg from '../assets/service_pic_head.png';
import RelatedStudents from './RelatedStudents.vue';

export default {
  props: {
    formInfoData: {
      type: Object,
    },

    subDetailType: {
      // 区分是老师还是班主任，班主任主题色是绿色
      type: String,
      default: 'teacher',
    },
    detailType: {
      type: String,
      default: '',
    },
  },
  components: { RelatedStudents },
  data() {
    return {
      cursor: 'cursor',
      defaultImg,
      pictures: [],
    };
  },
  methods: {
    hrefStudyPlan() {
      const obj = this.formInfoData.studentArray[0];
      const routeUrl = this.$router.resolve({
        path: '/learningPlanning',
        query: { studentObj: JSON.stringify(obj) },
      });
      window.open(routeUrl.href, '_blank');
    },
    lookWord() {
      let linkUrl = '';
      // 新生首通
      if (['TASK_FIRST_PASS', 'TASK_FIRST_FOLLOW_UP'].includes(this.detailType)) {
        linkUrl = 'https://wukongedu.feishu.cn/wiki/wikcnGGWcgRLcjvZIrsRZlHo90c';
      } else if (this.detailType === 'TASK_FIRST_VISIT') {
        // 首课回访
        linkUrl = 'https://wukongedu.feishu.cn/wiki/wikcnXuikAaW1RENum0d2pUyuNh';
      } else if (this.detailType === 'TASK_W3_VISIT') {
        // w3回访
        linkUrl = 'https://wukongedu.feishu.cn/wiki/wikcnGM4BXsv51OXs8iXvBn1lch';
      } else if (this.detailType === 'TASK_CLASS_ADMIN_FIRST_PASS') {
        // 班主任交接学员首通
        linkUrl = 'https://wukongedu.feishu.cn/wiki/wikcnsX8686SlkufJKDlkjYNWhh';
      } else if (this.detailType === 'TASK_SCHEDULE') {
        // 新生排课
        linkUrl = 'https://wukongedu.feishu.cn/wiki/wikcnR1ZqyT8BU37alWbWZHWIDb?sheet=h2ZIz4';
      } else if (this.detailType === 'TASK_CLASS_ADMIN_JOIN') {
        // 班主任交接入群
        linkUrl = 'https://wukongedu.feishu.cn/wiki/wikcnqndPX2dft5tgA7Fg2ldZKc';
      } else if (this.detailType === 'TASK_PROMOTE') {
        // 新生补升
        linkUrl = 'https://wukongedu.feishu.cn/wiki/T1x0w6omViWavCkqTtDczXSCn3g';
      } else if (this.detailType === 'TASK_MOTH_STUDY_VISIT') {
        linkUrl = 'https://wukongedu.feishu.cn/wiki/wikcnSvb4Gr4bk68L07RLgj7kCh';
      } else if (this.detailType === 'TASK_STUDENT_ACTIVE') {
        linkUrl = 'https://wukongedu.feishu.cn/wiki/wikcnw55op4J3DZtOK98Y7BuXQo';
      }
      window.open(linkUrl, '_blank');
    },
    onGetStudentId(data) {
      this.$emit('onGetStudentId', { name: data.name, id: data.studentId });
    },
    onPictures(datas) {
      this.$emit('onPictures', datas);
    },
    playCourse(platform) {
      this.$emit('playCourse', platform);
    },
    playClass(platform) {
      this.$emit('playClass', platform);
    },
  },
  mounted() {
    console.log(this.formInfoData);
  },
};
</script>

<style lang="less" scoped>
.cursor {
  cursor: pointer;
  color: #009cff !important;
}
.classAdmin {
  .cursor {
    cursor: pointer;
    color: #43d186 !important;
  }

  .title::before {
    background-color: #43d186;
  }
}
.title {
  font-size: 20px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #333333;
  line-height: 28px;
  margin-bottom: 20px;
}
.title::before {
  content: '';
  display: block;
  float: left;
  margin: 4px 12px 0 0;
  width: 4px;
  height: 20px;
  background-color: #009cff;
  border-radius: 0px 6px 6px 0px;
}
.task-form {
  border-bottom: 1px solid #eee;
}

.form-name span {
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  padding: 2px 10px;
  border-radius: 6px;
  margin: 0 10px;
}
.pending-status {
  color: #ffbf00;
  background-color: rgba(255, 191, 0, 0.09);
}
.success-status {
  color: #00cf64;
  background-color: rgba(0, 207, 100, 0.09);
}
.refuse-status {
  color: #ff5353;
  background-color: rgba(255, 83, 83, 0.09);
}
.form-teacher {
  font-size: 16px;
  font-weight: 400;
  color: #999;
  line-height: 22px;
  margin-bottom: 15px;
}
.form-teacher .dueDate {
  color: #ff5454;
}
.form-teacher span {
  color: #333;
}
.form-teacher img {
  width: 18px;
  height: 18px;
  border-radius: 50%;
  margin-right: 10px;
}
.form-des {
  font-size: 16px;
  font-weight: 400;
  color: #333333;
  line-height: 22px;
  margin-bottom: 15px;
}
.form-imgs {
  margin-bottom: 20px;
}
.form-imgs img {
  width: 80px;
  height: 80px;
  border-radius: 4px;
  margin-right: 10px;
}
.courseInfo {
  padding: 20px 0;
}
.course-info {
  padding: 20px 16px;
  background: linear-gradient(180deg, #ecf7ff 0%, #f7fcff 100%);
  border-radius: 20px;
  position: relative;
}
.course-name {
  font-size: 16px;
  font-weight: 600;
  color: #009cff;
  line-height: 22px;
  margin-bottom: 10px;
}
.course-info a:hover {
  text-decoration: underline;
}
.course-type {
  font-size: 14px;
  font-weight: 400;
  color: #999999;
  line-height: 18px;
  margin-top: 10px;
}

.student-item {
  display: flex;
  justify-content: space-between;
}
.student-item p {
  font-size: 14px;
  font-weight: 400;
  color: #999999;
  line-height: 20px;
}
.student-item img {
  width: 42px;
  height: 42px;
  border-radius: 50%;
  margin-right: 7px;
}
.student-item .student-name {
  font-size: 16px;
  font-weight: 600;
  color: #333333;
  line-height: 22px;
  width: 300px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.student-wrap {
  display: flex;
  margin-bottom: 22px;
  justify-content: space-between;
}
.playCourse {
  background-color: #fff;
  color: #009cff;
  padding: 4px 8px;
  border-radius: 10px;
  cursor: pointer;
  position: absolute;
  right: 5px;
  top: 10px;
}
.explainTC {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: url('../assets/explain.png') no-repeat center;
  background-size: 100%;
  cursor: pointer;
  margin-right: 5px;
}
.task-explainTitleTC {
  padding-bottom: 5px;
  display: inline-flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}
.task-titleTxt {
  font-size: 18px;
  font-weight: 600;
}
.task-explain {
  color: #2eaaf9;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
}
.form-name {
  font-size: 20px;
  font-weight: 600;
  color: #333333;
  line-height: 28px;
  margin-bottom: 15px;
  display: flex;
  align-items: center;
}
</style>
