<template>
  <div class="wrapper">
    <div v-if="studentInfo.subjectName === '数学'">
      <div class="md-temp" :class="studentInfo.sectionType">
        <div class="name">{{ studentInfo.name }}</div>
        <div class="rate">{{ studentInfo.rate }}</div>
        <div class="math-teacher">{{ studentInfo.teacher }}</div>
        <div class="math-tutor">{{ studentInfo.tutor }}</div>
      </div>
    </div>

    <!--中文模板-->
    <div v-if="studentInfo.subjectName === '中文'">
      <div class="ma-temp" :class="studentInfo.chineseType === 'MAJOR' ? 'major' : 'special'">
        <div class="chinese-name">{{ studentInfo.name }}</div>
        <div class="chinese-level">{{ studentInfo.sectionType }}</div>
        <div class="chinese-teacher">{{ studentInfo.teacher }}</div>
        <div class="chinese-tutor">{{ studentInfo.tutor }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Certificate',
  props: {
    studentInfo: {
      type: Object,
      require: true,
    },
  },
  data() {
    return {};
  },
  mounted() {
    console.log(this.studentInfo.sectionType);
  },
};
</script>

<style scoped lang="less">
.wrapper {
}
.md-temp {
  width: 595px;
  height: 842px;
  position: relative;
}
.M2D,
.LM2D {
  color: #005db3;
  background: url('../assets/m2d.png') no-repeat center;
  background-size: 100% 100%;
  .name {
    position: absolute;
    font-size: 32px;
    font-weight: bold;
    top: 245px;
    left: 70px;
  }
  .rate {
    position: absolute;
    top: 305px;
    left: 270px;
    font-size: 22px;
    font-weight: bold;
  }
  .math-teacher {
    position: absolute;
    top: 607px;
    left: 150px;
    font-size: 14px;
    font-weight: bold;
  }
  .math-tutor {
    position: absolute;
    top: 607px;
    left: 345px;
    font-size: 14px;
    font-weight: bold;
  }
}
.M3D,
.LM3D {
  color: #005db3;
  background: url('../assets/m3d.png') no-repeat center;
  background-size: 100% 100%;
  .name {
    position: absolute;
    font-size: 32px;
    font-weight: bold;
    top: 245px;
    left: 50%;
    transform: translate(-50%);
  }
  .rate {
    position: absolute;
    top: 337px;
    left: 405px;
    font-size: 22px;
    font-weight: bold;
  }
  .math-teacher {
    position: absolute;
    top: 665px;
    left: 150px;
    font-size: 14px;
    font-weight: bold;
  }
  .math-tutor {
    position: absolute;
    top: 665px;
    left: 378px;
    font-size: 14px;
    font-weight: bold;
  }
}
.M4D,
.LM4D {
  color: #005db3;
  background: url('../assets/m4d.png') no-repeat center;
  background-size: 100% 100%;
  .name {
    position: absolute;
    font-size: 32px;
    font-weight: bold;
    top: 228px;
    left: 45px;
  }
  .rate {
    position: absolute;
    top: 294px;
    left: 260px;
    font-size: 22px;
    font-weight: bold;
  }
  .math-teacher {
    position: absolute;
    top: 514px;
    left: 138px;
    font-size: 14px;
    font-weight: bold;
  }
  .math-tutor {
    position: absolute;
    top: 514px;
    left: 348px;
    font-size: 14px;
    font-weight: bold;
  }
}
.M5D,
.LM5D {
  color: #005db3;
  background: url('../assets/m5d.png') no-repeat center;
  background-size: 100% 100%;
  .name {
    position: absolute;
    font-size: 32px;
    font-weight: bold;
    top: 281px;
    left: 50%;
    transform: translate(-50%);
  }
  .rate {
    position: absolute;
    top: 356px;
    left: 405px;
    font-size: 22px;
    font-weight: bold;
  }
  .math-teacher {
    position: absolute;
    top: 602px;
    left: 128px;
    font-size: 14px;
    font-weight: bold;
  }
  .math-tutor {
    position: absolute;
    top: 602px;
    left: 396px;
    font-size: 14px;
    font-weight: bold;
  }
}
.M6D,
.LM6D {
  color: #005db3;
  background: url('../assets/m6d.png') no-repeat center;
  background-size: 100% 100%;
  .name {
    position: absolute;
    font-size: 32px;
    font-weight: bold;
    top: 338px;
    left: 50%;
    transform: translate(-50%);
  }
  .rate {
    position: absolute;
    top: 409px;
    left: 402px;
    font-size: 22px;
    font-weight: bold;
  }
  .math-teacher {
    position: absolute;
    top: 614px;
    left: 130px;
    font-size: 14px;
    font-weight: bold;
  }
  .math-tutor {
    position: absolute;
    top: 614px;
    left: 392px;
    font-size: 14px;
    font-weight: bold;
  }
}
.M7D,
.LM7D {
  color: #005db3;
  background: url('../assets/m7d.png') no-repeat center;
  background-size: 100% 100%;
  .name {
    position: absolute;
    font-size: 32px;
    font-weight: bold;
    top: 330px;
    left: 45px;
    color: #faa927;
  }
  .rate {
    position: absolute;
    top: 398px;
    left: 240px;
    font-size: 22px;
    color: #faa927;
    font-weight: bold;
  }
  .math-teacher {
    position: absolute;
    top: 644px;
    left: 48px;
    font-size: 14px;
    font-weight: bold;
  }
  .math-tutor {
    position: absolute;
    top: 736px;
    left: 49px;
    font-size: 14px;
    font-weight: bold;
  }
}
.A9A {
  color: #005db3;
  background: url('../assets/a9a.png') no-repeat center;
  background-size: 100% 100%;
  .name {
    position: absolute;
    font-size: 32px;
    font-weight: bold;
    top: 303px;
    left: 120px;
    color: #005db3;
    text-align: center;
    width: 350px;
  }
  .rate {
    position: absolute;
    top: 445px;
    left: 400px;
    font-size: 22px;
    color: #005db3;
    font-weight: bold;
  }
  .math-teacher {
    text-align: center;
    position: absolute;
    top: 628px;
    left: 90px;
    font-size: 16px;
    width: 200;
    width: 210px;
  }
  .math-tutor {
    position: absolute;
    top: 628px;
    right: 97px;
    width: 210px;
    font-size: 16px;
    text-align: center;
  }
}
.A9B {
  color: #005db3;
  background: url('../assets/a9b.png') no-repeat center;
  background-size: 100% 100%;
  .name {
    position: absolute;
    font-size: 32px;
    font-weight: bold;
    top: 303px;
    left: 120px;
    color: #005db3;
    text-align: center;
    width: 350px;
  }
  .rate {
    position: absolute;
    top: 445px;
    left: 400px;
    font-size: 22px;
    color: #005db3;
    font-weight: bold;
  }
  .math-teacher {
    text-align: center;
    position: absolute;
    top: 606px;
    left: 90px;
    font-size: 16px;
    width: 200;
    width: 210px;
  }
  .math-tutor {
    position: absolute;
    top: 606px;
    right: 97px;
    width: 210px;
    font-size: 16px;
    text-align: center;
  }
}
.A9C {
  color: #005db3;
  background: url('../assets/a9c.png') no-repeat center;
  background-size: 100% 100%;
  .name {
    position: absolute;
    font-size: 32px;
    font-weight: bold;
    top: 273px;
    left: 120px;
    color: #005db3;
    text-align: center;
    width: 350px;
  }
  .rate {
    position: absolute;
    top: 414px;
    left: 400px;
    font-size: 22px;
    color: #005db3;
    font-weight: bold;
  }
  .math-teacher {
    text-align: center;
    position: absolute;
    top: 598px;
    left: 90px;
    font-size: 16px;
    width: 200;
    width: 210px;
  }
  .math-tutor {
    position: absolute;
    top: 598px;
    right: 97px;
    width: 210px;
    font-size: 16px;
    text-align: center;
  }
}
.A9D {
  color: #005db3;
  background: url('../assets/a9d.png') no-repeat center;
  background-size: 100% 100%;
  .name {
    position: absolute;
    font-size: 32px;
    font-weight: bold;
    top: 273px;
    left: 120px;
    color: #005db3;
    text-align: center;
    width: 350px;
  }
  .rate {
    position: absolute;
    top: 414px;
    left: 400px;
    font-size: 22px;
    color: #005db3;
    font-weight: bold;
  }
  .math-teacher {
    text-align: center;
    position: absolute;
    top: 598px;
    left: 90px;
    font-size: 16px;
    width: 200;
    width: 210px;
  }
  .math-tutor {
    position: absolute;
    top: 598px;
    right: 97px;
    width: 210px;
    font-size: 16px;
    text-align: center;
  }
}
.A9E {
  color: #005db3;
  background: url('../assets/a9e.png') no-repeat center;
  background-size: 100% 100%;
  .name {
    position: absolute;
    font-size: 32px;
    font-weight: bold;
    top: 269px;
    left: 120px;
    color: #005db3;
    text-align: center;
    width: 350px;
  }
  .rate {
    position: absolute;
    top: 410px;
    left: 400px;
    font-size: 22px;
    color: #005db3;
    font-weight: bold;
  }
  .math-teacher {
    text-align: center;
    position: absolute;
    top: 594px;
    left: 90px;
    font-size: 16px;
    width: 200;
    width: 210px;
  }
  .math-tutor {
    position: absolute;
    top: 594px;
    right: 97px;
    width: 210px;
    font-size: 16px;
    text-align: center;
  }
}
.A1A {
  color: #005db3;
  background: url('../assets/a1a.png') no-repeat center;
  background-size: 100% 100%;
  .name {
    position: absolute;
    font-size: 32px;
    font-weight: bold;
    top: 287px;
    left: 120px;
    color: #005db3;
    text-align: center;
    width: 350px;
  }
  .rate {
    position: absolute;
    top: 430px;
    left: 400px;
    font-size: 22px;
    color: #005db3;
    font-weight: bold;
  }
  .math-teacher {
    text-align: center;
    position: absolute;
    top: 613px;
    left: 90px;
    font-size: 16px;
    width: 200;
    width: 210px;
  }
  .math-tutor {
    position: absolute;
    top: 613px;
    right: 97px;
    width: 210px;
    font-size: 16px;
    text-align: center;
  }
}
.A1B {
  color: #005db3;
  background: url('../assets/a1b.png') no-repeat center;
  background-size: 100% 100%;
  .name {
    position: absolute;
    font-size: 32px;
    font-weight: bold;
    top: 287px;
    left: 120px;
    color: #005db3;
    text-align: center;
    width: 350px;
  }
  .rate {
    position: absolute;
    top: 430px;
    left: 400px;
    font-size: 22px;
    color: #005db3;
    font-weight: bold;
  }
  .math-teacher {
    text-align: center;
    position: absolute;
    top: 630px;
    left: 90px;
    font-size: 16px;
    width: 200;
    width: 210px;
  }
  .math-tutor {
    position: absolute;
    top: 630px;
    right: 97px;
    width: 210px;
    font-size: 16px;
    text-align: center;
  }
}
.A1C {
  color: #005db3;
  background: url('../assets/a1c.png') no-repeat center;
  background-size: 100% 100%;
  .name {
    position: absolute;
    font-size: 32px;
    font-weight: bold;
    top: 287px;
    left: 120px;
    color: #005db3;
    text-align: center;
    width: 350px;
  }
  .rate {
    position: absolute;
    top: 430px;
    left: 400px;
    font-size: 22px;
    color: #005db3;
    font-weight: bold;
  }
  .math-teacher {
    text-align: center;
    position: absolute;
    top: 612px;
    left: 90px;
    font-size: 16px;
    width: 200;
    width: 210px;
  }
  .math-tutor {
    position: absolute;
    top: 612px;
    right: 97px;
    width: 210px;
    font-size: 16px;
    text-align: center;
  }
}
.A1D {
  color: #005db3;
  background: url('../assets/a1d.png') no-repeat center;
  background-size: 100% 100%;
  .name {
    position: absolute;
    font-size: 32px;
    font-weight: bold;
    top: 287px;
    left: 120px;
    color: #005db3;
    text-align: center;
    width: 350px;
  }
  .rate {
    position: absolute;
    top: 430px;
    left: 400px;
    font-size: 22px;
    color: #005db3;
    font-weight: bold;
  }
  .math-teacher {
    text-align: center;
    position: absolute;
    top: 612px;
    left: 90px;
    font-size: 16px;
    width: 200;
    width: 210px;
  }
  .math-tutor {
    position: absolute;
    top: 612px;
    right: 97px;
    width: 210px;
    font-size: 16px;
    text-align: center;
  }
}
.A1E {
  color: #005db3;
  background: url('../assets/a1e.png') no-repeat center;
  background-size: 100% 100%;
  .name {
    position: absolute;
    font-size: 32px;
    font-weight: bold;
    top: 287px;
    left: 120px;
    color: #005db3;
    text-align: center;
    width: 350px;
  }
  .rate {
    position: absolute;
    top: 430px;
    left: 400px;
    font-size: 22px;
    color: #005db3;
    font-weight: bold;
  }
  .math-teacher {
    text-align: center;
    position: absolute;
    top: 612px;
    left: 90px;
    font-size: 16px;
    width: 200;
    width: 210px;
  }
  .math-tutor {
    position: absolute;
    top: 612px;
    right: 97px;
    width: 210px;
    font-size: 16px;
    text-align: center;
  }
}
.A2A {
  color: #005db3;
  background: url('../assets/a2a.png') no-repeat center;
  background-size: 100% 100%;
  .name {
    position: absolute;
    font-size: 32px;
    font-weight: bold;
    top: 305px;
    left: 45px;
    color: #005db3;
    text-align: center;
    width: 350px;
  }
  .rate {
    position: absolute;
    top: 448px;
    left: 279px;
    font-size: 22px;
    color: #005db3;
    font-weight: bold;
  }
  .math-teacher {
    text-align: center;
    position: absolute;
    top: 607px;
    left: 30px;
    font-size: 16px;
    width: 200;
    width: 210px;
  }
  .math-tutor {
    position: absolute;
    top: 607px;
    right: 160px;
    width: 210px;
    font-size: 16px;
    text-align: center;
  }
}
.A3A {
  color: #005db3;
  background: url('../assets/a3a.png') no-repeat center;
  background-size: 100% 100%;
  .name {
    position: absolute;
    font-size: 32px;
    font-weight: bold;
    top: 322px;
    left: 122px;
    color: #005db3;
    text-align: center;
    width: 350px;
  }
  .rate {
    position: absolute;
    top: 468px;
    left: 387px;
    font-size: 22px;
    color: #005db3;
    font-weight: bold;
  }
  .math-teacher {
    text-align: center;
    position: absolute;
    top: 644px;
    left: 96px;
    font-size: 16px;

    width: 210px;
  }
  .math-tutor {
    position: absolute;
    top: 644px;
    right: 90px;
    width: 210px;
    font-size: 16px;

    text-align: center;
  }
}
.A4A {
  color: #005db3;
  background: url('../assets/a4a.png') no-repeat center;
  background-size: 100% 100%;
  .name {
    position: absolute;
    font-size: 32px;
    font-weight: bold;
    top: 305px;
    left: 32px;
    color: #005db3;
    text-align: center;
    width: 350px;
  }
  .rate {
    position: absolute;
    top: 447px;
    left: 256px;
    font-size: 22px;
    color: #005db3;
    font-weight: bold;
  }
  .math-teacher {
    text-align: center;
    position: absolute;
    top: 631px;
    left: 12px;
    font-size: 16px;

    width: 210px;
  }
  .math-tutor {
    position: absolute;
    top: 631px;
    right: 183px;
    width: 210px;
    font-size: 16px;
    text-align: center;
  }
}
.A5A {
  color: #005db3;
  background: url('../assets/a5a.png') no-repeat center;
  background-size: 100% 100%;
  .name {
    position: absolute;
    font-size: 32px;
    font-weight: bold;
    top: 300px;
    left: 50%;
    transform: translate(-50%);
    color: #005db3;
    text-align: center;
    width: 350px;
  }
  .rate {
    position: absolute;
    top: 444px;
    left: 392px;
    font-size: 22px;
    color: #005db3;
  }
  .math-teacher {
    text-align: center;
    position: absolute;
    top: 642px;
    left: 102px;
    font-size: 16px;
    width: 210px;
  }
  .math-tutor {
    position: absolute;
    top: 642px;
    right: 92px;
    width: 210px;
    font-size: 16px;
    text-align: center;
  }
}
.A5D {
  color: #005db3;
  background: url('../assets/a5d.png') no-repeat center;
  background-size: 100% 100%;
  .name {
    position: absolute;
    font-size: 32px;
    font-weight: bold;
    top: 302px;
    left: 50%;
    transform: translate(-50%);
    color: #005db3;
    text-align: center;
    width: 350px;
  }
  .rate {
    position: absolute;
    top: 445px;
    left: 380px;
    font-size: 22px;
    color: #005db3;
  }
  .math-teacher {
    text-align: center;
    position: absolute;
    top: 624px;
    left: 102px;
    font-size: 16px;
    width: 210px;
  }
  .math-tutor {
    position: absolute;
    top: 624px;
    right: 92px;
    width: 210px;
    font-size: 16px;
    text-align: center;
  }
}
.A6A {
  color: #005db3;
  background: url('../assets/a6a.png') no-repeat center;
  background-size: 100% 100%;
  .name {
    position: absolute;
    font-size: 32px;
    font-weight: bold;
    top: 363px;
    left: 115px;
    color: #005db3;
    text-align: center;
    width: 350px;
  }
  .rate {
    position: absolute;
    top: 506px;
    left: 380px;
    font-size: 22px;
    color: #005db3;
    font-weight: bold;
  }
  .math-teacher {
    text-align: center;
    position: absolute;
    top: 686px;
    left: 95px;
    font-size: 16px;
    width: 210px;
  }
  .math-tutor {
    position: absolute;
    top: 686px;
    right: 100px;
    width: 210px;
    font-size: 16px;
    text-align: center;
  }
}
.A7A {
  color: #005db3;
  background: url('../assets/a7a.png') no-repeat center;
  background-size: 100% 100%;
  .name {
    position: absolute;
    font-size: 32px;
    font-weight: bold;
    top: 293px;
    left: 20px;
    color: #005db3;
    text-align: center;
    width: 350px;
  }
  .rate {
    position: absolute;
    top: 471px;
    left: 238px;
    font-size: 22px;
    color: #005db3;
    font-weight: bold;
  }
  .math-teacher {
    text-align: center;
    position: absolute;
    top: 710px;
    left: 0px;
    font-size: 16px;
    width: 210px;
  }
  .math-tutor {
    position: absolute;
    top: 710px;
    right: 227px;
    width: 210px;
    font-size: 16px;
    text-align: center;
  }
}
.A7E {
  color: #005db3;
  background: url('../assets/a7e.png') no-repeat center;
  background-size: 100% 100%;
  .name {
    position: absolute;
    font-size: 32px;
    font-weight: bold;
    top: 291px;
    left: 20px;
    color: #005db3;
    text-align: center;
    width: 350px;
  }
  .rate {
    position: absolute;
    top: 470px;
    left: 251px;
    font-size: 22px;
    color: #005db3;
    font-weight: bold;
  }
  .math-teacher {
    text-align: center;
    position: absolute;
    top: 677px;
    left: 11px;
    font-size: 16px;
    width: 210px;
  }
  .math-tutor {
    position: absolute;
    top: 677px;
    right: 212px;
    width: 210px;
    font-size: 16px;
    text-align: center;
  }
}
.A7C {
  color: #005db3;
  background: url('../assets/a7c.png') no-repeat center;
  background-size: 100% 100%;
  .name {
    position: absolute;
    font-size: 32px;
    font-weight: bold;
    top: 291px;
    left: 32px;
    color: #005db3;
    text-align: center;
    width: 350px;
  }
  .rate {
    position: absolute;
    top: 470px;
    left: 251px;
    font-size: 22px;
    color: #005db3;
    font-weight: bold;
  }
  .math-teacher {
    text-align: center;
    position: absolute;
    top: 698px;
    left: 10px;
    font-size: 16px;
    width: 210px;
  }
  .math-tutor {
    position: absolute;
    top: 698px;
    right: 218px;
    width: 210px;
    font-size: 16px;
    text-align: center;
  }
}
.A7D {
  color: #005db3;
  background: url('../assets/a7d.png') no-repeat center;
  background-size: 100% 100%;
  .name {
    position: absolute;
    font-size: 32px;
    font-weight: bold;
    top: 291px;
    left: 20px;
    color: #005db3;
    text-align: center;
    width: 350px;
  }
  .rate {
    position: absolute;
    top: 469px;
    left: 251px;
    font-size: 22px;
    color: #005db3;
    font-weight: bold;
  }
  .math-teacher {
    text-align: center;
    position: absolute;
    top: 678px;
    left: 12px;
    font-size: 16px;
    width: 210px;
  }
  .math-tutor {
    position: absolute;
    top: 678px;
    right: 211px;
    width: 210px;
    font-size: 16px;
    text-align: center;
  }
}
.A2B {
  color: #005db3;
  background: url('../assets/a2b.png') no-repeat center;
  background-size: 100% 100%;
  .name {
    position: absolute;
    font-size: 32px;
    font-weight: bold;
    top: 300px;
    left: 60px;
    color: #005db3;
    text-align: center;
    width: 330px;
  }
  .rate {
    position: absolute;
    top: 447px;
    left: 278px;
    font-size: 22px;
    color: #005db3;
    font-weight: bold;
  }
  .math-teacher {
    text-align: center;
    position: absolute;
    top: 633px;
    left: 33px;
    font-size: 16px;
    width: 210px;
  }
  .math-tutor {
    position: absolute;
    top: 633px;
    right: 160px;
    width: 210px;
    font-size: 16px;
    text-align: center;
  }
}
.A2E {
  color: #005db3;
  background: url('../assets/a2e.png') no-repeat center;
  background-size: 100% 100%;
  .name {
    position: absolute;
    font-size: 32px;
    font-weight: bold;
    top: 300px;
    left: 60px;
    color: #005db3;
    text-align: center;
    width: 330px;
  }
  .rate {
    position: absolute;
    top: 447px;
    left: 278px;
    font-size: 22px;
    color: #005db3;
    font-weight: bold;
  }
  .math-teacher {
    text-align: center;
    position: absolute;
    top: 633px;
    left: 33px;
    font-size: 16px;
    width: 210px;
  }
  .math-tutor {
    position: absolute;
    top: 633px;
    right: 160px;
    width: 210px;
    font-size: 16px;
    text-align: center;
  }
}
.A2D {
  color: #005db3;
  background: url('../assets/a2d.png') no-repeat center;
  background-size: 100% 100%;
  .name {
    position: absolute;
    font-size: 32px;
    font-weight: bold;
    top: 300px;
    left: 60px;
    color: #005db3;
    text-align: center;
    width: 330px;
  }
  .rate {
    position: absolute;
    top: 447px;
    left: 278px;
    font-size: 22px;
    color: #005db3;
    font-weight: bold;
  }
  .math-teacher {
    text-align: center;
    position: absolute;
    top: 633px;
    left: 33px;
    font-size: 16px;
    width: 210px;
  }
  .math-tutor {
    position: absolute;
    top: 633px;
    right: 160px;
    width: 210px;
    font-size: 16px;
    text-align: center;
  }
}
.A3B {
  color: #005db3;
  background: url('../assets/a3b.png') no-repeat center;
  background-size: 100% 100%;
  .name {
    position: absolute;
    font-size: 32px;
    font-weight: bold;
    top: 325px;
    left: 125px;
    color: #005db3;
    text-align: center;
    width: 330px;
  }
  .rate {
    position: absolute;
    top: 467px;
    left: 294px;
    font-size: 22px;
    color: #005db3;
    font-weight: bold;
  }
  .math-teacher {
    text-align: center;
    position: absolute;
    top: 648px;
    left: 102px;
    font-size: 16px;
    width: 210px;
  }
  .math-tutor {
    position: absolute;
    top: 648px;
    right: 95px;
    width: 210px;
    font-size: 16px;
    text-align: center;
  }
}
.A4B {
  color: #005db3;
  background: url('../assets/a4b.png') no-repeat center;
  background-size: 100% 100%;
  .name {
    position: absolute;
    font-size: 32px;
    font-weight: bold;
    top: 292px;
    left: 49px;
    color: #005db3;
    text-align: center;
    width: 330px;
  }
  .rate {
    position: absolute;
    top: 434px;
    left: 258px;
    font-size: 22px;
    color: #005db3;
    font-weight: bold;
  }
  .math-teacher {
    text-align: center;
    position: absolute;
    top: 615px;
    left: 12px;
    font-size: 16px;
    width: 210px;
  }
  .math-tutor {
    position: absolute;
    top: 615px;
    right: 180px;
    width: 210px;
    font-size: 16px;
    text-align: center;
  }
}
.A4E {
  color: #005db3;
  background: url('../assets/a4e.png') no-repeat center;
  background-size: 100% 100%;
  .name {
    position: absolute;
    font-size: 32px;
    font-weight: bold;
    top: 292px;
    left: 49px;
    color: #005db3;
    text-align: center;
    width: 330px;
  }
  .rate {
    position: absolute;
    top: 434px;
    left: 258px;
    font-size: 22px;
    color: #005db3;
    font-weight: bold;
  }
  .math-teacher {
    text-align: center;
    position: absolute;
    top: 607px;
    left: 12px;
    font-size: 16px;
    width: 210px;
  }
  .math-tutor {
    position: absolute;
    top: 607px;
    right: 180px;
    width: 210px;
    font-size: 16px;
    text-align: center;
  }
}
.A5B {
  color: #005db3;
  background: url('../assets/a5b.png') no-repeat center;
  background-size: 100% 100%;
  .name {
    position: absolute;
    font-size: 32px;
    font-weight: bold;
    top: 301px;
    left: 135px;
    color: #005db3;
    text-align: center;
    width: 330px;
  }
  .rate {
    position: absolute;
    top: 443px;
    left: 380px;
    font-size: 22px;
    color: #005db3;
    font-weight: bold;
  }
  .math-teacher {
    text-align: center;
    position: absolute;
    top: 619px;
    left: 100px;
    font-size: 16px;
    width: 210px;
  }
  .math-tutor {
    position: absolute;
    top: 619px;
    right: 88px;
    width: 210px;
    font-size: 16px;
    text-align: center;
  }
}
.A5E {
  color: #005db3;
  background: url('../assets/a5e.png') no-repeat center;
  background-size: 100% 100%;
  .name {
    position: absolute;
    font-size: 32px;
    font-weight: bold;
    top: 300px;
    left: 135px;
    color: #005db3;
    text-align: center;
    width: 330px;
  }
  .rate {
    position: absolute;
    top: 443px;
    left: 380px;
    font-size: 22px;
    color: #005db3;
    font-weight: bold;
  }
  .math-teacher {
    text-align: center;
    position: absolute;
    top: 634px;
    left: 100px;
    font-size: 16px;
    width: 210px;
  }
  .math-tutor {
    position: absolute;
    top: 634px;
    right: 87px;
    width: 210px;
    font-size: 16px;
    text-align: center;
  }
}
.A6B {
  color: #005db3;
  background: url('../assets/a6b.png') no-repeat center;
  background-size: 100% 100%;
  .name {
    position: absolute;
    font-size: 32px;
    font-weight: bold;
    top: 339px;
    left: 135px;
    color: #005db3;
    text-align: center;
    width: 330px;
  }
  .rate {
    position: absolute;
    top: 479px;
    left: 374px;
    font-size: 22px;
    color: #005db3;
    font-weight: bold;
  }
  .math-teacher {
    text-align: center;
    position: absolute;
    top: 664px;
    left: 100px;
    font-size: 16px;
    width: 210px;
  }
  .math-tutor {
    position: absolute;
    top: 664px;
    right: 98px;
    width: 210px;
    font-size: 16px;
    text-align: center;
  }
}
.A6E {
  color: #005db3;
  background: url('../assets/a6e.png') no-repeat center;
  background-size: 100% 100%;
  .name {
    position: absolute;
    font-size: 32px;
    font-weight: bold;
    top: 339px;
    left: 133px;
    color: #005db3;
    text-align: center;
    width: 330px;
  }
  .rate {
    position: absolute;
    top: 479px;
    left: 374px;
    font-size: 22px;
    color: #005db3;
    font-weight: bold;
  }
  .math-teacher {
    text-align: center;
    position: absolute;
    top: 664px;
    left: 100px;
    font-size: 16px;
    width: 210px;
  }
  .math-tutor {
    position: absolute;
    top: 664px;
    right: 94px;
    width: 210px;
    font-size: 16px;
    text-align: center;
  }
}
.A6D {
  color: #005db3;
  background: url('../assets/a6d.png') no-repeat center;
  background-size: 100% 100%;
  .name {
    position: absolute;
    font-size: 32px;
    font-weight: bold;
    top: 339px;
    left: 135px;
    color: #005db3;
    text-align: center;
    width: 330px;
  }
  .rate {
    position: absolute;
    top: 481px;
    left: 374px;
    font-size: 22px;
    color: #005db3;
    font-weight: bold;
  }
  .math-teacher {
    text-align: center;
    position: absolute;
    top: 664px;
    left: 100px;
    font-size: 16px;
    width: 210px;
  }
  .math-tutor {
    position: absolute;
    top: 664px;
    right: 97px;
    width: 210px;
    font-size: 16px;
    text-align: center;
  }
}
.A7B {
  color: #005db3;
  background: url('../assets/a7b.png') no-repeat center;
  background-size: 100% 100%;
  .name {
    position: absolute;
    font-size: 32px;
    font-weight: bold;
    top: 292px;
    left: 35px;
    color: #005db3;
    text-align: center;
    width: 330px;
  }
  .rate {
    position: absolute;
    top: 470px;
    left: 256px;
    font-size: 22px;
    color: #005db3;
    font-weight: bold;
  }
  .math-teacher {
    text-align: center;
    position: absolute;
    top: 675px;
    left: 10px;
    font-size: 16px;
    width: 210px;
  }
  .math-tutor {
    position: absolute;
    top: 675px;
    right: 228px;
    width: 180px;
    font-size: 16px;
    text-align: center;
  }
}
.A8A {
  color: #005db3;
  background: url('../assets/a8a.png') no-repeat center;
  background-size: 100% 100%;
  .name {
    position: absolute;
    font-size: 32px;
    font-weight: bold;
    top: 330px;
    left: 120px;
    color: #005db3;
    text-align: center;
    width: 330px;
  }
  .rate {
    position: absolute;
    top: 474px;
    left: 373px;
    font-size: 22px;
    color: #005db3;
    font-weight: bold;
  }
  .math-teacher {
    text-align: center;
    position: absolute;
    top: 658px;
    left: 100px;
    font-size: 16px;
    width: 210px;
  }
  .math-tutor {
    position: absolute;
    top: 658px;
    right: 100px;
    width: 210px;
    font-size: 16px;
    text-align: center;
  }
}
.A8B {
  color: #005db3;
  background: url('../assets/a8b.png') no-repeat center;
  background-size: 100% 100%;
  .name {
    position: absolute;
    font-size: 32px;
    font-weight: bold;
    top: 330px;
    left: 120px;
    color: #005db3;
    text-align: center;
    width: 330px;
  }
  .rate {
    position: absolute;
    top: 474px;
    left: 373px;
    font-size: 22px;
    color: #005db3;
    font-weight: bold;
  }
  .math-teacher {
    text-align: center;
    position: absolute;
    top: 658px;
    left: 100px;
    font-size: 16px;
    width: 210px;
  }
  .math-tutor {
    position: absolute;
    top: 658px;
    right: 100px;
    width: 210px;
    font-size: 16px;
    text-align: center;
  }
}
.A8C {
  color: #005db3;
  background: url('../assets/a8c.png') no-repeat center;
  background-size: 100% 100%;
  .name {
    position: absolute;
    font-size: 32px;
    font-weight: bold;
    top: 284px;
    left: 30px;
    color: #005db3;
    text-align: center;
    width: 330px;
  }
  .rate {
    position: absolute;
    top: 430px;
    left: 243px;
    font-size: 22px;
    color: #005db3;
    font-weight: bold;
  }
  .math-teacher {
    text-align: center;
    position: absolute;
    top: 620px;
    left: 14px;
    font-size: 16px;
    width: 210px;
  }
  .math-tutor {
    text-align: center;
    position: absolute;
    top: 620px;
    right: 172px;
    font-size: 16px;
    width: 210px;
  }
}
.A8D {
  color: #005db3;
  background: url('../assets/a8d.png') no-repeat center;
  background-size: 100% 100%;
  .name {
    position: absolute;
    font-size: 32px;
    font-weight: bold;
    top: 330px;
    left: 120px;
    color: #005db3;
    text-align: center;
    width: 330px;
  }
  .rate {
    position: absolute;
    top: 475px;
    left: 373px;
    font-size: 22px;
    color: #005db3;
    font-weight: bold;
  }
  .math-teacher {
    text-align: center;
    position: absolute;
    top: 658px;
    left: 100px;
    font-size: 16px;
    width: 210px;
  }
  .math-tutor {
    position: absolute;
    top: 658px;
    right: 100px;
    width: 210px;
    font-size: 16px;
    text-align: center;
  }
}
.A8E {
  color: #005db3;
  background: url('../assets/a8e.png') no-repeat center;
  background-size: 100% 100%;
  .name {
    position: absolute;
    font-size: 32px;
    font-weight: bold;
    top: 330px;
    left: 120px;
    color: #005db3;
    text-align: center;
    width: 330px;
  }
  .rate {
    position: absolute;
    top: 475px;
    left: 373px;
    font-size: 22px;
    color: #005db3;
    font-weight: bold;
  }
  .math-teacher {
    text-align: center;
    position: absolute;
    top: 673px;
    left: 100px;
    font-size: 16px;
    width: 210px;
  }
  .math-tutor {
    position: absolute;
    top: 673px;
    right: 100px;
    width: 210px;
    font-size: 16px;
    text-align: center;
  }
}
.A2C {
  color: #005db3;
  background: url('../assets/a2c.png') no-repeat center;
  background-size: 100% 100%;
  .name {
    position: absolute;
    font-size: 32px;
    font-weight: bold;
    top: 302px;
    left: 60px;
    color: #005db3;
    text-align: center;
    width: 330px;
  }
  .rate {
    position: absolute;
    top: 446px;
    left: 280px;
    font-size: 22px;
    color: #005db3;
    font-weight: bold;
  }

  .math-teacher {
    text-align: center;
    position: absolute;
    top: 631px;
    left: 30px;
    font-size: 16px;
    width: 210px;
  }
  .math-tutor {
    position: absolute;
    top: 631px;
    right: 159px;
    width: 210px;
    font-size: 16px;
    text-align: center;
  }
}
.A3C {
  color: #005db3;
  background: url('../assets/a3c.png') no-repeat center;
  background-size: 100% 100%;
  .name {
    position: absolute;
    font-size: 32px;
    font-weight: bold;
    top: 322px;
    left: 130px;
    color: #005db3;
    text-align: center;
    width: 330px;
  }
  .rate {
    position: absolute;
    top: 466px;
    left: 293px;
    font-size: 22px;
    color: #005db3;
    font-weight: bold;
  }

  .math-teacher {
    text-align: center;
    position: absolute;
    top: 631px;
    left: 100px;
    font-size: 16px;
    width: 210px;
  }
  .math-tutor {
    position: absolute;
    top: 631px;
    right: 99px;
    width: 210px;
    font-size: 16px;
    text-align: center;
  }
}
.A3D {
  color: #005db3;
  background: url('../assets/a3d.png') no-repeat center;
  background-size: 100% 100%;
  .name {
    position: absolute;
    font-size: 32px;
    font-weight: bold;
    top: 322px;
    left: 130px;
    color: #005db3;
    text-align: center;
    width: 330px;
  }
  .rate {
    position: absolute;
    top: 466px;
    left: 293px;
    font-size: 22px;
    color: #005db3;
    font-weight: bold;
  }

  .math-teacher {
    text-align: center;
    position: absolute;
    top: 660px;
    left: 100px;
    font-size: 16px;
    width: 210px;
  }
  .math-tutor {
    position: absolute;
    top: 660px;
    right: 99px;
    width: 210px;
    font-size: 16px;
    text-align: center;
  }
}
.A3E {
  color: #005db3;
  background: url('../assets/a3e.png') no-repeat center;
  background-size: 100% 100%;
  .name {
    position: absolute;
    font-size: 32px;
    font-weight: bold;
    top: 322px;
    left: 130px;
    color: #005db3;
    text-align: center;
    width: 330px;
  }
  .rate {
    position: absolute;
    top: 466px;
    left: 293px;
    font-size: 22px;
    color: #005db3;
    font-weight: bold;
  }

  .math-teacher {
    text-align: center;
    position: absolute;
    top: 631px;
    left: 100px;
    font-size: 16px;
    width: 210px;
  }
  .math-tutor {
    position: absolute;
    top: 631px;
    right: 99px;
    width: 210px;
    font-size: 16px;
    text-align: center;
  }
}
.A4C {
  color: #005db3;
  background: url('../assets/a4c.png') no-repeat center;
  background-size: 100% 100%;
  .name {
    position: absolute;
    font-size: 32px;
    font-weight: bold;
    top: 293px;
    left: 40px;
    color: #005db3;
    text-align: center;
    width: 330px;
  }
  .rate {
    position: absolute;
    top: 434px;
    left: 261px;
    font-size: 22px;
    color: #005db3;
    font-weight: bold;
  }

  .math-teacher {
    text-align: center;
    position: absolute;
    top: 606px;
    left: 13px;
    font-size: 16px;
    width: 210px;
  }
  .math-tutor {
    position: absolute;
    top: 606px;
    right: 185px;
    width: 210px;
    font-size: 16px;
    text-align: center;
  }
}
.A4D {
  color: #005db3;
  background: url('../assets/a4d.png') no-repeat center;
  background-size: 100% 100%;
  .name {
    position: absolute;
    font-size: 32px;
    font-weight: bold;
    top: 273px;
    left: 40px;
    color: #005db3;
    text-align: center;
    width: 330px;
  }
  .rate {
    position: absolute;
    top: 415px;
    left: 257px;
    font-size: 22px;
    color: #005db3;
    font-weight: bold;
  }

  .math-teacher {
    text-align: center;
    position: absolute;
    top: 614px;
    left: 12px;
    font-size: 16px;
    width: 210px;
  }
  .math-tutor {
    position: absolute;
    top: 614px;
    right: 181px;
    width: 210px;
    font-size: 16px;
    text-align: center;
  }
}
.A5C {
  color: #005db3;
  background: url('../assets/a5c.png') no-repeat center;
  background-size: 100% 100%;
  .name {
    position: absolute;
    font-size: 32px;
    font-weight: bold;
    top: 300px;
    left: 127px;
    color: #005db3;
    text-align: center;
    width: 330px;
  }
  .rate {
    position: absolute;
    top: 442px;
    left: 380px;
    font-size: 22px;
    color: #005db3;
    font-weight: bold;
  }

  .math-teacher {
    text-align: center;
    position: absolute;
    top: 604px;
    left: 100px;
    font-size: 16px;
    width: 210px;
  }
  .math-tutor {
    position: absolute;
    top: 604px;
    right: 89px;
    width: 210px;
    font-size: 16px;
    text-align: center;
  }
}
.A6C {
  color: #005db3;
  background: url('../assets/a6c.png') no-repeat center;
  background-size: 100% 100%;
  .name {
    position: absolute;
    font-size: 32px;
    font-weight: bold;
    top: 340px;
    left: 127px;
    color: #005db3;
    text-align: center;
    width: 330px;
  }
  .rate {
    position: absolute;
    top: 480px;
    left: 372px;
    font-size: 22px;
    color: #005db3;
    font-weight: bold;
  }

  .math-teacher {
    text-align: center;
    position: absolute;
    top: 664px;
    left: 92px;
    font-size: 16px;
    width: 210px;
  }
  .math-tutor {
    position: absolute;
    top: 664px;
    right: 89px;
    width: 210px;
    font-size: 16px;
    text-align: center;
  }
}
.ma-temp {
  width: 842px;
  height: 595px;
  position: relative;
}
.major {
  background: url('../assets/major.png') no-repeat center;
  background-size: 100% 100%;
}
.special {
  background: url('../assets/special.png') no-repeat center;
  background-size: 100% 100%;
}
.chinese-name {
  position: absolute;
  top: 175px;
  left: 50%;
  transform: translate(-50%);
  font-size: 26px;
  font-weight: bold;
  color: #faa927;
}
.chinese-teacher {
  position: absolute;
  left: 35px;
  width: 324px;
  text-align: center;
  font-size: 20px;
  top: 407px;
  color: #523f22;
}
.chinese-tutor {
  position: absolute;
  width: 324px;
  top: 407px;
  right: 30px;
  text-align: center;
  font-size: 20px;
  color: #523f22;
}
.chinese-level {
  position: absolute;
  width: 220px;
  top: 287px;
  left: 223px;
  text-align: center;
  font-size: 23px;
  color: rgb(250, 169, 39);
}
</style>
