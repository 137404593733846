<template>
  <div>
    <template v-if="dataInfos">
      <div v-for="item in dataInfos" :key="item.uuid">
        <div
          class="read-report"
          v-if="
            originType === 'teacher' &&
            ((item.exerciseType !== 'SUBJECTIVE_EXERCISE' && item.exerciseType !== 'COMPLEX_SUBJECTIVE') ||
              (item.questionTopicType !== 'SUBJECTIVE_EXERCISE' && item.questionTopicType !== 'COMPLEX_SUBJECTIVE'))
          "
        >
          <div class="report-title">
            <InfoTitle :title="item.linkName" />
            <p v-text="item.linkAnswerType === 'QUESTS' ? '闯关模式' : '测试模式'"></p>
          </div>

          <p
            @click="onReportHistory(item.linkId)"
            v-if="item.exerciseType !== 'SUBJECTIVE_EXERCISE' || item.questionTopicType !== 'SUBJECTIVE_EXERCISE'"
            style="margin-top: 4px"
          >
            {{ subject === 'MATH' ? 'View historical reports >' : '查看历史报告 >' }}
          </p>
        </div>
        <div
          v-if="
            item.reportData &&
            ((item.exerciseType !== 'SUBJECTIVE_EXERCISE' && item.exerciseType !== 'COMPLEX_SUBJECTIVE') ||
              (item.questionTopicType !== 'SUBJECTIVE_EXERCISE' && item.questionTopicType !== 'COMPLEX_SUBJECTIVE'))
          "
        >
          <!-- v-if="item.reportData.answerRecord[0].questionType !== 'EXPLAN_CHOICE'" -->

          <MathReport
            v-if="subject === 'MATH'"
            :dataInfo="item || {}"
            @audioPlay="audioPlay"
            @clearAudio="clearAudio"
            :explainData="explainData"
            @explainResolution="explainResolution"
            :checkData="checkData"
            :parentaudio="parentaudio"
          />
          <PictureReaderReport
            v-else-if="item.exerciseType === 'READER_EXERCISE'"
            :dataInfo="item || {}"
            @audioPlay="audioPlay"
            @clearAudio="clearAudio"
            :explainData="explainData"
            @explainResolution="explainResolution"
            :checkData="checkData"
            :parentaudio="parentaudio"
          />
          <ReadReport
            v-else
            :dataInfo="item || {}"
            @audioPlay="audioPlay"
            @clearAudio="clearAudio"
            :explainData="explainData"
            @explainResolution="explainResolution"
            :checkData="checkData"
            :parentaudio="parentaudio"
          />
        </div>
        <div v-else>
          <InfoTitle :title="item.linkName" />
          <template v-if="originType === 'teacher'">
            <slot :datas="item"></slot>
          </template>
          <template v-else>
            <ClassDoReport :dataInfo="item || {}" :teacherId="teacherUuid" />
          </template>
        </div>
      </div>
    </template>
    <ReportHistoryOperation
      v-if="currentView === 'ReportHistoryOperation'"
      :originType="originType"
      :reportHistory="reportHistory"
      :historyType="historyType"
      :studentReview="studentReview"
      :studentId="studentId"
      :explainData="explainData"
      @explainResolution="explainResolution"
      :checkData="checkData"
      :subject="subject"
    >
      <template>
        <slot></slot>
      </template>
    </ReportHistoryOperation>
  </div>
</template>
<script>
import InfoTitle from '@/components/WkInfoTitle/InfoTitle';
import ReadReport from './ReadReport.vue';
import PictureReaderReport from './PictureReaderReport.vue';
import MathReport from './MathReport';
import ClassDoReport from './ClassDoReport';
import ReportHistoryOperation from './ReportHistoryOperation';

export default {
  components: { InfoTitle, ReadReport, ReportHistoryOperation, MathReport, ClassDoReport },
  props: {
    dataInfos: {
      type: Array,
      default: () => [],
    },
    reportHistory: {
      type: Array,
      default: () => [],
    },
    studentReview: {
      type: Object,
      default: () => {},
    },
    studentId: {
      type: String,
      default: '',
    },
    teacherId: {
      type: String,
      default: '',
    },
    courseScheduleId: {
      type: String,
      default: '',
    },
    batchCorrection: {
      type: Boolean,
    },
    explainData: {
      type: Object,
      default: () => {},
    },
    checkData: {
      type: Array,
      default: () => [],
    },
    originType: {
      type: String,
      default: '',
    },
    subject: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      // reportHistory: null,
      currentView: null,
      loadingBol: true,
      visible: true,
      // dataInfo: null,
      historyType: '',
      audio: null,
      parentaudio: '',
      studentUuid: this.studentId,
      teacherUuid: this.teacherId,
    };
  },
  methods: {
    explainResolution(id) {
      this.$emit('explainResolution', id);
    },
    onReportHistory(values) {
      this.clearAudio();
      this.currentView = 'ReportHistoryOperation';
      this.$emit('getLinkId', values);
      this.historyType = values;
    },
    handleTab(values) {
      this.studentUuid = values;
      this.initReport();
    },
    audioPlay(value) {
      if (!value.audio) {
        this.$message.warning('暂无朗读录音');
        return;
      }
      if (this.audio && this.parentaudio !== value.audio) {
        this.audio.pause();
        this.audio = null;
      }
      if (!this.audio) {
        this.audio = new Audio();
        this.audio.loop = false;
        this.audio.addEventListener(
          'ended',
          () => {
            this.audio.pause();
            this.audio = null;
            this.parentaudio = '';
          },
          false,
        );
        this.audio.src = value.audio;
        this.parentaudio = value.audio;
        this.audio.play();
      }
    },
    clearAudio() {
      // this.parentaudio = value;
      if (this.audio) {
        this.audio.pause();
        this.audio = null;
        this.parentaudio = '';
      }
    },
    callback() {},
    cancal() {
      this.currentView = null;
    },
    onClose() {
      this.loadingBol = false;
      this.$parent.cancal();
    },
    classOutInTime(value) {
      let theTime = Number(value);
      let middle = 0;
      let hour = 0;
      if (theTime > 60) {
        middle = parseInt(theTime / 60, 10);
        theTime = parseInt(theTime % 60, 10);
        if (middle > 60) {
          hour = parseInt(middle / 60, 10);
          middle = parseInt(middle % 60, 10);
        }
      }
      let result = '';
      if (parseInt(theTime, 10) > 0) {
        result = `${parseInt(theTime, 10)}秒`;
      }
      if (middle > 0) {
        result = `${parseInt(middle, 10)}分${result}`;
      }
      if (hour > 0) {
        result = `${parseInt(hour, 10)}时${result}`;
      }
      return result;
    },
    getPopupContainer(trigger) {
      return trigger.parentElement.parentElement;
    },
  },
};
</script>
<style lang="less">
.review-main {
  .ant-drawer-body {
    padding: 0;
  }
  .ant-tabs-content {
    overflow-y: auto;
    height: calc(100vh - 48px);
  }
  .ant-tabs .ant-tabs-left-content {
    padding: 24px;
  }
  .ant-tabs-nav {
    .ant-tabs-tab-active {
      color: #000;
      background-color: #fff;
    }
    .ant-tabs-tab {
      width: 150px;
      text-align: center;
      padding: 16px 24px;
      text-align: left;
      margin: 0;
    }
    .ant-tabs-ink-bar {
      background-color: #fff;
    }
  }
  .ant-tabs-nav-wrap {
    background-color: #f7f7f7;
  }
  .ant-tabs-left-content {
    border-left: none;
  }
  .tabs-main {
    height: calc(100vh - 55px);
    overflow-y: auto;
  }
  .read-report {
    display: flex;
    justify-content: flex-start;
    p:hover {
      cursor: pointer;
    }
  }
  .report-title {
    display: flex;
    justify-content: flex-start;
    p {
      height: 28px;
      display: inline-block;
      padding: 2px 10px;
      font-size: 14px;
      color: #009cff;
      border: 1px solid #009cff;
      border-radius: 4px;
      margin: 0 8px;
    }
  }
  .empty {
    height: 70vh;
    font-size: 16px;
    color: #999;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .loading {
    width: 80%;
    height: 100%;
    background-color: rgba(255, 255, 255);
    display: flex;
    align-items: center;
    justify-content: center;
    // padding: 20% 0 0 0;
    text-align: center;
    font-size: 60px;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 9999;
  }
}
</style>
