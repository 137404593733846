<template>
  <div style="margin-top: 15px" v-if="combinedList.length && combinedList.length > 0">
    <div class="title">
      <span>{{ title }}</span>
    </div>

    <div class="student-wrap">
      <div v-for="item in combinedList" :key="item.id" class="student-item">
        <div :class="{ cursor: !formInfoData.showcourseSection }" class="student-info">
          <img :src="defaultImg" alt="" class="handler-img" />
          <div v-if="detailMode === 'drawer'" class="student-name-box">
            <p @click="lookStudentInfo" class="student-name" :class="{ cursor: !formInfoData.showcourseSection }">
              {{ item.name }} ({{ item.code }})
            </p>
            <p style="margin-top: 2px" @click="lookStudentInfo">查看学生资料</p>
          </div>
          <div v-if="detailMode === 'jump'" class="student-name-box">
            <p @click="onGetStudentId(item)" class="student-name" :class="{ cursor: !formInfoData.showcourseSection }">
              {{ item.name }} ({{ item.code }})>
            </p>
          </div>
          <div class="tag-wrap" v-if="item.subscribe">
            <a-tag color="green">订阅</a-tag>
          </div>
        </div>
        <div style="display: flex">
          <AssociatedTasks
            type="other"
            :totalNum="formInfoData.currentStudentOtherTaskNumber"
            :taskList="formInfoData.currentStudentOtherTask ? [formInfoData.currentStudentOtherTask] : []"
            style="margin-right: 10px"
            v-if="formInfoData.currentStudentOtherTaskNumber != null"
          />
          <AssociatedTasks
            type="familyOther"
            :totalNum="formInfoData.otherStudentTaskNumber"
            :taskList="formInfoData.otherStudentTasks || []"
            v-if="formInfoData.otherStudentTaskNumber != null"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import AssociatedTasks from './AssociatedTasks.vue';
import defaultImg from '../assets/service_pic_head.png';

export default {
  components: { AssociatedTasks },
  props: {
    formInfoData: {
      type: Object,
    },
    detailMode: {
      type: String,
      default: 'jump', // jump:跳转到学生详情页，drawer:打开抽屉
    },
    title: {
      type: String,
      default: '学生',
    },
  },
  data() {
    return {
      defaultImg,
    };
  },
  computed: {
    combinedList() {
      if (this.formInfoData?.allStudentInfoList?.length) {
        return this.formInfoData.allStudentInfoList.map((item) => ({
          id: item.uuid,
          studentId: item.uuid,
          code: item.code,
          name: item.fullName,
          subscribe: item.subscribe,
        }));
      }

      return this.formInfoData?.studentArray?.map?.((item) => ({ ...item, subscribe: false })) || [];
    },
  },
  methods: {
    onGetStudentId(data) {
      this.$emit('onGetStudentId', data);
    },
    lookStudentInfo() {
      this.$emit('lookStudentInfo');
    },
  },
};
</script>

<style lang="less" scoped>
.cursor {
  cursor: pointer;
  color: #43d186 !important;
}
.title {
  font-size: 20px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #333333;
  line-height: 28px;
  margin-bottom: 20px;
}
.title::before {
  content: '';
  display: block;
  float: left;
  margin: 4px 12px 0 0;
  width: 4px;
  height: 20px;
  background-color: #43d186;
  border-radius: 0px 6px 6px 0px;
}

.student-item {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.student-item p {
  font-size: 14px;
  font-weight: 400;
  color: #999999;
  line-height: 20px;
}
.student-item img {
  width: 42px;
  height: 42px;
  border-radius: 50%;
  margin-right: 7px;
}
.student-item .student-name {
  font-size: 16px;
  font-weight: 600;
  color: #333333;
  line-height: 22px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.student-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 4px;
}
.student-item p {
  font-size: 14px;
  font-weight: 400;
  color: #999999;
  line-height: 20px;
}
.student-item img {
  width: 42px;
  height: 42px;
  border-radius: 50%;
  margin-right: 7px;
}
.student-item .student-name {
  font-size: 16px;
  font-weight: 600;
  color: #333333;
}
.student-name-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-right: 4px;
}
.student-info {
  display: flex;
  align-items: center;
}
.student-wrap {
  margin-bottom: 12px;
  display: flex;
  flex-direction: column;
}
</style>
