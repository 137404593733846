<template>
  <div :class="subDetailType">
    <div class="task-form">
      <p class="form-name">
        <a-tooltip trigger="click" :overlayStyle="{ maxWidth: '500px' }">
          <template slot="title">
            <div class="task-explainTitleTC">
              <span class="task-titleTxt">任务说明</span>
              <!-- <span class="task-explain" @click="lookWord">查看文档说明</span> -->
            </div>
            <div>
              【完成方式】电话/文字沟通<br />
              【完成时效】<br />
              【操作步骤】<br />
              沟通前：<br />
              a.询问授课老师旷课原因<br />
              b.确认授课老师该时段之后能否正常出勤<br />
              沟通中： <br />
              a.安抚家长情绪，委婉告知家长授课老师旷课原因，本节课程课时系统将自动归还<br />
              b.询问学员何时方便进行补课，及时跟上课程进度<br />
              【注意事项】<br />
              请及时填写“授课老师旷课”的跟进记录以完成本任务
            </div>
          </template>
          <i class="explainTC"></i>
        </a-tooltip>
        {{ formInfoData.question }}
        <span class="pending-status" v-if="!formInfoData.finished">待处理</span>
        <span class="success-status" v-if="formInfoData.finished && !formInfoData.deleted">已完成</span>
        <span class="refuse-status" v-if="formInfoData.finished && formInfoData.deleted">已拒绝</span>
      </p>
      <div class="form-teacher">
        <img :src="defaultImg" alt="" />
        <span
          >{{ formInfoData.startUserName ? formInfoData.startUserName : '系统'
          }}<span v-if="formInfoData.userRole">（ {{ formInfoData.userRole }} ）</span></span
        >
        提交于
        <span>{{ $moment(formInfoData.startTime).utcOffset(8).format('YYYY年MM月DD日 HH:mm') }}</span>
        | 最迟解决时间

        <span :class="formInfoData.expireFlag ? 'dueDate' : ''">{{ formInfoData.dueDate }}</span>
      </div>
      <div class="form-des">
        <p>请尽快及时与学员反馈授课老师旷课情况，并完成该任务的跟进记录</p>
      </div>
    </div>
    <RelatedStudents :formInfoData="formInfoData" @onGetStudentId="onGetStudentId" />

    <div class="courseInfo" v-if="formInfoData.courseInfo" style="border-top: 1px solid #e9e9e9">
      <div class="title">
        <span>授课老师旷课课节</span>
      </div>

      <div class="course-info">
        <router-link
          v-if="formInfoData.showcourseSection"
          class="course-name"
          :to="{ path: '/courseDetails', query: { uuid: formInfoData.courseInfo.uuid } }"
        >
          {{ formInfoData.courseInfo.courseName }}
          {{ formInfoData.courseInfo.scheduleName }}
        </router-link>
        <span v-if="!formInfoData.showcourseSection" class="course-name"
          >{{ formInfoData.courseInfo.courseName }} {{ formInfoData.courseInfo.scheduleName }}</span
        >
        <div class="course-type">
          <span>旷课时间：{{ formInfoData.courseInfo.startTime }}</span>
        </div>
        <div class="course-type">
          <span>授课老师：{{ formInfoData.courseInfo.teacherName }}</span>
        </div>
        <div class="course-type">
          <span>{{ formInfoData.courseInfo.courseSectionName }}</span> |
          <span>{{ formInfoData.courseInfo.classType === 'PRIVATE' ? '私教课' : '班课' }}</span> |
          <span>学生：{{ formInfoData.courseInfo.studentName || '无' }}</span> |
          <span>班级：{{ formInfoData.courseInfo.formClassName }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import defaultImg from '../assets/service_pic_head.png';
import RelatedStudents from './RelatedStudents.vue';

export default {
  props: {
    formInfoData: {
      type: Object,
    },
    subDetailType: {
      // 区分是老师还是班主任，班主任主题色是绿色
      type: String,
      default: 'teacher',
    },
  },
  components: { RelatedStudents },
  data() {
    return {
      cursor: 'cursor',
      defaultImg,
      pictures: [],
    };
  },
  methods: {
    lookWord() {},
    onGetStudentId(data) {
      this.$emit('onGetStudentId', { name: data.name, id: data.studentId });
    },
    onPictures(datas) {
      this.$emit('onPictures', datas);
    },
    playCourse() {
      this.$emit('playCourse');
    },
  },
};
</script>

<style lang="less" scoped>
.cursor {
  cursor: pointer;
  color: #009cff !important;
}
.classAdmin {
  .cursor {
    cursor: pointer;
    color: #43d186 !important;
  }

  .title::before {
    background-color: #43d186;
  }
}
.title {
  font-size: 20px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #333333;
  line-height: 28px;
  margin-bottom: 20px;
}
.title::before {
  content: '';
  display: block;
  float: left;
  margin: 4px 12px 0 0;
  width: 4px;
  height: 20px;
  background-color: #009cff;
  border-radius: 0px 6px 6px 0px;
}
.task-form {
  border-bottom: 1px solid #eee;
}

.form-name span {
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  padding: 2px 10px;
  border-radius: 6px;
  margin: 0 10px;
}
.pending-status {
  color: #ffbf00;
  background-color: rgba(255, 191, 0, 0.09);
}
.success-status {
  color: #00cf64;
  background-color: rgba(0, 207, 100, 0.09);
}
.refuse-status {
  color: #ff5353;
  background-color: rgba(255, 83, 83, 0.09);
}
.form-teacher {
  font-size: 16px;
  font-weight: 400;
  color: #999;
  line-height: 22px;
  margin-bottom: 15px;
}
.form-teacher .dueDate {
  color: #ff5454;
}
.form-teacher span {
  color: #333;
}
.form-teacher img {
  width: 18px;
  height: 18px;
  border-radius: 50%;
  margin-right: 10px;
}
.form-des {
  font-size: 16px;
  font-weight: 400;
  color: #333333;
  line-height: 22px;
  margin-bottom: 15px;
}
.form-imgs {
  margin-bottom: 20px;
}
.form-imgs img {
  width: 80px;
  height: 80px;
  border-radius: 4px;
  margin-right: 10px;
}
.courseInfo {
  padding: 20px 0;
}
.course-info {
  padding: 20px 16px;
  background: linear-gradient(180deg, #ecf7ff 0%, #f7fcff 100%);
  border-radius: 20px;
  position: relative;
}
.course-name {
  font-size: 16px;
  font-weight: 600;
  color: #009cff;
  line-height: 22px;
  margin-bottom: 10px;
}
.course-info a:hover {
  text-decoration: underline;
}
.course-type {
  font-size: 14px;
  font-weight: 400;
  color: #999999;
  line-height: 18px;
  margin-top: 10px;
}

.student-item {
  display: flex;
  justify-content: space-between;
}
.student-item p {
  font-size: 14px;
  font-weight: 400;
  color: #999999;
  line-height: 20px;
}
.student-item img {
  width: 42px;
  height: 42px;
  border-radius: 50%;
  margin-right: 7px;
}
.student-item .student-name {
  font-size: 16px;
  font-weight: 600;
  color: #333333;
  line-height: 22px;
  width: 300px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.student-wrap {
  margin-bottom: 15px;
}
.playCourse {
  background-color: #fff;
  color: #009cff;
  padding: 4px 8px;
  border-radius: 10px;
  cursor: pointer;
  position: absolute;
  right: 5px;
  top: 10px;
}
.explainTC {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: url('../assets/explain.png') no-repeat center;
  background-size: 100%;
  cursor: pointer;
}
.task-explainTitleTC {
  padding-bottom: 5px;
  display: inline-flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}
.task-titleTxt {
  font-size: 18px;
  font-weight: 600;
}
.task-explain {
  color: #2eaaf9;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
}
.form-name {
  font-size: 20px;
  font-weight: 600;
  color: #333333;
  line-height: 28px;
  margin-bottom: 15px;
  display: flex;
  align-items: center;
}
</style>
