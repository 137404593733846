<!--
 * @Description: 
 * @Author: wling
 * @Date: 2023-07-20 16:52:32
 * @LastEditTime: 2023-07-27 14:52:17
 * @LastEditors: wling
-->
<template>
  <div>
    <a-popover :placement="position" :overlayStyle="{ width: '460px' }" v-if="totalNum > 0">
      <template slot="content">
        <div class="task-title">
          <span>{{ title }}</span>
          <span class="tip">注：仅支持查看处理人是我的待办</span>
        </div>
        <div class="task-list">
          <div class="task-item" v-for="item in taskList" :key="item.studentId">
            <div class="student-name">{{ item.studentName }}({{ item.studentCode }})</div>
            <div
              class="link"
              v-for="taskItem in item.tasks"
              :key="taskItem.taskId"
              @click="onTaskDetail(taskItem.taskId)"
            >
              {{ taskItem.name }}（截止时间：{{ taskItem.dueDate }}）
            </div>
          </div>
        </div>
      </template>
      <span style="cursor: default">{{ title }}> <a-badge :count="totalNum" /></span>
    </a-popover>
    <div v-else>
      <span>
        {{ title }}>
        <a-badge
          count="0"
          showZero
          :number-style="{
            backgroundColor: '#ddd',
            color: '#fff',
          }"
        />
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AssociatedTasks',
  props: {
    type: {
      type: String,
      required: true,
      validator(value) {
        return ['other', 'familyOther'].includes(value);
      },
    },
    totalNum: {
      type: Number,
      default: 0,
    },
    taskList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      title: this.type === 'other' ? '学员其他待办' : '同家庭学员待办',
      position: this.type === 'other' ? 'bottom' : 'bottomRight',
    };
  },
  methods: {
    onTaskDetail(id) {
      window.open(`/mainHome?taskId=${id}`);
    },
  },
};
</script>

<style scoped lang="less">
.task-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 10px;
  .tip {
    font-size: 12px;
  }
}
.task-list {
  max-height: 290px;
  overflow-y: auto;
}
.task-item {
  background: #f4f4f4;
  margin-bottom: 10px;
  line-height: 26px;
  padding: 10px;

  .student-name {
    font-weight: bold;
    font-weight: 15px;
    margin-bottom: 10px;
  }
}
.link {
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  &:hover {
    color: #04cb94;
    text-decoration: underline;
  }
}
.storybook-button--primary {
  color: white;
  background-color: #1ea7fd;
}
.storybook-button--secondary {
  color: #333;
  background-color: transparent;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 0px 0px 1px inset;
}
.storybook-button--small {
  font-size: 12px;
  padding: 10px 16px;
}
.storybook-button--medium {
  font-size: 14px;
  padding: 11px 20px;
}
.storybook-button--large {
  font-size: 16px;
  padding: 12px 24px;
}
</style>
