<template>
  <div>
    <!-- <div v-for="(item, index) in reportAllData" :key="index"> -->
    <div class="report-main" v-if="topicData">
      <div class="report-title">
        <div class="info-title" :class="{ 'title-ellipsis': is_expand }" :title="topicData.title">
          {{ topicData.title }}
        </div>
        <div class="tags" :title="topicData.tag" v-if="topicData.tag">
          {{ topicData.tag }}
        </div>
      </div>
      <p>
        提交时间: <span>{{ linkInformation.whenCreated }}</span>
      </p>
      <p>{{ topicData.questionName }}</p>
      <p class="expand-title" @click="onExpand(topicData.questionId)">
        {{ is_expand ? '展开题目详情' : '收起题目详情' }}
      </p>
      <div v-if="!is_expand">
        <!-- 各种天马行空的属性名称 -->
        <div class="img-type title-info" v-if="topicData.questionPicture">
          <img :src="topicData.questionPicture" alt="主观题图片" :preview="topicData.questionPicture" />
          <audio :src="titleInfo.audio" controls="controls" preload></audio>
        </div>
        <div class="img-type title-info" v-if="titleInfo.audio">
          <audio :src="titleInfo.audio" controls="controls" preload></audio>
        </div>
        <div class="img-type title-info" v-if="titleInfo">
          <!-- 图片数组 -->
          <div
            class="teacher-audio pictures"
            v-if="typeof titleInfo.pictures !== 'string' && titleInfo.pictures && titleInfo.pictures.length !== 0"
          >
            <div v-for="i in titleInfo.pictures" :key="i">
              <img :src="`${i}?x-oss-process=image/quality,q_90/resize,w_160`" :preview="i" />
            </div>
          </div>
          <!-- 图片 -->
          <div
            class="teacher-audio pictures"
            v-if="typeof titleInfo.pictures === 'string' && titleInfo.pictures && !titleInfo.pictures.includes('.pdf')"
          >
            <img
              :src="`${titleInfo.pictures}?x-oss-process=image/quality,q_90/resize,w_160`"
              :preview="titleInfo.pictures"
            />
          </div>
          <div
            class="teacher-audio pictures"
            v-if="typeof titleInfo.picture === 'string' && titleInfo.picture && !titleInfo.picture.includes('.pdf')"
          >
            <img
              :src="`${titleInfo.picture}?x-oss-process=image/quality,q_90/resize,w_160`"
              :preview="titleInfo.picture"
            />
          </div>
          <!-- pdf -->
          <div
            class="teacher-audio pictures"
            v-if="typeof titleInfo.pictures === 'string' && titleInfo.pictures && titleInfo.pictures.includes('.pdf')"
          >
            <div @click="downloadFile(titleInfo.pictures)">
              <a-icon type="link" />
              <span controls="controls">
                {{
                  decodeURIComponent(titleInfo.pictures).substring(
                    decodeURIComponent(titleInfo.pictures).lastIndexOf('/') + 1,
                  )
                }}
              </span>
            </div>
          </div>
          <!-- 文件 -->
          <div
            class="teacher-audio pictures"
            v-if="
              typeof titleInfo.attachment === 'string' && titleInfo.attachment && titleInfo.attachment.includes('.pdf')
            "
          >
            <div @click="downloadFile(titleInfo.attachment)">
              <a-icon type="link" />
              <span controls="controls">
                {{
                  decodeURIComponent(titleInfo.attachment).substring(
                    decodeURIComponent(titleInfo.attachment).lastIndexOf('/') + 1,
                  )
                }}
              </span>
            </div>
          </div>
          <!-- 字帖 -->
          <div class="teacher-audio pictures" v-if="titleInfo.copybooks">
            <p>字帖文字：{{ titleInfo.copybooks.character.join(',') }}</p>
          </div>
          <div class="teacher-audio" v-if="titleInfo.audios && titleInfo.audios.length !== 0">
            <div v-for="i in titleInfo.audios" :key="i">
              <audio :src="i" controls="controls" preload></audio>
            </div>
          </div>
          <!-- 视频 -->
          <div class="teacher-video" v-if="titleInfo.videos && titleInfo.videos.length !== 0">
            <div v-for="i in titleInfo.videos" :key="i">
              <video :src="i" controls="controls"></video>
            </div>
          </div>

          <div class="teacher-video" v-if="titleInfo.video">
            <video :src="titleInfo.video" controls="controls"></video>
          </div>

          <div class="teacher-file" v-if="titleInfo.files && titleInfo.files.length !== 0">
            <div v-for="i in titleInfo.files" :key="i" @click="downloadFile(i)">
              <a-icon type="link" />
              <span controls="controls">
                {{ decodeURIComponent(i).substring(decodeURIComponent(i).lastIndexOf('/') + 1) }}
              </span>
            </div>
          </div>
        </div>
      </div>
      <!-- <div v-if="!is_expand">
        <div class="img-type title-info" v-if="topicData.questionPicture">
          <img :src="topicData.questionPicture" alt="主观题图片" :preview="topicData.questionPicture" />
          <audio :src="titleInfo.audio" controls="controls" preload></audio>
        </div>
        <div class="img-type title-info" v-if="titleInfo">
          <div
            class="teacher-audio pictures"
            v-if="typeof titleInfo.pictures !== 'string' && titleInfo.pictures && titleInfo.pictures.length !== 0"
          >
            <div v-for="i in titleInfo.pictures" :key="i">
              <img :src="`${i}?x-oss-process=image/quality,q_90/resize,w_160`" :preview="i" />
            </div>
          </div>
          <div
            class="teacher-audio pictures"
            v-if="typeof titleInfo.pictures === 'string' && titleInfo.pictures && !titleInfo.pictures.includes('.pdf')"
          >
            <img
              :src="`${titleInfo.pictures}?x-oss-process=image/quality,q_90/resize,w_160`"
              :preview="titleInfo.pictures"
            />
          </div>

          <div
            class="teacher-audio pictures"
            v-if="
              typeof titleInfo.attachment === 'string' && titleInfo.attachment && titleInfo.attachment.includes('.pdf')
            "
          >
            <div @click="downloadFile(titleInfo.attachment)">
              <a-icon type="link" />
              <span controls="controls">
                {{
                  decodeURIComponent(titleInfo.attachment).substring(
                    decodeURIComponent(titleInfo.attachment).lastIndexOf('/') + 1,
                  )
                }}
              </span>
            </div>
          </div>
          <div class="teacher-audio pictures" v-if="titleInfo.copybooks">
            <p>字帖文字：{{ titleInfo.copybooks.character.join(',') }}</p>
          </div>
          <div class="teacher-audio" v-if="titleInfo.audios && titleInfo.audios.length !== 0">
            <div v-for="i in titleInfo.audios" :key="i">
              <audio :src="i" controls="controls" preload></audio>
            </div>
          </div>
          <div class="teacher-video" v-if="titleInfo.videos && titleInfo.videos.length !== 0">
            <div v-for="i in titleInfo.videos" :key="i">
              <video :src="i" controls="controls"></video>
            </div>
          </div>
          <div class="teacher-file" v-if="titleInfo.files && titleInfo.files.length !== 0">
            <div v-for="i in titleInfo.files" :key="i" @click="downloadFile(i)">
              <a-icon type="link" />
              <span controls="controls">
                {{ decodeURIComponent(i).substring(decodeURIComponent(i).lastIndexOf('/') + 1) }}
              </span>
            </div>
          </div>
        </div>
      </div> -->
      <div class="student-sumbit">
        <p>学生提交内容</p>

        <div class="img-type" v-if="topicData.answerPictures">
          <template>
            <div
              v-for="(i, index) in editImageArray"
              :key="index"
              class="universal-img"
              @mouseover="selectImg(i.newImage)"
              @mouseout="selectEle = ''"
            >
              <img :src="`${i.newImage}?x-oss-process=image/quality,q_90/resize,w_560`" :key="index" preview="3" />
            </div>
          </template>
        </div>

        <div class="teacher-audio" v-if="topicData.answerAudios">
          <div v-for="i in topicData.answerAudios" :key="i">
            <audio :src="i" controls="controls" preload></audio>
          </div>
        </div>
        <div class="teacher-audio" v-if="topicData.answerAudio">
          <audio :src="topicData.answerAudio" controls="controls" preload></audio>
        </div>
        <div class="teacher-video" v-if="topicData.answerVideos">
          <div v-for="i in topicData.answerVideos" :key="i">
            <video :src="i" controls="controls"></video>
          </div>
        </div>
        <div class="teacher-file" v-if="topicData.answerFiles">
          <div v-for="i in topicData.answerFiles" :key="i" @click="downloadFile(i)">
            <a-icon type="link" />
            <span controls="controls">
              {{ decodeURIComponent(i).substring(decodeURIComponent(i).lastIndexOf('/') + 1) }}
            </span>
          </div>
        </div>
        <div class="teacher-text" v-if="topicData.text">
          <p><span>文本内容：</span>{{ topicData.text }}</p>
        </div>
      </div>
      <div
        class="teacher-comment"
        v-if="submitData || linkInformation.homeworkReviews.some((i) => i.questionId === topicData.questionId)"
      >
        <div v-for="(i, index) in submitData || linkInformation.homeworkReviews" :key="index">
          <template v-if="i.questionId === topicData.questionId">
            <div>
              <label>老师评分：</label>
              <a-rate v-model="i.start" disabled />
            </div>
            <div class="teacher-evaluation">
              <label>老师评语：</label>
              <div>{{ i.evaluation || '暂无评语' }}</div>
            </div>
            <div class="teacher-audio" v-if="i.audioUrl">
              <audio :src="i.audioUrl" controls="controls" preload></audio>
            </div>
            <!-- <div v-if="linkInformation.reportData.answerRecord[0].questionType === 'GENERAL_SUBJECTIVE'"> -->
            <div class="teacher-image" v-if="i.imageUrl.length !== 0">
              <img v-for="item in i.imageUrl" :key="item" :src="item" alt="" preview="4" />
            </div>
            <div class="teacher-image" v-if="i.videoUrl.length !== 0">
              <video v-for="item in i.videoUrl" :key="item" :src="item" alt="" controls />
            </div>
            <div v-if="i.fileUrl.length !== 0">
              <span class="teafileName" v-for="item in i.fileUrl" :key="item" alt="" @click="downloadFile(item)">
                <a-icon type="link" />
                <span controls="controls">
                  {{ decodeURIComponent(item).substring(decodeURIComponent(item).lastIndexOf('/') + 1) }}
                </span>
              </span>
            </div>
            <!-- </div> -->
          </template>
        </div>
      </div>
      <div v-else class="teacher-comment">
        <template v-if="topicData.answerStatus !== 'NORMAL'">
          <div class="empty">
            <p>学生还未提交作业</p>
            <img src="@/assets/icons/pic_emptypage.svg" alt="" />
          </div>
        </template>
      </div>
      <div v-if="!reportItem" class="empty">
        <p>学生还未提交作业</p>
        <img src="@/assets/icons/pic_emptypage.svg" alt="" />
      </div>
    </div>
    <!-- </div> -->
  </div>
</template>
<script>
import moment from 'moment';

export default {
  components: {},
  props: {
    reportItem: {
      type: Object,
      default: () => {},
    },
    // 环节信息
    linkInformation: {
      type: Object,
      default: () => {},
    },
    teacherId: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      topicData: null,

      moment,
      isBol: true,
      form: this.$form.createForm(this, { name: 'coordinated' }),
      startValues: 0,
      voiceUrl: null,
      is_submit: false,
      is_expand: true,
      is_showVoice: false,
      reportIndex: 0,
      isCarousel: false,
      pictures: [],
      isUp: false,
      imageUrl: null,
      imageArray: [],
      videoUrl: null,
      videoArray: [],
      fileUrl: null,
      fileArray: [],
      fileName: '',
      isUpLoad: false,
      isSize: false,
      currentView: null,
      imgUrl: '',
      selectEle: '',
      editImageArray: [],
      editImageArrayOne: [],
      loading: false,
      newPics: [],
      titleInfo: null,
      showPdf: false,
      pdfUrl: '',
      imgIndex: 0,
      isNum: false,
      submitData: null,
      tagEnum: { BONUS_QUESTION: '附加题' },
    };
  },
  mounted() {
    this.topicData = this.reportItem;
    let editImg = [];
    if (this.linkInformation.homeworkReviews.some((i) => i.questionId === this.topicData.questionId)) {
      editImg = this.linkInformation.homeworkReviews.find((i) => i.questionId === this.topicData.questionId)?.data
        ?.editImage;
    } else {
      editImg = this.reportItem.answerPictures || [];
    }

    const o = this.linkInformation.homeworkReviews.find((i) => i.questionId === this.topicData.questionId)?.data
      ?.editImageSource;
    this.editImageSource = this.submitData?.[0]?.data?.editImageSource || o;

    this.$nextTick(() => {
      this.$previewRefresh();
    });
    if (editImg && editImg.length !== 0) {
      editImg.forEach((item) => {
        this.editImageArray.push({
          oldImage: item.oldImage || item,
          newImage: item.newImage || item,
          // oldImage: item,
          // newImage: item,
        });
        // this.editImageArrayOne.push(item);
      });
    }
  },
  methods: {
    editImage(data, index) {
      this.imgIndex = index;
      this.currentView = 'imagesKonva';
      this.imgUrl = data;
    },
    selectImg(item) {
      this.selectEle = item;
    },
    downloadFile(fileUrl) {
      // pdf。word支持在线预览，其他格式保留原来的下载查看
      const fileType = fileUrl.substring(fileUrl.lastIndexOf('.') + 1);
      let type = '';
      if (fileType === 'doc' || fileType === 'docx' || fileType === 'pdf') {
        if (fileType === 'pdf') {
          type = 'application/pdf';
        } else if (fileType === 'doc' || fileType === 'docx') {
          type = 'application/msword';
        }
        const alink = document.createElement('a');
        alink.target = '_blank';
        alink.href = `${fileUrl}?response-content-type=${type}`;
        alink.click();
      } else {
        this.downloadFileOther(fileUrl);
      }
    },

    downloadFileOther(fileUrl) {
      const fileName = fileUrl.substring(fileUrl.lastIndexOf('/') + 1);

      const url2 = fileUrl.replace(/\\/g, '/');
      const xhr = new XMLHttpRequest();
      xhr.open('GET', url2, true);
      xhr.responseType = 'blob';
      // xhr.setRequestHeader('Authorization', 'Basic a2VybWl0Omtlcm1pdA==');
      xhr.onload = () => {
        if (xhr.status === 200) {
          this.saveAs(xhr.response, fileName);
        }
      };
      xhr.send();
    },
    saveAs(data, name) {
      const urlObject = window.URL || window.webkitURL || window;
      const blob = new Blob([data]);
      const aTag = document.createElement('a');
      aTag.href = urlObject.createObjectURL(blob);
      aTag.download = name;
      aTag.click();
    },
    delFile(type, data) {
      this[type] = this[type].filter((item) => item !== data);
    },

    onPictures(datas) {
      this.pictures = [];
      this.isCarousel = true;
      this.pictures = datas;
    },
    onClose() {
      this.isCarousel = false;
      this.showPdf = false;
    },

    cancal() {
      this.is_showVoice = false;
      this.currentView = null;
    },

    onExpand(questionId) {
      const data = {
        linkId: this.linkInformation.linkId,
        questionId,
        courseScheduleId: this.linkInformation.courseScheduleId,
        courseSectionId: this.linkInformation.courseSectionId,
      };
      if (!this.titleInfo) {
        this.$post(process.env.VUE_APP_API_IM_URL, `/api/student/link/question`, data).then((res) => {
          if (
            res.data.questionTopicType === 'SUBJECTIVE_EXERCISE' ||
            res.data.questionTopicType === 'COMPLEX_SUBJECTIVE'
          ) {
            this.titleInfo = res.data.contentOption;
          } else {
            this.titleInfo = {
              picture: res.data?.contentOption?.picture,
              audio: res.data?.audio,
            };
          }
          this.$previewRefresh();
          this.is_expand = !this.is_expand;
        });
      } else {
        this.is_expand = !this.is_expand;
      }
    },
  },
};
</script>
<style lang="less">
.report-main {
  padding: 10px 20px;
  border-radius: 10px;
  border: 1px solid #eee;
  margin-bottom: 20px;
  p {
    font-size: 16px;
    color: #999;
    margin-bottom: 10px;
    span {
      color: #333;
      margin-right: 40px;
    }
  }
  .report-name {
    color: #333;
    font-size: 18px;
    font-weight: 600;
  }
  .report-title {
    display: flex;
    justify-content: space-between;
    color: #333;
    font-size: 18px;
    font-weight: 600;
    .tags {
      width: 20%;
      height: 26px;
      font-weight: normal;
      font-size: 14px;
      text-align: center;
      border: 1px solid rgba(151, 151, 151, 0.3);
      border-radius: 6px;
      padding: 2px 5px;
      margin: 0;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .info-title {
      display: inline-block;
      width: 100%;
    }
    .title-ellipsis {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
  .expand-title {
    font-size: 14px;
    color: #009cff;
    &:hover {
      cursor: pointer;
    }
  }
  .img-type {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    padding: 0 0 20px 0;
    img {
      width: 160px;
      height: 90px;
      margin: 5px 10px 0 0;
    }
    .close {
      width: 14px;
      height: 14px;
    }
  }
  .title-info {
    img {
      margin-bottom: 20px;
    }
    flex-direction: column;
    .teacher-audio,
    .teacher-video,
    .teacher-file {
      cursor: pointer;
      margin: 0 0 20px 0;
    }
  }
  .student-sumbit {
    padding-bottom: 20px;
    border-bottom: 1px solid #eee;
    p {
      font-size: 18px;
      font-weight: 600;
      color: #333;
      line-height: 24px;
      margin: 15px 0;
    }
  }
  .teacher-comment {
    padding-top: 20px;
    label {
      font-size: 18px;
      font-weight: 600;
      color: #333;
      line-height: 40px;
    }
    textarea {
      border-radius: 6px;
    }
    .ant-form-item-children {
      display: flex;
      justify-content: space-between;
    }
  }
  .teacher-evaluation {
    display: flex;
    justify-content: flex-start;
    align-content: center;
    margin: 20px 0;
    label {
      line-height: 20px;
    }
    div {
      width: 80%;
      font-size: 16px;
      color: #333;
    }
  }
  .teacher-audio {
    audio {
      height: 22px;
    }
  }
  .pictures {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
  }
  .teafileName {
    color: #009cff;
    &:hover {
      cursor: pointer;
    }
  }

  .teacher-video {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin-top: 20px;
    video {
      width: 160px;
      height: 90px;
      margin: 5px;
    }
  }

  .teacher-file {
    cursor: pointer;
    margin-top: 30px;
  }
  .teacher-text {
    p {
      span {
        color: #666;
        margin-right: 0;
      }
      margin: 0;
      font-size: 16px;
      line-height: 22px;
      font-weight: normal;
    }
  }
  .audio-main {
    // height: 22px;
    display: flex;
    justify-content: flex-start;
    // align-content: center;
    align-items: center;
    i {
      font-size: 22px;
    }
    audio {
      height: 22px;
    }
    img {
      width: 20px;
      height: 20px;
    }
  }
  .ant-btn-primary {
    color: #fff;
    background-color: #009cff;
  }
  .empty {
    height: 200px !important;
    font-size: 16px;
    color: #999;
    padding-top: 1%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .audiocls {
    width: 40px;
    height: 40px;
    border: 1px solid #eee;
    border-radius: 50%;
    text-align: center;
    line-height: 40px;
  }
  .submitCls {
    border-radius: 10px;
  }
  .ant-upload-list-picture {
    display: none;
  }
  .filemain {
    .universal-file {
      display: flex;
      align-items: center;
      p {
        margin: 5px 0;
      }
    }
    .image-main {
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
    }
    .img-main {
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
    }
    .flieName {
      font-size: 16px;
      color: #333;
      border: 1px solid #eee;
      border-radius: 6px;
      padding: 5px 10px;
      i {
        color: #009cff;
        font-size: 16px;
      }
      span {
        margin-right: 0;
      }
    }
    img,
    video {
      width: 160px;
      height: 90px;
      margin: 5px;
    }
  }
  .universal-img {
    position: relative;
    width: 175px;
    height: 105px;
    img {
      width: 160px;
      height: 90px;
      margin: 5px;
    }
    .close {
      width: 30px;
      height: 30px;
      position: absolute;
      top: -4px;
      right: 0px;
    }
  }
  .universal-edit {
    // display: flex;
    // justify-content: flex-start;
    // flex-wrap: wrap;
    img {
      width: 160px;
      height: 90px;
      margin: 5px;
    }
  }
  .teacher-image {
    img,
    video {
      width: 160px;
      height: 90px;
      margin: 5px;
    }
  }
}
.carousel-main {
  // display: none;
  width: 550px;
  height: 340px;
  background: #fff;
  // box-shadow: 0px 2px 16px 0px rgba(0, 0, 0, 0.2);
  border-radius: 20px;
  // position: absolute;
  // z-index: 200;
  // top: 50%;
  // right: 10%;
  // transform: translate(-50%, -50%);
  .imgcls {
    width: 550px;
    height: 340px;
    img {
      width: 100%;
      height: 100%;
      border-radius: 20px;
    }
  }
  .ant-carousel {
    .slick-slide {
      text-align: center;
      height: 340px;
      // line-height: 160px;
      // background: #364d79;
      // overflow: hidden;
    }
    .custom-slick-arrow {
      width: 25px;
      height: 25px;
      font-size: 25px;
      color: #000;
      background-color: rgba(31, 45, 61, 0.11);
      opacity: 0.3;
    }
    .custom-slick-arrow:before {
      display: none;
    }
    .custom-slick-arrow:hover {
      opacity: 0.5;
    }
    // .slick-slide h3 {
    //   color: #fff;
    // }
  }
}
</style>
