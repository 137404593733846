<template>
  <div class="poster-contentOld">
    <div class="circle-lefttop"></div>
    <div class="circle-leftbottom"></div>
    <div class="circle-righttop"></div>
    <div class="poster-icon"></div>
    <div class="monkey-wrap">
      <div class="monkey"></div>
      <div class="star-wrap">
        <div class="poster-star" v-for="i in studyData.score" :key="i + 'star'"></div>
        <div class="poster-noStar" v-for="i in 5 - studyData.score" :key="i + 'noStar'"></div>
      </div>
    </div>
    <div class="poster-contentWrap">
      <div class="poster-infoWrap">
        <div class="poster-title">
          <span class="poster-name">{{ studyData.name }}</span
          >阶段学习报告
        </div>
        <div class="poster-levelWrap">
          <div class="poster-level2">
            {{ studyData.progress }}
          </div>
        </div>
      </div>
      <div class="poster-roleWrap">
        <div class="poster-role">
          <span class="role-icon1"></span>
          <span>{{ studyData.teacherName }}</span>
        </div>
        <div class="poster-role">
          <span class="role-icon2"></span>
          <span>{{ studyData.createTime }}</span>
        </div>
      </div>
      <p class="poster-item"><span class="red-txt">听力水平：</span>{{ studyData.listeningLevel }}</p>
      <div class="poster-item">
        <span class="red-txt">认读能力：</span>
        {{ studyData.readingLevel }}
      </div>
      <p class="poster-item">
        <span class="red-txt">口语水平：</span>
        {{ studyData.speakingLevel }}
      </p>
      <p class="poster-item">
        <span class="red-txt">书写能力：</span>
        {{ studyData.writingLevel }}
      </p>
      <div class="poster-item">
        <span class="red-txt">老师想说的话：</span>
        <div class="remake" v-html="studyData.remake"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'StudyReportTemplateOld',
  props: {
    studyData: {
      type: Object,
      require: true,
    },
  },
  data() {
    return {};
  },
  mounted() {},
};
</script>

<style lang="less">
.remake {
  h1 {
    font-size: 2em;
    margin-bottom: 10px;
  }
  h2 {
    font-size: 1.5em;
    margin-bottom: 10px;
  }
}
</style>
<style scoped lang="less">
.poster-contentOld {
  position: relative;
  width: 375px;
  margin: 0 auto;
  overflow: hidden;
  background: linear-gradient(180deg, #ff8181 0%, #ff5353 100%);
  border-radius: 8px;
}

.circle-lefttop {
  width: 194px;
  height: 194px;
  background: #ff6060;
  border-radius: 50%;
  position: absolute;
  left: -58px;
  top: -50px;
}

.circle-righttop {
  width: 277px;
  height: 277px;
  background: #ff9292;
  border-radius: 50%;
  position: absolute;
  right: -60px;
  top: 80px;
}
.circle-leftbottom {
  width: 292px;
  height: 292px;
  background: #ff9292;
  border-radius: 50%;
  position: absolute;
  left: -60px;
  bottom: -30px;
}

.poster-icon {
  position: absolute;
  width: 67px;
  height: 38px;
  background: url('../assets/posterIcon.png') no-repeat;
  background-size: 100%;
  position: absolute;
  right: 80px;
}
.monkey-wrap {
  width: 100%;
  position: relative;
  display: flex;
  margin: 28px 0 0 30px;
}
.monkey {
  width: 98px;
  height: 141px;
  background: url('../assets/monkey.png') no-repeat;
  background-size: 100%;
}
.poster-star {
  width: 35px;
  height: 36px;
  background: url('../assets/poster-star.png') no-repeat;
  background-size: 100%;
}
.poster-noStar {
  width: 35px;
  height: 36px;
  background: url('../assets/icon_star.png') no-repeat;
  background-size: 100%;
}
.poster-contentWrap {
  width: 335px;
  background: #ffffff;
  border-radius: 20px 20px 20px 20px;
  border: 4px solid #ffa4a4;
  margin: -45px auto 18px;
  position: relative;
}
.star-wrap {
  margin: 30px 0 0 15px;
  display: flex;
}
.poster-star {
  margin-right: 7px;
}
.poster-infoWrap {
  margin: 0 10px;
  border-bottom: 1px solid #eeeeee;
  text-align: center;
}
.poster-title {
  text-align: center;
  font-size: 22px;
  color: #ff7474;
  padding: 12px 0 5px;
  border-bottom: 2px solid #fadb1b;
  display: inline-block;
}
.poster-name {
  color: #333333;
  padding-right: 5px;
}
.poster-levelWrap {
  display: flex;
  color: #fff;
  justify-content: center;
  align-items: center;
  margin: 14px 0 20px;
}
.poster-level1 {
  padding: 5px 9px;
  font-size: 12px;
  background-color: #ff9292;
  border-radius: 50%;
}
.poster-level2 {
  padding: 0px 15px;
  background-color: #ff7474;
  border-radius: 10px;
  font-size: 16px;
}
.arrow-line {
  width: 33px;
  height: 1px;
  display: inline-block;
  background: linear-gradient(90deg, #ffb9b9 0%, #ff7474 100%);
}
.arrow-icon {
  border: 5px solid transparent;
  border-left-color: #ff7474;
  width: 0;
  height: 0;
  display: inline-block;
}
.arrow-wrap {
  display: flex;
  align-items: center;
}
.role-icon1 {
  width: 10px;
  height: 12px;
  display: inline-block;
  background: url('../assets/vector.png') no-repeat;
  background-size: 100%;
  margin-right: 4px;
}
.role-icon2 {
  width: 12px;
  height: 12px;
  display: inline-block;
  background: url('../assets/timeicon.png') no-repeat;
  background-size: 100%;
  margin-right: 4px;
}
.poster-roleWrap {
  padding: 20px 10px;
  font-size: 14px;
  color: #333;
  display: flex;
  align-items: center;
}
.poster-role:first-child {
  margin-right: 40px;
}
.poster-item {
  letter-spacing: 1px;
  padding: 10px;
  background-color: #fff8f8;
  margin: 0 10px 10px;
  border-radius: 12px;
  font-size: 12px;
  color: #333;
  text-align: justify;
}
.red-txt {
  color: #ff7474;
}
.poster-btnWrap {
  margin-top: 15px;
  text-align: center;
}
.closePosterBtn {
  background: #f7f7f7;
  color: #000;
}
.poster-btn {
  width: 150px;
  height: 46px;

  border-radius: 10px;
  cursor: pointer;
  border: none;
}
.downloadBtn {
  background: #009cff;
  margin-left: 35px;
  color: #fff;
}
.certificateWrapper {
  position: relative;
  width: 595px;
  right: -1000000px;
  overflow: hidden;
}
</style>
