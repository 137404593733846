<template>
  <div class="poster" id="teacherPoster" :class="teacherInfo.subject">
    <!-- teacherInfo = {
    fullName, //老师姓名
    avatar, // 老师头像
    teachingStyles, // 授课风格
    eduBackground, // 教育背景
    desc, // 老师简介
    certificates, // 荣誉证书
    workExperience, // 工作经验
    subject, // 科目
  }; -->
    <div class="base-info">
      <img
        class="base-logo"
        :src="
          teacherInfo.subject === 'CHINESE'
            ? require('../assets/poster/logo_chinese.png')
            : require('../assets/poster/logo_math.png')
        "
        alt=""
      />
      <div class="base-main">
        <div class="teacher-avatar">
          <img :src="teacherInfo.avatar || Avatar" alt="Avatar" />
        </div>

        <div>
          <p class="teacher-name">{{ teacherInfo.fullName }}</p>
          <div class="teacher-label" v-if="teacherInfo.teachingStyles && teacherInfo.teachingStyles.length">
            <span class="teaching-styles" v-for="i in teacherInfo.teachingStyles.slice(0, 3)" :key="i">{{ i }}</span>
          </div>
          <div class="teaching-education" v-if="teacherInfo.eduBackground">
            <img src="../assets/poster/education.png" alt="" />
            <span class="education">{{ teacherInfo.eduBackground }}</span>
          </div>
        </div>
      </div>
      <img
        :src="
          teacherInfo.subject === 'CHINESE'
            ? require('../assets/poster/paper_chinese.png')
            : require('../assets/poster/paper_math.png')
        "
        alt=""
      />
    </div>
    <div class="teacher-introduce">
      <!-- 教师简介 -->
      <div class="teacher-main" v-if="teacherInfo.desc">
        <div class="teacher-label">
          <img
            :src="
              teacherInfo.subject === 'CHINESE'
                ? require('../assets/poster/profile_chinese.png')
                : require('../assets/poster/profile_math.png')
            "
            alt=""
          />
          <span>{{ teacherInfo.subject === 'CHINESE' ? '教师简介' : 'Teacher Profile' }}</span>
        </div>

        <div class="teacher-text teacher-desc">
          {{ teacherInfo.desc }}
        </div>
      </div>
      <!-- 荣誉证书 -->
      <div class="teacher-main" v-if="teacherInfo.certificates && teacherInfo.subject === 'CHINESE'">
        <div class="teacher-label">
          <img src="../assets/poster/certificate.png" alt="" />
          <span>资历证书</span>
        </div>
        <div>
          <p class="teacher-text">{{ teacherInfo.certificates }}</p>
        </div>
      </div>
      <!-- 教学经验 -->
      <div class="teacher-main" v-if="teacherInfo.workExperience && teacherInfo.subject === 'CHINESE'">
        <div class="teacher-label">
          <img src="../assets/poster/experience.png" alt="" />
          <span>教学经验</span>
        </div>
        <div>
          <p class="teacher-text">{{ teacherInfo.workExperience }}</p>
          <!-- <ul>
            <li class="teacher-text teacher-experience">{{ teacherInfo.workExperience }}</li>
          </ul> -->
        </div>
      </div>
    </div>
    <div class="pic_bottom">
      <img src="../assets/poster/pic_bottom.png" alt="" />
    </div>
  </div>
</template>
<script>
import Avatar from '../assets/poster/avatar.png';

export default {
  inheritAttrs: false,
  data() {
    return {
      Avatar,
      teacherInfo: null,
    };
  },
  created() {
    this.teacherInfo = this.$attrs;
  },
  methods: {},
};
</script>
<style lang="less">
.poster {
  width: 750px;
  min-height: 1334px;
  border-radius: 20px;
  overflow: hidden;
  .base-info {
    width: 100%;

    background-size: 100% 100%;
  }
  .base-logo {
    position: absolute;
    top: 0;
    right: 40px;
  }
  .base-main {
    display: flex;
    justify-content: flex-start;
    padding: 40px 40px 0;
    margin-bottom: 50px;
    .teacher-avatar {
      width: 160px;
      height: 160px;
      outline: 4px solid #fff;
      border-radius: 50%;
      margin-right: 30px;
      img {
        width: 160px;
        height: 160px;
        border-radius: 50%;
      }
    }
    .teacher-name {
      font-size: 40px;
      font-weight: 600;
      line-height: 59px;
      margin-bottom: 16px;
      color: #fff;
    }
    .teacher-label {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-wrap: wrap;
      margin-bottom: 20px;
      .teaching-styles {
        font-size: 24px;
        display: inline-block;
        padding: 4px 8px;
        background: #fff9e6;
        border-radius: 8px;
        margin-right: 10px;
        margin-bottom: 10px;
      }
    }
    .teaching-education {
      font-size: 28px;
      line-height: 39px;
      color: #fff;
      display: flex;
      align-items: flex-start;
      .education {
        // overflow: hidden;
        // white-space: nowrap;
        // text-overflow: ellipsis;
      }
      span {
        display: inline-block;
        width: 420px;
        // white-space: nowrap;
        // overflow: hidden;
        // text-overflow: ellipsis;
      }
      img {
        margin-right: 10px;
      }
    }
  }
  .teacher-introduce {
    padding: 25px 40px 160px;
    .teacher-main {
      padding-bottom: 40px;
      .teacher-label {
        margin-bottom: 20px;
        display: flex;
        align-items: center;
        img {
          margin-right: 12px;
        }
        span {
          font-weight: 600;
          font-size: 34px;
          line-height: 48px;
        }
      }
      .teacher-text {
        font-size: 28px;
        line-height: 46px;
        color: rgba(0, 0, 0, 0.6);
        // text-align: justify;
        font-family: PingFang SC;
        // overflow-wrap: break-word;
        word-break: break-all;
      }

      .teacher-experience {
        // list-style: disc;
      }
    }
  }
  .pic_bottom {
    position: absolute;
    bottom: 0;
  }
  // .teacher-desc {
  // overflow: hidden;
  // display: -webkit-box;
  // }
}
.CHINESE {
  background: #fff3f3;
  .base-info {
    background: url('../assets/poster/chinese-top.png');
  }
  .teaching-styles {
    color: #ff6666;
  }
  .teacher-introduce {
    background: #fff3f3;
  }
  .pic_bottom {
    background: #fff3f3;
  }
}
.MATH {
  background: #f4f8ff;
  .base-info {
    background: url('../assets/poster/math-top.png');
  }
  .teaching-styles {
    color: #3e79ff;
  }
  .teacher-introduce {
    background: #f4f8ff;
  }
  .pic_bottom {
    background: #f4f8ff;
  }
}
</style>
